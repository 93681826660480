/* eslint-disable jsx-a11y/anchor-is-valid */

import {useContext, useEffect, useState} from "react";
import {GetApiCall, UploadFormApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import ChangePasswordPage from "../../Authentications/Candidates/changepassword";
import {toast} from "react-toastify";
import Loader from "../../../components/loader";
import {AuthContext, UserIdContext} from "../../../contexts/auth-context";

const CompanyProfileInfo = () => {
    const [loading, setLoading] = useState(false);
    const [uploadImage, setUploadImage] = useState(null);
    const [companySizeList, setCompanySizeList] = useState();
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
    const userIdContext = useContext(UserIdContext);
    const [updateProfile, setUpdateProfile] = useState({});
    const auth = useContext(AuthContext);

    const changePasswordClick = () => {
        setIsChangePasswordVisible(true);
    };
    const closeAuthPopup = (event) => {
        setIsChangePasswordVisible(event);
    };

    useEffect(() => {
        GetApiCall(APIConstants.VIEWPROFILE_URL).then((data) => {
            setUpdateProfile({
                email: data.email,
                company_size_id: data.employer_profile.company_size_id,
                company_name: data.employer_profile.company_name,
                phone_no: data.employer_profile.phone_no,
                address: data.employer_profile.address,
                profile_image: data.employer_profile.logo,
            });
        });
    }, []);

    useEffect(() => {
        GetApiCall(APIConstants.COMPANYSIZES_URL).then((data) => {
            if (data !== undefined) {
                setCompanySizeList(data);
            }
        });
    }, []);

    // const uploadImageAccount = () => {
    //     setLoading(true);
    //     const imageUploadBody = {
    //         image: uploadImage,
    //     };
    //     UploadFormApiCall(
    //         APIConstants.ACCOUNTPROFILEUPLOADIMAGE_URL,
    //         imageUploadBody
    //     ).then((data) => {
    //         setLoading(false);
    //         toast("Profile Image Updated Successfully");
    //     });
    // };

    const handleChange = (e) => {
        e.preventDefault();
        let accountCreationBody = {
            ...updateProfile,
            [e.target.name]: e.target.value,
        };
        setUpdateProfile(accountCreationBody);
    };
    const updateAccount = () => {
        setLoading(true);
        let accountProfileBody = {
            ...updateProfile,
            city: "hyderbad",
            state: "telangana",
            google_location: "hyderbad",
            latitude: "17.3850",
            longitude: "17.3850",
            zip: "500019",
        };
        if (uploadImage !== null) {
            accountProfileBody = {
                ...updateProfile,
                company_logo: uploadImage,
            };
        }
        UploadFormApiCall(
            APIConstants.EMPLOYERUPDATE_URL + userIdContext.id,
            accountProfileBody
        ).then((data) => {
            const updateCompanyProfile = userIdContext;
            if (updateCompanyProfile !== null) {
                updateCompanyProfile.profile_image = data.data.logo;
                auth.profileUpdate(updateCompanyProfile);
            }
            setLoading(false);
            toast(data.message);
        });
    };

    const handleUploadImage = (e) => {
        e.preventDefault();
        setUploadImage((prev) => e.target.files[0]);
    };
    const createAccounthandle = (e) => {
        e.preventDefault();
        const numberCheck = /^[0-9\b]+$/;
        if (!numberCheck.test(updateProfile.phone_no)) {
            toast('Phone number need to be only numbers');
        } else {
            if (updateProfile.phone_no.length > 10 || updateProfile.phone_no.length < 10) {
                toast('The phone no field must be 10 digits.');
            } else {
                updateAccount();
            }

        }

    };

    return (
        <>
            {loading && <Loader loading={loading}/>}
            <div className="candidate-outer main-container-outer">
                <div className="container-cstm">
                    <div id="account-tab1" className="tabcontent">
                        {isChangePasswordVisible && (
                            <ChangePasswordPage
                                isVisible={isChangePasswordVisible}
                                closeAuthPopup={closeAuthPopup}
                                changePasswordClick={changePasswordClick}
                            />
                        )}
                        <form action="">
                            <div className="tabcontent-inner">
                                <div className="acc-sts-left">
                                    <input
                                        type="text"
                                        placeholder="E-mail"
                                        value={updateProfile?.email}
                                        disabled
                                    />
                                    <div className="frmcontainer">
                                        <select
                                            name="company_size_id"
                                            onChange={handleChange}
                                            value={updateProfile.company_size_id}
                                            aria-invalid="false"
                                        >
                                            {companySizeList?.map((sizeList) => {
                                                return (
                                                    <option value={sizeList.id}>
                                                        {sizeList?.company_size}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <input
                                        type="text"
                                        placeholder="Family name"
                                        value={updateProfile?.company_name}
                                        onChange={handleChange}
                                        name="company_name"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Phone Number"
                                        value={updateProfile?.phone_no}
                                        onChange={handleChange}
                                        name="phone_no"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Location"
                                        value={updateProfile?.address}
                                        onChange={handleChange}
                                        name="address"
                                    />
                                </div>

                                <div className="acc-sts-right">
                                    <div className="acc-sts-img-wrap">
                                        {uploadImage !== null ? (
                                            <img src={URL.createObjectURL(uploadImage)} alt=""/>
                                        ) : (
                                            <img src={updateProfile.profile_image} alt=""/>
                                        )}

                                        <h2>
                                            Welcome<span>{updateProfile.company_name}</span>
                                        </h2>
                                    </div>

                                    <div
                                        className="file-upload-wrapper"
                                        data-text="Upload profile Picture"
                                    >
                                        <input
                                            name="file-upload-field"
                                            type="file"
                                            className="file-upload-field"
                                            onChange={handleUploadImage}
                                        />
                                    </div>
                                </div>
                            </div>

                            <p>
                                <a href="#" onClick={() => changePasswordClick()}>
                                    Change Password
                                </a>{" "}
                            </p>
                            <input
                                type="submit"
                                value="Save"
                                onClick={(e) => createAccounthandle(e)}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CompanyProfileInfo;
