/* eslint-disable jsx-a11y/anchor-is-valid */

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GetApiCall } from "../../../../utils/axios";
import { APIConstants, debounce } from "../../../../utils/config";
import Loader from "../../../../components/loader";
import moment from "moment";
import { CommonActionTypes } from "../../../../redux/common/common-enum";
import { useDispatch, useSelector } from "react-redux";
import { SetupAccountMenuList } from "../../../../utils/enum";
import Pagination from "../../../../components/pagination";
import { UserIdContext } from "../../../../contexts/auth-context";
import Footer from "../../../../components/footer";

const MyJobPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobsListing, setMyJobsListing] = useState([]);
  const dispatch = useDispatch();
  const userIdContext = useContext(UserIdContext);
  const setupAccountState = useSelector((state) => state.SetupAccountState);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const postJob = () => {
    let accountMenuList = [...setupAccountState.setupMenuData];
    accountMenuList[0].isShow = false;
    accountMenuList[1].isShow = false;
    accountMenuList[2].isShow = false;
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
      setupMenuData: accountMenuList,
    });
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENU,
      setupMenuSelected: 5,
    });
    navigate("/company-info/job-info");
  };

  const onclickHandler = (singleJob) => {
    navigate("/my-job-applicants", { state: { id: singleJob.id } });
  };
  const myJobs = (search) => {
    setLoading(true);
    GetApiCall(APIConstants.EMPLOYER_MY_JOBS_URL + search).then((data) => {
      setLoading(false);
      setMyJobsListing(data.data);
    });
  };
  useEffect(() => {
    myJobs(location.search);
  }, [searchParams]);

  useEffect(() => {
    myJobs("");
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENULIST,
      setupMenuData: SetupAccountMenuList,
    });
  }, []);

  const searchOnChangrHandler = (e) => {
    jobSearch(e.target.value);
  };

  const jobSearch = useCallback(
    debounce((val) => {
      if (searchParams.has("search")) {
        searchParams.delete("search");
      }
      if (val.toString() === "") {
        searchParams.delete("search");
      } else {
        searchParams.append("search", val);
      }
      setSearchParams(searchParams);
    }, 1000),
    []
  );
  return (
    <div className="grey-bg">
      {loading && <Loader loading={loading} />}
      <div className="candidate-outer ">
        <div className="profile-outer mt-0 pt-5">
          <form className="job-search-section" action="">
            <div>
              <i className="fa fa-search" aria-hidden="true"></i>
              <input
                type="text"
                placeholder="Search Among Your Jobs"
                onChange={searchOnChangrHandler}
              />
            </div>
            {userIdContext.membership_plan_id !== null && (
              <input type="submit" onClick={postJob} value="Post a new Job" />
            )}
          </form>
        </div>
      </div>
      <div className="candidate-outer main-container-outer">
        <div className="container-cstm">
          <div className="tble-prt myjob">
            <div className="table-responsive table-card">
              <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th>Job title</th>
                    <th>Posted</th>
                    <th>Unposting</th>
                    <th>Status</th>
                    <th>Views</th>
                    <th>Applicants</th>
                  </tr>
                </thead>
                <tbody>
                  {jobsListing.data?.length > 0 &&
                    jobsListing.data.map((singleJob) => {
                      return (
                        <tr onClick={() => onclickHandler(singleJob)}>
                          <td>
                            <strong>{singleJob.title}</strong>{" "}
                          </td>
                          <td>
                            {moment(singleJob.created_at).format("MM-DD-YYYY")}
                          </td>
                          <td>
                            {moment(singleJob.unposting_date).format(
                              "MM-DD-YYYY"
                            )}
                          </td>
                          <td>
                            <span className="purple">{singleJob.status}</span>{" "}
                          </td>
                          <td>{singleJob.no_of_views}</td>

                          <td> {singleJob.no_of_applicants}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                perPage={jobsListing.per_page}
                total={jobsListing.total}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default MyJobPage;
