/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordApi} from "../../../redux/authenication/authenication-saga";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "../../../components/loader";
import {AuthenicationActionTypes} from "../../../redux/authenication/authenication-enum";
import {PostApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";

const ResetPasswordPage = () => {
    const dispatch = useDispatch();
    const resetPasswordState = useSelector((state) => state.ResetPasswordState);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [formData, setFormData] = useState({
        email: searchParams.get("email"),
        password: "",
        repeatpassword: "",
    });
    const [type, setType] = useState("password");

    useEffect(() => {
        const resetTokenBody = {
            email: searchParams.get("email"),
            token: searchParams.get("token")
        }
        PostApiCall(APIConstants.RESETPASSWORDTOKEN, resetTokenBody).then((response) => {
            console.log(response);
        });
    }, [searchParams]);
    const handleToggle = () => {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    };

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    useEffect(() => {
        if (
            resetPasswordState.isResetPasswordStatus !== null &&
            resetPasswordState.isResetPasswordStatus
        ) {
            dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
            });
            toast(
                "Password has been changed successfully, please try to login with the new password."
            );
            navigate("/");
        }
    }, [resetPasswordState]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.password === "") {
            toast("Enter the password");
        } else if (formData.password.length < 8) {
            toast("Password must be at least 8 chars long");
        } else if (formData.repeatpassword === "") {
            toast("Enter the repeat password");
        } else if (formData.password !== formData.repeatpassword) {
            toast("Password doesnt match");
        } else {
            dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
            });
            dispatch(resetPasswordApi(formData));
        }
    };

    return (
        <div className="grey-bg">
            {resetPasswordState.loading && (
                <Loader loading={resetPasswordState.loading}/>
            )}
            <div className="candidate-outer ">
                <div className="container-cstm">
                    <div className="resetpassword">
                        <h2>Reset password</h2>
                        <p>Enter your new password below.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="pswrd">
                                <input
                                    type={type}
                                    placeholder="Password"
                                    name="password"
                                    id=""
                                    onChange={handleChange}
                                />
                                <i
                                    className={`fa ${
                                        type === "password" ? "fa-eye-slash" : "fa-eye"
                                    }`}
                                    onClick={handleToggle}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <input
                                type="password"
                                placeholder="Repeat Password"
                                name="repeatpassword"
                                id=""
                                onChange={handleChange}
                            />
                            <input type="submit" value="Submit"/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ResetPasswordPage;
