/* eslint-disable jsx-a11y/anchor-is-valid */

import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PostApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import Loader from "../../../components/loader";
import {toast} from "react-toastify";
import {SetupAccountMenuRoutes} from "../../../utils/enum";
import {CommonActionTypes} from "../../../redux/common/common-enum";
import {useDispatch, useSelector} from "react-redux";

const QuestionnairePage = () => {
    const {state} = useLocation();
    const dispatch = useDispatch();
    const setupAccountState = useSelector((state) => state.SetupAccountState);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {id} = state;
    const updateJobDetailsState = useSelector(
        (state) => state.CreateNewCompanyState.updateJobDetails
    );
    const [questionnaire, setQuestionnaire] = useState(
        updateJobDetailsState !== null
            ? updateJobDetailsState.questionnaire
            : [
                {
                    question: "",
                    qid: 1,
                },
            ]
    );
    const [count, pageName, setCount] = useOutletContext();

    const addQuestion = (e) => {
        e.preventDefault();
        const addQuestion = {
            question: "",
            qid: questionnaire.length + 1,
        };
        setQuestionnaire((oldArray) => [...oldArray, addQuestion]);
    };

    const removeQuestion = (item) => {
        const qIndex = questionnaire.findIndex((q) => q.qid === item.qid);
        let newArr = [...questionnaire];
        newArr.splice(qIndex, 1);
        setQuestionnaire(newArr);
        // setQuestionnaire(questionnaire.filter((que) => que.qid !== item.qid));
    };

    const handleQuestion = (event, item) => {
        const qIndex = questionnaire.findIndex((q) => q.qid === item.qid);
        let newArr = [...questionnaire];
        newArr[qIndex].question = event.target.value;
        setQuestionnaire(newArr);
    };

    useEffect(() => {
        if (count > 0 && pageName === SetupAccountMenuRoutes.Questionaire) {
            questionnaireHandler();
        }
    }, [count]);

    const questionnaireHandler = () => {
        setLoading(true);
        const filteredQuestionList = questionnaire
            .filter((cn1) => cn1.question !== "")
            .map((cN) => cN.question);
        PostApiCall(APIConstants.JOBQUESTIONARIES_URL + id, {
            question: filteredQuestionList,
        }).then((data) => {
            if (data.status) {
                setCount(0);
                toast("Questionaries Saved Successfully");
                dispatch({
                    type: CommonActionTypes.SETUPACCOUNTMENU,
                    setupMenuSelected: 8,
                });
                const menuIndex = setupAccountState.setupMenuData.findIndex(
                    (mI) => mI.id === 7
                );
                let accountMenuList = [...setupAccountState.setupMenuData];
                accountMenuList[menuIndex].isFilled = true;
                dispatch({
                    type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
                    setupMenuData: accountMenuList,
                });
                navigate("/company-info/sponsoring", {state: {id: id}});
            }
            setLoading(false);
        });
    };

    return (
        <>
            {loading && <Loader loading={loading}/>}

            <div>
                <h2>Questionnaire</h2>
                <p>
                    Please select up to 5 questions that you'd like applicants to answer.
                </p>
                {questionnaire.length < 5 && <p className="d-flex justify-content-end w-100">
                    <button className="nxt-btn" onClick={(e) => addQuestion(e)}>
                        Add
                    </button>
                </p>}

                <form>
                    {questionnaire.map((question, index) => {
                        return (
                            <div
                                className={`${
                                    question.question === "" ? "add-question" : "added-question"
                                }`}
                            >
                                <input
                                    type="text"
                                    onChange={(e) => handleQuestion(e, question)}
                                    name={question.qid}
                                    value={question.question}
                                    placeholder={`Question number ${question.qid}.`}
                                />
                                {question.question !== "" && (
                                    <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                        onClick={() => removeQuestion(question)}
                                    ></i>
                                )}
                            </div>
                        );
                    })}
                </form>
            </div>
        </>
    );
};
export default QuestionnairePage;
