import {MyProfileActionTypes} from "./my-profile-enum";
import {GetApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import {call, put, takeLatest} from "redux-saga/effects";

export function myProfileAppliedJobsApi(params) {
    return {
        type: MyProfileActionTypes.MYPROFILEAPPLIEDJOBSREQUESTED,
        params: params,
    };
}

export function myProfileSavedJobsApi(params) {
    return {
        type: MyProfileActionTypes.MYPROFILESAVEDJOBSREQUESTED,
        params: params,
    };
}

async function myProfileSavedJobsService(action) {
    return GetApiCall(APIConstants.JOBSAVEDLIST_URL + action.params).then((data) => {
        return data;
    });
}
async function myProfileAppliedJobsService(action) {
    return GetApiCall(APIConstants.JOBAPPLIEDLIST_URL + action.params).then((data) => {
        return data;
    });
}

export function* doSavedJobList(params) {
    yield put({type: MyProfileActionTypes.MYPROFILEJOBSREQUEST, loading: true});
    const jobsListResponse = yield call(myProfileSavedJobsService, params);
    if (jobsListResponse) {
        yield put({
            type: MyProfileActionTypes.MYPROFILEJOBSSAVEDJOBS,
            status: jobsListResponse.status,
            savedJobs: jobsListResponse,
        });
    }
    yield put({
        type: MyProfileActionTypes.MYPROFILEJOBSREQUEST,
        loading: false,
    });
}

export function* doAppliedJobList(params) {
    yield put({type: MyProfileActionTypes.MYPROFILEJOBSREQUEST, loading: true});
    const jobsListResponse = yield call(myProfileAppliedJobsService, params);
    if (jobsListResponse) {
        yield put({
            type: MyProfileActionTypes.MYPROFILEJOBSAPPILEDJOBS,
            status: jobsListResponse.status,
            appliedJobs: jobsListResponse,
        });
    }
    yield put({
        type: MyProfileActionTypes.MYPROFILEJOBSREQUEST,
        loading: false,
    });
}

export function* myProfileJobsSaga() {
    yield takeLatest(MyProfileActionTypes.MYPROFILESAVEDJOBSREQUESTED, doSavedJobList);
    yield takeLatest(MyProfileActionTypes.MYPROFILEAPPLIEDJOBSREQUESTED, doAppliedJobList);
}
