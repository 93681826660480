import React, { useEffect, useState } from "react";
import { membershipApi } from "../../../redux/employer/membership-plans/membership-saga";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { toast } from "react-toastify";
import { companyCreateApi } from "../../../redux/employer/company-create/companycreate-saga";
import Loader from "../../../components/loader";
import { SetupAccountMenuRoutes } from "../../../utils/enum";
import { CommonActionTypes } from "../../../redux/common/common-enum";

const PricesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const membershipState = useSelector((state) => state.MembershipState);
  const createNewCompanyState = useSelector(
    (state) => state.CreateNewCompanyState
  );
  const setupAccountState = useSelector((state) => state.SetupAccountState);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [count, pageName, setCount] = useOutletContext();

  useEffect(() => {
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENU,
      setupMenuSelected: 3,
    });
    dispatch(membershipApi());
  }, []);

  useEffect(() => {
    if (count > 0 && pageName === SetupAccountMenuRoutes.Prices) {
      if (selectedPrice === 0) {
        toast("Please select the price");
      } else {
        dispatch({
          type: CommonActionTypes.SETUPACCOUNTMENU,
          setupMenuSelected: 4,
        });
        const menuIndex = setupAccountState.setupMenuData.findIndex(
          (mI) => mI.id === 3
        );
        let accountMenuList = [...setupAccountState.setupMenuData];
        accountMenuList[menuIndex].isFilled = true;
        dispatch({
          type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
          setupMenuData: accountMenuList,
        });
        setCount(0);
        navigate({
          pathname: "/payment",
          search: createSearchParams({
            id: selectedPrice.toString(),
          }).toString(),
        });
      }
    }
  }, [count]);

  const priceHandler = (membership) => {
    setSelectedPrice(membership.id);
  };

  return (
    <>
      {createNewCompanyState.loading && (
        <Loader loading={createNewCompanyState.loading} />
      )}

      <div className="inner-content-right">
        <h2>Prices</h2>
        <p>Choose the plan that suits you </p>
        <div className="emp-step-price-outer">
          {membershipState.membershipPlans.map((membership, index) => {
            return (
              <div className="emp-step-price" key={index}>
                <h3>{membership.package_name}</h3>
                <p>
                  <span>{membership.price} $</span>
                </p>
                <p> {membership.description}</p>

                <button
                  className={`${
                    selectedPrice === membership.id ? "active" : ""
                  }`}
                  onClick={() => priceHandler(membership)}
                >
                  {selectedPrice === membership.id ? "Selected" : "Select"}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default PricesPage;
