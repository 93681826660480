/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { GetApiCall } from "../../../../utils/axios";
import { APIConstants } from "../../../../utils/config";
import Loader from "../../../../components/loader";
import moment from "moment";
import MyJobApplicantsDetailsPage from "./my-jobs-applicants-details";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonActionTypes } from "../../../../redux/common/common-enum";
import { SetupAccountMenuList } from "../../../../utils/enum";
import Footer from "../../../../components/footer";

const MyJobApplicantsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state || {};
  const [loading, setLoading] = useState(false);
  const [jobsApplicants, setJobsApplicants] = useState();
  const [isOpen, setIsOpen] = useState("");
  const [isApplicantDetails, setApplicantDetails] = useState(false);
  const [applicantSelected, setApplicantSelected] = useState(null);
  const dispatch = useDispatch();
  const setupAccountState = useSelector((state) => state.SetupAccountState);

  // const nextJobDetails = () => {
  //   navigate("/my-job-applicants-details");
  // };

  const myJobsApplicants = () => {
    setLoading(true);
    GetApiCall(APIConstants.EMPLOYER_MY_JOBS_APPLICANTS_URL + id).then(
      (data) => {
        setLoading(false);
        setJobsApplicants(data.data);
      }
    );
  };

  useEffect(() => {
    myJobsApplicants();
  }, []);
  useEffect(() => {
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENULIST,
      setupMenuData: SetupAccountMenuList,
    });
  }, []);

  const dropdown = (keyName) => {
    setIsOpen(keyName);
  };
  const openTheApplicantDetails = (applicate) => {
    if (applicate.job_status === "Not Reviewed") {
      setApplicantDetails(true);
      setApplicantSelected(applicate);
    }
  };
  const updateJob = () => {
    let accountMenuList = [...setupAccountState.setupMenuData];
    accountMenuList[0].isShow = false;
    accountMenuList[1].isShow = false;
    accountMenuList[2].isShow = false;
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
      setupMenuData: accountMenuList,
    });
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENU,
      setupMenuSelected: 5,
    });
    navigate({
      pathname: "/company-info/job-info",
      search: createSearchParams({
        id: id,
      }).toString(),
    });
  };
  return (
    <div className="grey-bg pt-5">
      {loading && <Loader loading={loading} />}
      <div className="candidate-outer ">
        <div className="profile-outer myjob-app-top mt-0">
          <p>{jobsApplicants?.title}</p>
          <p>{moment(jobsApplicants?.created_at).format("MM-DD-YYYY")}</p>
          <p className="green">Open</p>
          <p>{jobsApplicants?.no_of_applicants}</p>
          <p>
            <strong>---</strong>
          </p>
          <p onClick={updateJob}>
            <strong>Edit</strong>
          </p>
        </div>
      </div>

      <div className="candidate-outer ">
        <div className="container-cstm">
          <div
            className={`job-outer myjob-app ${
              isApplicantDetails && "myjob-app-details-outer"
            }`}
          >
            <div className="job-left desktop">
              <div className="accordion-outer">
                <button
                  onClick={() => dropdown("sortby")}
                  className={`accordion ${isOpen === "sortby" && "active"}`}
                >
                  Sort by <i className="arrow"></i>
                </button>
                <div
                  className={`panel ${isOpen === "sortby" && "dropdownClose"}`}
                >
                  <div className="acc-open">
                    <label className="lbl-chkbx">
                      Date (Newest first )
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>

                    <label className="lbl-chkbx">
                      Date (Oldest first )
                      <input type="checkbox" checked="checked" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="accordion-outer">
                <button
                  onClick={() => dropdown("Status")}
                  className={`accordion ${isOpen === "Status" && "active"}`}
                >
                  Status <i className="arrow"></i>
                </button>
                <div
                  className={`panel ${isOpen === "Status" && "dropdownClose"}`}
                >
                  <div className="acc-open">
                    <label className="lbl-chkbx">
                      All ({jobsApplicants?.status_count.all})
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="lbl-chkbx">
                      Not Reviewed ({jobsApplicants?.status_count.not_reviewed})
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="lbl-chkbx">
                      Under Review ({jobsApplicants?.status_count.under_review})
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="lbl-chkbx">
                      Accepted ({jobsApplicants?.status_count.accepted})
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="lbl-chkbx">
                      Rejected ({jobsApplicants?.status_count.rejected})
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-inner">
              <div className="cntnt-left">
                <div className="tble-prt myjob">
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>E-mail</th>
                          <th>Phone Number</th>
                          <th>Status</th>
                          <th>Call</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobsApplicants?.candidates?.length > 0 &&
                          jobsApplicants?.candidates?.map((applicantsJobs) => {
                            return (
                              <tr
                                onClick={() =>
                                  openTheApplicantDetails(applicantsJobs)
                                }
                              >
                                <td>
                                  <strong>{applicantsJobs.user.name}</strong>{" "}
                                </td>
                                <td> {applicantsJobs.user.email} </td>
                                <td> {applicantsJobs.user.phone_no} </td>
                                <td>
                                  <span className="green">
                                    {applicantsJobs.job_status}
                                  </span>
                                </td>
                                <td>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </td>
                              </tr>
                            );
                          })}

                        {/* <tr>
                          <td>
                            <strong>Jack Dohorty</strong>{" "}
                          </td>
                          <td> Jack.Dohorty@gmail.com </td>
                          <td> +1416-555-0444 </td>
                          <td>
                            <span className="red"> Rejected</span>{" "}
                          </td>
                          <td>
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          </td>
                          <td>
                            {" "}
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Jane Rooney</strong>{" "}
                          </td>
                          <td> Jane.Rooney@gmail.com </td>
                          <td> +1416-555-0444 </td>
                          <td>
                            <span className="yellow"> Not Reviewed</span>{" "}
                          </td>
                          <td>
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          </td>
                          <td>
                            {" "}
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Joseff Walter</strong>{" "}
                          </td>
                          <td> JoseffWalter@gmail.com </td>
                          <td> +1416-555-0444 </td>
                          <td>
                            <span className="purple"> Under Review</span>{" "}
                          </td>
                          <td>
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          </td>
                          <td>
                            {" "}
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>{" "}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {isApplicantDetails &&
              applicantSelected?.job_status === "Not Reviewed" && (
                <MyJobApplicantsDetailsPage applicant={applicantSelected} />
              )}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default MyJobApplicantsPage;
function serializeFormQuery(target) {
  throw new Error("Function not implemented.");
}
