import {useEffect, useState} from "react";
import {APIConstants} from "../utils/config";
import {GetApiCall} from "../utils/axios";

const MyProfitFilterMenu = ({jobSelected, sortBy, statusJobs}) => {
    const [isOpen, setIsOpen] = useState("");
    const [appliedSidemenu, setAppliedSidemenu] = useState(null);
    const [savedSideMenu, setSavedSideMenu] = useState(null);
    const [sortByChecked, setSortByChecked] = useState(null);
    const [statusChecked, setStatusChecked] = useState([]);

    const appliedJobs = () => {
        GetApiCall(APIConstants.JOBAPPLIEDSIDEMENU_URL).then((data) => {
            setAppliedSidemenu(data);
        });
    };

    const savedJobs = () => {
        GetApiCall(APIConstants.JOBSAVEDSIDEMENU_URL).then((data) => {
            setSavedSideMenu(data);
        });
    };

    useEffect(() => {
        appliedJobs();
        savedJobs();
    }, []);

    const dropdown = (keyName) => {
        setIsOpen(keyName);
    };

    const sortByChange = (e) => {
        setSortByChecked(e.target.value);
        sortBy(e.target.value);
    }

    useEffect(() => {
        statusJobs(statusChecked);
    }, [statusChecked]);

    const statusOnChange = (e) => {
        if (statusChecked.includes(e.target.value)) {
            setStatusChecked(prevState => prevState.filter(function (item) {
                return item !== e.target.value
            }));
        } else {
            setStatusChecked(prevState => [...prevState, e.target.value]);
        }
    }

    const AppliedSideList = () => {
        return (
            <div className="job-left desktop">
                <div className="accordion-outer">
                    <button
                        onClick={() => dropdown("sortby")}
                        className={`accordion ${isOpen === "sortby" && "active"}`}
                    >
                        Sort by <i className="arrow"></i>
                    </button>
                    <div className={`panel ${isOpen === "sortby" && "dropdownClose"}`}>
                        <div className="acc-open" onChange={sortByChange}>
                            <label className="lbl-chkbx">
                                Date (Newest first )
                                <input type="radio" value="desc" name="sortby"
                                       defaultChecked={sortByChecked === "desc"}/>
                                <span className="checkmark"></span>
                            </label>

                            <label className="lbl-chkbx">
                                Date (Oldest first )
                                <input type="radio" value="asc" name="sortby" defaultChecked={sortByChecked === "asc"}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="accordion-outer">
                    <button
                        onClick={() => dropdown("Status")}
                        className={`accordion ${isOpen === "Status" && "active"}`}
                    >
                        Status <i className="arrow"></i>
                    </button>
                    <div className={`panel ${isOpen === "Status" && "dropdownClose"}`}>
                        <div className="acc-open" onChange={statusOnChange}>
                            <label className="lbl-chkbx">
                                All ({appliedSidemenu?.all})
                                <input type="checkbox" value="all" name="all" defaultChecked={statusChecked.includes("all")}/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="lbl-chkbx">
                                Not Reviewed ({appliedSidemenu?.not_reviewed})
                                <input type="checkbox" value="not_reviewed" name="not_reviewed" defaultChecked={statusChecked.includes("not_reviewed")}/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="lbl-chkbx">
                                Under Review ({appliedSidemenu?.under_review})
                                <input type="checkbox" value="under_review" name="under_review" defaultChecked={statusChecked.includes("under_review")}/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="lbl-chkbx">
                                Accepted ({appliedSidemenu?.accepted})
                                <input type="checkbox" value="accepted" name="accepted" defaultChecked={statusChecked.includes("accepted")}/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="lbl-chkbx">
                                Rejected ({appliedSidemenu?.rejected})
                                <input type="checkbox" value="rejected" name="rejected" defaultChecked={statusChecked.includes("rejected")}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const SavedSideList = () => {
        return (
            <div className="job-left desktop">
                <div className="accordion-outer">
                    <button
                        onClick={() => dropdown("sortby")}
                        className={`accordion ${isOpen === "sortby" && "active"}`}
                    >
                        Sort by <i className="arrow"></i>
                    </button>
                    <div className={`panel ${isOpen === "sortby" && "dropdownClose"}`}>
                        <div className="acc-open" onChange={sortByChange}>
                            <label className="lbl-chkbx">
                                Date (Newest first )
                                <input type="radio" value="desc" name="sortby"
                                       defaultChecked={sortByChecked === "desc"}/>
                                <span className="checkmark"></span>
                            </label>

                            <label className="lbl-chkbx">
                                Date (Oldest first )
                                <input type="radio" value="asc" name="sortby" defaultChecked={sortByChecked === "asc"}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="accordion-outer">
                    <button
                        onClick={() => dropdown("Status")}
                        className={`accordion ${isOpen === "Status" && "active"}`}
                    >
                        Status <i className="arrow"></i>
                    </button>
                    <div className={`panel ${isOpen === "Status" && "dropdownClose"}`}>
                        <div className="acc-open" onChange={statusOnChange}>
                            <label className="lbl-chkbx">
                                All ({savedSideMenu?.All})
                                <input type="checkbox" value="all" name="all"
                                       defaultChecked={statusChecked.includes("all")}/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="lbl-chkbx">
                                Open Jobs ({savedSideMenu?.Open})
                                <input type="checkbox" value="open" name="open"
                                       defaultChecked={statusChecked.includes("open")}/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="lbl-chkbx">
                                Closed Jobs ({savedSideMenu?.Closed})
                                <input type="checkbox" value="closed" name="closed"
                                       defaultChecked={statusChecked.includes("closed")}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    if (jobSelected === "applied-jobs") {
        return <AppliedSideList/>;
    } else {
        return <SavedSideList/>;
    }
};
export default MyProfitFilterMenu;
