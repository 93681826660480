import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterMenuApi } from "../redux/candidates/filter-menu/filter-menu-saga";
import { useSearchParams } from "react-router-dom";
import { debounce } from "redux-saga/effects";
import React from "react";

const FilterMenu = () => {
  const dispatch = useDispatch();

  const filterSideMenuState = useSelector((state) => state.filterSideMenuState);

  const [isOpen, setIsOpen] = useState("");

  const [jobType, setJobType] = useState([]);

  const [industry, setIndustry] = useState([]);

  const [experience, setExperience] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    dispatch(filterMenuApi());
  }, []);

  const dropdown = (keyName) => {
    if (isOpen === keyName) {
      setIsOpen("");
    } else {
      setIsOpen(keyName);
    }
  };

  useEffect(() => {
    if (filterType === "job_type") {
      if (searchParams.has("job_type")) {
        searchParams.delete("job_type");
        setSearchParams(searchParams);
      }
      if (jobType.toString() === "") {
        searchParams.delete("job_type");
      } else {
        searchParams.append("job_type", jobType.toString());
      }
      setSearchParams(searchParams);
    } else if (filterType === "industry") {
      if (searchParams.has("industry")) {
        searchParams.delete("industry");
        setSearchParams(searchParams);
      }
      if (industry.toString() === "") {
        searchParams.delete("industry");
      } else {
        searchParams.append("industry", industry.toString());
      }
      setSearchParams(searchParams);
    } else if (filterType === "experience_id") {
      if (searchParams.has("experience_id")) {
        searchParams.delete("experience_id");
      }
      if (experience.toString() === "") {
        searchParams.delete("experience_id");
      } else {
        searchParams.append("experience_id", experience.toString());
      }
      setSearchParams(searchParams);
    }
  }, [industry, jobType, filterType, experience]);
  const searchHandler = (filterType, filterItem) => {
    setFilterType(filterType);
    if (filterType === "job_type") {
      const jobFindIndex = jobType.findIndex(
        (jobTypeIndex) => jobTypeIndex === filterItem.id
      );
      if (jobFindIndex === -1) {
        setJobType((prevState) => [...prevState, filterItem.id]);
      } else {
        const removedJob = jobType.filter((item) => item !== filterItem.id);
        setJobType(removedJob);
      }
    } else if (filterType === "industry") {
      const industryFindIndex = industry.findIndex(
        (jobTypeIndex) => jobTypeIndex === filterItem.id
      );
      if (industryFindIndex === -1) {
        setIndustry((prevState) => [...prevState, filterItem.id]);
      } else {
        const removedJob = industry.filter((item) => item !== filterItem.id);
        setIndustry(removedJob);
      }
    } else if (filterType === "experience_id") {
      const experienceFindIndex = experience.findIndex(
        (jobTypeIndex) => jobTypeIndex === filterItem.id
      );
      if (experienceFindIndex === -1) {
        setExperience((prevState) => [...prevState, filterItem.id]);
      } else {
        const removedJob = experience.filter((item) => item !== filterItem.id);
        setExperience(removedJob);
      }
    }
  };

  const wageOnChangrHandler = (e) => {
    // wageSearch(e.target.value);
    if (searchParams.has("min_salary")) {
      searchParams.delete("min_salary");
    }
    if (e.target.value.toString() === "") {
      searchParams.delete("min_salary");
    } else {
      searchParams.append("min_salary", e.target.value.replaceAll(" ", ""));
    }
    setSearchParams(searchParams);
  };

  const wageSearch = useCallback(
    debounce((val) => {
      if (searchParams.has("min_salary")) {
        searchParams.delete("min_salary");
      }
      if (val.toString() === "") {
        searchParams.delete("min_salary");
      } else {
        searchParams.append("min_salary", val.replaceAll(" ", ""));
      }
      setSearchParams(searchParams);
    }, 1000),
    []
  );

  const onClearFilters = () => {
    if (searchParams.has("job_type")) {
      searchParams.delete("job_type");
    }
    if (searchParams.has("industry")) {
      searchParams.delete("industry");
    }
    if (searchParams.has("experience_id")) {
      searchParams.delete("experience_id");
    }
    if (searchParams.has("min_salary")) {
      searchParams.delete("min_salary");
    }
    setSearchParams(searchParams);
  };
  return (
    <div className="job-left desktop">
      {filterSideMenuState.filterMenu.sortby !== undefined && (
        <div className="accordion-outer">
          <button
            onClick={() => dropdown("jobType")}
            className={`accordion ${isOpen === "sortby" && "active"}`}
          >
            Sort by <i className="arrow"></i>
          </button>
          <div className={`panel ${isOpen === "sortby" && "dropdownClose"}`}>
            <div className="acc-open">
              <label className="lbl-chkbx">
                Date (Newest first )
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>

              <label className="lbl-chkbx">
                Date (Oldest first )
                <input type="checkbox" checked="checked" />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      )}
      {filterSideMenuState.filterMenu.jobType !== undefined && (
        <div className="accordion-outer">
          <button
            onClick={() => dropdown("jobType")}
            className={`accordion ${isOpen === "jobType" && "active"}`}
          >
            Job Type <i className="arrow"></i>
          </button>
          {filterSideMenuState.filterMenu.jobType.map((filterItem) => {
            return (
              <div
                className={`panel ${isOpen === "jobType" && "dropdownClose"}`}
              >
                <div className="acc-open">
                  <label className="lbl-chkbx">
                    {filterItem.name}
                    <input
                      type="checkbox"
                      name="job_type"
                      value={filterItem.id}
                      onClick={(e) => searchHandler("job_type", filterItem)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {filterSideMenuState.filterMenu.industry !== undefined && (
        <div className="accordion-outer">
          <button
            onClick={() => dropdown("industry")}
            className={`accordion ${isOpen === "industry" && "active"}`}
          >
            Industry <i className="arrow"></i>
          </button>
          {filterSideMenuState.filterMenu.industry.map((filterItem) => {
            return (
              <div
                className={`panel ${isOpen === "industry" && "dropdownClose"}`}
              >
                <div className="acc-open">
                  <label className="lbl-chkbx">
                    {filterItem.name}
                    <input
                      type="checkbox"
                      onClick={() => searchHandler("industry", filterItem)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {filterSideMenuState.filterMenu.experience !== undefined && (
        <div className="accordion-outer">
          <button
            onClick={() => dropdown("experience_id")}
            className={`accordion ${isOpen === "experience_id" && "active"}`}
          >
            Experience Level <i className="arrow"></i>
          </button>

          {filterSideMenuState.filterMenu.experience.map((filterItem) => {
            return (
              <div
                className={`panel ${
                  isOpen === "experience_id" && "dropdownClose"
                }`}
              >
                <div className="acc-open">
                  <label className="lbl-chkbx">
                    {filterItem.name}
                    <input
                      type="checkbox"
                      onClick={() => searchHandler("experience_id", filterItem)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="accordion-outer">
        <button
          onClick={() => dropdown("salary")}
          className={`accordion ${isOpen === "salary" && "active"}`}
        >
          Minimum Wage[/hour]
        </button>
        <div className={`panel px-0 ${isOpen === "salary" && "dropdownClose"}`}>
          <input type="text" class="form-control" onChange={wageOnChangrHandler} />
        </div>
      </div>
      <button className="clr-fltr" onClick={onClearFilters}>
        Clear filters
      </button>
    </div>
  );
};
export default FilterMenu;
