// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { GetApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import Loader from "../../../components/loader";
import NoResult from "../../../components/no-result";
import Chart from "react-google-charts";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer";

const DashBoardPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [chartDashBoardList, setchartDashBoardList] = useState([]);

  const myDashBoard = () => {
    setLoading(true);
    GetApiCall(APIConstants.MYDASHBOARD_URL).then((data) => {
      if (data?.data) {
        const chartDashboard = [...chartDashBoardList];
        chartDashboard.push([
          "Job Title",
          "New",
          "Under Review",
          "Rejected",
          "Accepted",
        ]);
        for (const element of data.data) {
          delete element["total_jobs"];
          const v = Object.values(element);
          chartDashboard.push(v);
          setchartDashBoardList(chartDashboard);
        }
      }
      setLoading(false);
    });
  };
  const checkPaymentStatus = () => {
    GetApiCall(APIConstants.PAYMENTSTATUS).then((response) => {
      setPaymentStatus(response?.data?.subscription_plan === null);
    });
  };

  useEffect(() => {
    myDashBoard();
    checkPaymentStatus();
  }, []);

  const paymentNavigation = () => {
    navigate("/payment-selection");
  };

  const options = {
    title: "Jobs Dashboard",
    chartArea: { width: "50%" },
    isStacked: true,
    colors: ["#FFD42F", "#8474A1", "#FE5E5E", "#089896"],
    legend: "none",
    hAxis: {
      title: "Status of jobs",
      minValue: 0,
    },
    vAxis: {
      title: "Jobs",
    },
  };
  return (
    <div className="grey-bg pt-5">
      {loading && <Loader loading={loading} />}
      {paymentStatus && (
        <>
          <div className="dashboard-head container-cstm container-cstm-mobile">
            <p>To post the job its mandatory to subscribe</p>
            <input
              type="submit"
              onClick={paymentNavigation}
              value="Subscribe"
              className="subcribe-btn"
            />
          </div>
        </>
      )}
      {chartDashBoardList.length === 0 && (
        <NoResult title={"No dashboard data"} subtitle="" />
      )}
      {chartDashBoardList.length > 0 && (
        <div className="candidate-outer ">
          <div className="container-cstm">
            <div className="dashboard-outer mt-0">
              <h3>My Active Jobs</h3>
              {chartDashBoardList.length > 0 && (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="400px"
                  data={chartDashBoardList}
                  options={options}
                />
              )}
              <div className="segment-section">
                <p>
                  <i className="fa fa-square yellow" aria-hidden="true"></i>New
                </p>
                <p>
                  <i className="fa fa-square purple" aria-hidden="true"></i>{" "}
                  Under Review
                </p>
                <p>
                  <i className="fa fa-square red" aria-hidden="true"></i>
                  Rejected
                </p>
                <p>
                  <i className="fa fa-square green" aria-hidden="true"></i>
                  Accepted
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default DashBoardPage;
