import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  APIConstants,
  STRIPE_ACCOUNTID,
  STRIPE_KEY,
} from "../../../utils/config";
import CheckoutForm from "./checkoutform";
import { PostApiCall } from "../../../utils/axios";
import { useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(STRIPE_KEY, {
  stripeAccount: STRIPE_ACCOUNTID,
});
const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const paymentIntantBody = {
      membership_plan_id: searchParams.get("id"),
    };
    PostApiCall(APIConstants.PAYMENT_INTENT, paymentIntantBody).then((data) => {
      setClientSecret(data.data.clientSecret);
    });
  }, []);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <>
      {stripePromise && clientSecret !== "" && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            clientSecret={clientSecret}
            membershipId={searchParams.get("id")}
            returnurl={searchParams.get('returnurl')}
          />
        </Elements>
      )}
    </>
  );
};

export default PaymentPage;
