import { CommonActionTypes } from "./common-enum";

export const initialIndustryState = {
  creationCompany: null,
};

export const CreateCompanyReducer = (state = initialIndustryState, action) => {
  switch (action.type) {
    case CommonActionTypes.COMPANYCREATE:
      return { ...state, creationCompany: action.creationCompany };
    case CommonActionTypes.COMPANYCLEAR:
      return { ...state, creationCompany: null };
    default:
      return state;
  }
};

export const initialAuthenicationState = {
  signInPopUp: false,
  signUpPopUp: false,
};

export const AuthenticationPopUpReducer = (
  state = initialAuthenicationState,
  action
) => {
  switch (action.type) {
    case CommonActionTypes.AUTHENTICATIONSSIGNIN:
      return { ...state, signInPopUp: action.signInPopUp };
    case CommonActionTypes.AUTHENTICATIONSSIGNUP:
      return { ...state, signUpPopUp: action.signUpPopUp };
    case CommonActionTypes.AUTHENTICATIONSCLEAR:
      return initialAuthenicationState;
    default:
      return state;
  }
};

export const initialAuthenicationEmployerState = {
  signInPopUpEmployer: false,
  signUpPopUpEmployer: false,
};
export const AuthenticationPopUpEmployerReducer = (
  state = initialAuthenicationEmployerState,
  action
) => {
  switch (action.type) {
    case CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNIN:
      return { ...state, signInPopUpEmployer: action.signInPopUpEmployer };
    case CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNUP:
      return { ...state, signUpPopUpEmployer: action.signUpPopUpEmployer };
    case CommonActionTypes.AUTHENTICATIONSCLEAR:
      return initialAuthenicationEmployerState;
    default:
      return state;
  }
};

export const initialSetupAccountState = {
  setupMenuSelected: 2,
  setupMenuData: [],
};
export const SetupAccountReducer = (
  state = initialSetupAccountState,
  action
) => {
  switch (action.type) {
    case CommonActionTypes.SETUPACCOUNTMENULIST:
      return { ...state, setupMenuData: action.setupMenuData };
    case CommonActionTypes.SETUPACCOUNTMENU:
      return { ...state, setupMenuSelected: action.setupMenuSelected };
    case CommonActionTypes.SETUPACCOUNTMENUCLEAR:
      return { ...state, setupMenuSelected: 2 };
    case CommonActionTypes.SETUPACCOUNTMENUUPDATE:
      return { ...state, setupMenuData: action.setupMenuData };
    default:
      return state;
  }
};

export const initialLoadingState = {
  loading: false,
};
export const LoadingReducer = (state = initialSetupAccountState, action) => {
  switch (action.type) {
    case CommonActionTypes.SETUPACCOUNTMENULIST:
      return { ...state, setupMenuData: action.setupMenuData };
    case CommonActionTypes.SETUPACCOUNTMENU:
      return { ...state, setupMenuSelected: action.setupMenuSelected };
    case CommonActionTypes.SETUPACCOUNTMENUCLEAR:
      return { ...state, setupMenuSelected: 2 };
    case CommonActionTypes.SETUPACCOUNTMENUUPDATE:
      return { ...state, setupMenuData: action.setupMenuData };
    default:
      return state;
  }
};
