/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {APIConstants} from "../../../utils/config";
import {GetApiCall, PostApiCall} from "../../../utils/axios";
import Loader from "../../../components/loader";
import {RoleTypeContext, UserIdContext} from "../../../contexts/auth-context";
import {toast} from "react-toastify";
import moment from "moment/moment";
import Footer from "../../../components/footer";

const JobDescriptionPage = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [previewResponse, setPreviewResponse] = useState({});
    const roleTypeContext = useContext(RoleTypeContext);
    const userIdContext = useContext(UserIdContext);

    const myjobDetails = () => {
        setLoading(true);
        GetApiCall(APIConstants.JOBPREVIEW_URL + searchParams.get("id")).then(
            (data) => {
                setPreviewResponse(data.data);
                setLoading(false);
            }
        );
    };
    const SavedJobs = () => {
        PostApiCall(APIConstants.SAVEJOB_URL + searchParams.get("id")).then(
            (data) => {
                if (data.status) {
                    setPreviewResponse({...previewResponse, isSaved: !previewResponse.isSaved});
                    toast(data.message);
                } else {
                    toast("You have already saved this job");
                }
            }
        );
    };
    const ApplyJobs = () => {
        PostApiCall(APIConstants.APPLYJOB_URL + searchParams.get("id")).then(
            (data) => {
                setPreviewResponse({...previewResponse, isApplied: true});
                toast(data.message);
            }
        );
    };

    const appliedButton = () => {
        ApplyJobs();
    };
    useEffect(() => {
        myjobDetails();
    }, []);
    return (
        <div>
            {loading && <Loader loading={loading}/>}
            <div className="grey-bg">
                <div className="candidate-outer ">
                    <div className="container-cstm">
                        <div className="content-inner">
                            <div className="cmp-desg-apply-section">
                                <div className="cmp-desg">
                                    <img
                                        src={previewResponse?.employer_profile?.logo}
                                    />
                                    <div className="desg-sec">
                                        <p>{previewResponse?.title}</p>
                                        <p>
                      <span>
                        {" "}
                          {previewResponse?.employer_profile?.company_name}.{previewResponse?.city} , {previewResponse?.state},{" "}
                          {previewResponse?.country}.{" "}
                          {previewResponse?.job_type?.name}
                      </span>
                                        </p>
                                        <p>{moment(previewResponse.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                {userIdContext !== null && roleTypeContext !== "Employer" && (
                                    <div className="apply-section">
                                        <p className="bookmark" onClick={(e) => SavedJobs()}>
                                            Save this job{" "}
                                            <a
                                                href="#"
                                                className={`${previewResponse.isSaved && "active"}`}
                                            >
                                                <i class="fa fa-bookmark-o" aria-hidden="true"></i>
                                                <i class="fa fa-bookmark" aria-hidden="true"></i>
                                            </a>
                                        </p>
                                        <p className="apply-btn" onClick={(e) => appliedButton()}>
                                            <a href="#">
                                                {" "}
                                                {previewResponse?.isApplied ? "Applied" : "Apply Now"}
                                                {!previewResponse?.isApplied && (
                                                    <i
                                                        className="fa fa-arrow-right"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </a>
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="salary-rate">
                                <p>
                                    Salary:{" "}
                                    <span>{previewResponse?.average_salary} /annually</span>
                                </p>
                            </div>
                            <div className="job-desc">
                                <p>
                                    <strong> Essential Duties & Responsibilities </strong>
                                </p>
                                <ul>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: previewResponse?.duties_and_responsibilty,
                                        }}
                                    ></li>
                                </ul>

                                <p>
                                    <strong> Qualifications and Education Requirements </strong>
                                </p>
                                <ul>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: previewResponse?.requirements,
                                        }}
                                    ></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
export default JobDescriptionPage;
