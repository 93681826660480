/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerApi } from "../../../redux/authenication/authenication-saga";
import { RoleType } from "../../../utils/enum";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../utils/config";
import { CommonActionTypes } from "../../../redux/common/common-enum";
import Loader from "../../../components/loader";
import { AuthenicationActionTypes } from "../../../redux/authenication/authenication-enum";

const SignUpPage = ({ isVisible, verifyEmailClick, verifyEmailOTPClick }) => {
  useEffect(() => {
    if (isVisible) {
      var modal = document.getElementById("signup");
      modal.style.display = "flex";
    }
  }, [isVisible]);
  const dispatch = useDispatch();
  const registerState = useSelector((state) => state.RegisterState);

  const closeSignUp = () => {
    var modal = document.getElementById("signup");
    modal.style.display = "none";
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSSIGNUP,
      signUpPopUp: false,
    });
    dispatch({
      type: AuthenicationActionTypes.CLEARVALUES,
    });
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    repeatpassword: "",
    role_type: RoleType.CANDIDATE,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (registerState.registerStatus !== null && registerState.registerStatus) {
      toast("Account created successfully");
      verifyEmailClick(true);
      verifyEmailOTPClick(formData.email);
      dispatch({
        type: CommonActionTypes.AUTHENTICATIONSSIGNUP,
        signUpPopUp: false,
      });
    } else if (
      registerState.registerStatus !== null &&
      !registerState.registerStatus
    ) {
      toast(registerState.registerErrorMessage);
    }
  }, [registerState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.email === "") {
      toast("Enter the email address");
    } else if (!isValidEmail(formData.email)) {
      toast("Invalid Email");
    } else if (formData.password === "") {
      toast("Enter the password");
    } else if (formData.password.length < 8) {
      toast("Password must be at least 8 chars long");
    } else if (formData.repeatpassword === "") {
      toast("Enter the repeat password");
    } else if (formData.password !== formData.repeatpassword) {
      toast("Password doesnt match");
    } else {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      dispatch(registerApi(formData));
    }
  };

  return (
    <div id="signup" className="modal popup-form signup">
      {registerState.loading && <Loader loading={registerState.loading} />}
      <div className="modal-content">
        <span className="close" onClick={() => closeSignUp()}>
          &times;
        </span>
        <h2>Sign up</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="E-mail"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
          />
          <input
            type="password"
            placeholder="Repeat Password"
            name="repeatpassword"
            id="repeatpassword"
            value={formData.repeatpassword}
            onChange={handleChange}
          />
          <input type="submit" value="Signup" />
        </form>
        <p>
          Already have an account?{" "}
          <a
            href="#"
            onClick={() => {
              dispatch({
                type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
                signInPopUp: true,
              });
              dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
              });
              dispatch({
                type: CommonActionTypes.AUTHENTICATIONSSIGNUP,
                signUpPopUp: false,
              });
            }}
          >
            Sign in{" "}
          </a>{" "}
        </p>
        <p className="or-dvdr">
          <span>or</span>
        </p>
        <div className="social-m">
          <div className="google">
            <i className="fa fa-google" aria-hidden="true"></i>{" "}
            <span>Google</span>{" "}
          </div>
          <div className="facebook">
            <i className="fa fa-facebook" aria-hidden="true"></i>
            <span>Facebook</span>{" "}
          </div>
          <div className="linkedin">
            <i className="fa fa-linkedin" aria-hidden="true"></i>
            <span>Linkedin</span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpPage;
