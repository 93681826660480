/* eslint-disable jsx-a11y/anchor-is-valid */

const PreviewDetailsPage = () => {
  return (
    <>
      <div className="inner-content-right emp-step-preview">
        <h2>Preview</h2>
        <p>Click to see the details.</p>

        <div className="cmp-desg-outer1">
          <div className="cmp-desg-apply-section">
            <div className="cmp-desg">
              <img src={require("../../../assets/images/Slack.jpg")} alt="" />
              <div className="desg-sec">
                <p>Digital Product Designer - UI/UX Designer</p>
                <p>
                  <span>Slack . Ottawa, Ontario, Canada . Part time</span>
                </p>
                <p>Today</p>
              </div>
            </div>
          </div>
          <div className="salary-rate">
            <p>
              Salary: <span>12.5$+ /hour</span>
            </p>
          </div>
          <div className="job-desc">
            <p>
              <strong> Essential Duties & Responsibilities </strong>
            </p>
            <ul>
              <li>
                Translating concepts into wireframes and mockups that lead to
                intuitive user experiences.
              </li>
              <li>
                Facilitating client’s product visions by researching,
                conceiving, wireframing, sketching, prototyping, and mocking up
                user experiences for digital products.
              </li>
              <li>
                Iteratively designing and delivering wireframes, user stories,
                user journeys, and mockups optimized for a wide range of devices
                and interfaces.{" "}
              </li>
              <li>
                Identifying design problems and devising elegant solutions.{" "}
              </li>
              <li>
                Making strategic design and user-experience decisions related to
                core, and new functions and features.
              </li>
              <li>
                Taking a user-centered design approach and rapidly test and
                iterate your designs.
              </li>
              <li>Taking smart risks and championing new ideas.</li>
              <li>
                Work closely with the Art Director & Design team to develop a
                cohesive experience throughout our platform in order to increase
                user satisfaction.
              </li>
              <li>
                Conduct user testing to validate the design, interaction and
                content.
              </li>
              <li>
                Apply research findings into design recommendations and plans.{" "}
              </li>
            </ul>

            <p>
              <strong> Qualifications and Education Requirements </strong>
            </p>
            <ul>
              <li>
                4+ years of UX design experience (preference will be given to
                candidates who have experience designing complex solutions for
                complete digital environments){" "}
              </li>
              <li>
                Expertise in standard UX software such as Figma, Zeplin, Sketch,
                Axure, InVision, Balsamiq and the like is a must Ability to work
                with stakeholders to understand detailed requirements and design
                complete user experiences that meet stakeholders needs and
                vision.
              </li>
              <li>
                Extensive experience in using UX design best practices to design
                solutions, and a deep understanding of mobile-first, responsive
                and native mobile app design.
              </li>
              <li>
                A solid grasp of user-centered design (UCD), planning and
                conducting user research, user testing, A/B testing.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default PreviewDetailsPage;
