import {GetApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import { call, put, takeLatest } from "redux-saga/effects";
import {MembershipPlansActionTypes} from "./membership-enum";

export function membershipApi() {
  return {
    type: MembershipPlansActionTypes.MEMBERSHIPPLANSAPIREQUESTED,
  };
}

async function membershipService() {
  return GetApiCall(APIConstants.MEMBERSHIPPLANS_URL).then((data) => {
    return data;
  });
}

export function* doMembership() {
  yield put({ type: MembershipPlansActionTypes.MEMBERSHIPPLANSREQUEST, loading: true });
  const membershipResponse = yield call(membershipService);
  if (membershipResponse.data.length > 0) {
    yield put({
      type: MembershipPlansActionTypes.MEMBERSHIPPLANSSUCCESS,
      status: true,
      membershipPlans: membershipResponse.data,
    });
  } else {
    yield put({
      type: MembershipPlansActionTypes.MEMBERSHIPPLANSERROR,
      status: false,
    });
  }
  yield put({ type: MembershipPlansActionTypes.MEMBERSHIPPLANSREQUEST, loading: false });
}

export function* membershipSaga() {
  yield takeLatest(MembershipPlansActionTypes.MEMBERSHIPPLANSAPIREQUESTED, doMembership);
}
