/* eslint-disable jsx-a11y/anchor-is-valid */

import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {GetApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import Loader from "../../../components/loader";
import {SetupAccountMenuRoutes} from "../../../utils/enum";
import {CommonActionTypes} from "../../../redux/common/common-enum";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

const PreviewPage = () => {
    const {state} = useLocation();
    const {id} = state;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [previewResponse, setPreviewResponse] = useState(null);
    const [count, pageName, setCount] = useOutletContext();
    const setupAccountState = useSelector((state) => state.SetupAccountState);

    useEffect(() => {
        setLoading(true);
        GetApiCall(APIConstants.JOBPREVIEW_URL + id).then((data) => {
            setPreviewResponse(data.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (count > 0 && pageName === SetupAccountMenuRoutes.Preview) {
            setCount(0);
            dispatch({
                type: CommonActionTypes.SETUPACCOUNTMENU,
                setupMenuSelected: 9,
            });
            const menuIndex = setupAccountState.setupMenuData.findIndex(
                (mI) => mI.id === 9
            );
            let accountMenuList = [...setupAccountState.setupMenuData];
            accountMenuList[menuIndex].isFilled = true;
            dispatch({
                type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
                setupMenuData: accountMenuList,
            });
            toast("Posted Job Successfully");
            if (!setupAccountState.setupMenuData[0].isShow) {
                navigate("/my-job");
            } else {
                navigate("/dashBoard");
            }
        }
    }, [count]);

    return (
        <>
            {loading && <Loader loading={loading}/>}

            {previewResponse !== null && (
                <div className="emp-step-preview">
                    <h2>Preview</h2>
                    {/* <p>Click to see the details.</p> */}

                    <div className="cmp-desg-outer1">
                        <div className="cmp-desg-apply-section">
                            <div className="cmp-desg">
                                <img src={previewResponse.employer_profile.logo} alt=""/>

                                <div className="desg-sec">
                                    <p>{previewResponse.title}</p>
                                    <p>
                    <span>
                      {previewResponse.employer_profile.city} .{" "}
                        {previewResponse.employer_profile.state},{" "}
                        {previewResponse.employer_profile.address}, India .{" "}
                        {previewResponse.job_type.name}
                    </span>
                                    </p>
                                    <p>Today</p>
                                </div>
                            </div>
                        </div>
                        <div className="salary-rate">
                            <p>
                                Salary: <span>12.5$+ /hour</span>
                            </p>
                        </div>
                        <div className="job-desc">
                            <p>
                                <strong> Essential Duties & Responsibilities </strong>
                            </p>
                            <ul>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: previewResponse.duties_and_responsibilty,
                                    }}
                                ></li>
                            </ul>

                            <p>
                                <strong> Qualifications and Education Requirements </strong>
                            </p>
                            <ul>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: previewResponse.requirements,
                                    }}
                                ></li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default PreviewPage;
