import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Footer from "../../../components/footer";

const SetUpCompanyProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [profileSelected, setProfileSelected] = useState("company-info");
    const onNavigationHandler = (routePath) => {
        navigate(routePath);
    };

    useEffect(() => {
        if (location.pathname.includes("/employer-profile/company-info")) {
            setProfileSelected("company-info");
        } else if (location.pathname.includes("/employer-profile/transaction-history")) {
            setProfileSelected("transaction-history");
        }
    }, []);

    return (
        <div className="grey-bg pt-5">
            <div className="candidate-outer ">
                <div className="container-cstm">
                    <div className="profile-outer mb-0 pb-5 mt-0">
                        <div className="profile-outer profile-outer-tab mt-0">
                            <div className="tab">
                                <p
                                    className={`tablinks ${profileSelected === "company-info" && 'active'}`}
                                    onClick={() => {
                                        onNavigationHandler("/employer-profile/company-info");
                                        setProfileSelected('company-info');
                                    }
                                    }
                                >
                                    <span>Company Info</span>
                                </p>
                                <p
                                    className={`tablinks ${profileSelected === "transaction-history" && 'active'}`}
                                    onClick={() => {
                                        onNavigationHandler("/employer-profile/transaction-history");
                                        setProfileSelected('transaction-history');
                                    }
                                    }
                                >
                                    <span>Transaction History</span>
                                </p>
                            </div>
                            {/* <div className="comapany-dashboard">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      <span>Post job</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      <span>Edit job</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      <span>Applicants</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      <span>Questionnaire</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      <span>Setting</span>
                    </a>
                  </li>
                </ul>
              </div> */}
                        </div>

                        <div>
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default SetUpCompanyProfile;
