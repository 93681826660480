// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loginApi} from "../../../redux/authenication/authenication-saga";
import {toast} from "react-toastify";
import {isValidEmail} from "../../../utils/config";
import Loader from "../../../components/loader";
import {CommonActionTypes} from "../../../redux/common/common-enum";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../contexts/auth-context";
import {AuthenicationActionTypes} from "../../../redux/authenication/authenication-enum";
import {SessionStorage} from "../../../utils/enum";

const SignInPage = ({
                        isVisible,
                        closeAuthPopup,
                        forgotPasswordClick,
                        verifyEmailClick,
                        verifyEmailOTPClick,
                    }) => {
    const auth = useContext(AuthContext);
    useEffect(() => {
        if (isVisible) {
            var modal = document.getElementById("signin");
            modal.style.display = "flex";
        }
    }, [isVisible]);

    const closeSignIn = () => {
        var modal = document.getElementById("signin");
        modal.style.display = "none";
        closeAuthPopup(false);
        dispatch({
            type: AuthenicationActionTypes.CLEARVALUES,
        });
        dispatch({
            type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
            signInPopUp: false,
        });
    };
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.LoginState);
    const navigate = useNavigate();
    const [type, setType] = useState("password");

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleToggle = () => {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    };

    useEffect(() => {
        if (loginState.loginStatus !== null && !loginState.loginStatus) {
            if (!loginState.loginStatus && !loginState.isVerified) {
                if (
                    loginState.loginErrorMessage?.email !== undefined &&
                    loginState.loginErrorMessage?.email.length > 0 &&
                    loginState.loginErrorMessage?.email[0] === "Email does not exist."
                ) {
                    toast(loginState.loginErrorMessage.email[0]);
                } else if (
                    loginState.loginErrorMessage ===
                    "Please verify your email before logging in."
                ) {
                    verifyEmailClick(true);
                    verifyEmailOTPClick(formData.email);
                    dispatch({
                        type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
                        signInPopUp: false,
                    });
                } else if (loginState.loginErrorMessage === "Password doesn't match") {
                    toast(loginState.loginErrorMessage);
                }
            } else if (loginState.loginErrorMessage === "Password doesn't match") {
                toast(loginState.loginErrorMessage);
            }
        } else if (loginState.loginStatus) {
            closeAuthPopup(false);
            toast("SignedIn Successfully");
            dispatch({
                type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
                signInPopUp: false,
            });
            dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
            });
            auth.signIn(
                JSON.stringify(loginState.loginData.data),
                loginState.loginData.role_type
            );
            if (loginState.loginData.role_type === "Employer") {
                localStorage.setItem(SessionStorage.ISPROFILECOMPLETED, loginState.loginData.isProfileCompleted);
                if (loginState.loginData.isProfileCompleted) {
                    navigate("/dashboard");
                } else {
                    navigate("/company-info/");
                }
            } else {
                navigate("/");
            }
        }
    }, [loginState]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.email === "") {
            toast("Enter the email address");
        } else if (!isValidEmail(formData.email)) {
            toast("Invalid Email");
        } else if (formData.password === "") {
            toast("Enter the password");
        } else if (formData.password.length < 8) {
            toast("Password must be at least 8 chars long");
        } else {
            dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
            });
            dispatch(loginApi(formData));
        }
    };

    return (
        <div>
            <div id="signin" className="modal signin popup-form">
                {loginState.loading && <Loader loading={loginState.loading}/>}
                <div className="modal-content">
          <span className="close" onClick={() => closeSignIn()}>
            &times;
          </span>
                    <h2>Sign in</h2>
                    <div className="social-m">
                        <div className="google">
                            <i className="fa fa-google" aria-hidden="true"></i>{" "}
                            <span>Sign in with Google</span>{" "}
                        </div>
                    </div>

                    <p className="or-dvdr">
                        <span>or</span>
                    </p>

                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="E-mail"
                            name="email"
                            id="email"
                            onChange={handleChange}
                        />
                        <div className="pswrd">
                            <input
                                type={type}
                                placeholder="Password"
                                name="password"
                                id="password"
                                onChange={handleChange}
                            />
                            <i
                                className={`fa ${
                                    type === "password" ? "fa-eye-slash" : "fa-eye"
                                }`}
                                aria-hidden="true"
                                onClick={handleToggle}
                            ></i>
                        </div>
                        <p className="frgtps">
                            <a href="#" onClick={() => forgotPasswordClick(true)}>
                                Forgot Password?
                            </a>{" "}
                        </p>
                        <div>
                            <input type="submit" value="Sign in"/>
                        </div>
                    </form>

                    <p>
                        Don't have an account?{" "}
                        <a
                            href="#"
                            onClick={() => {
                                dispatch({
                                    type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
                                    signInPopUp: false,
                                });
                                dispatch({
                                    type: AuthenicationActionTypes.CLEARVALUES,
                                });
                                dispatch({
                                    type: CommonActionTypes.AUTHENTICATIONSSIGNUP,
                                    signUpPopUp: true,
                                });
                            }}
                        >
                            Sign up
                        </a>{" "}
                    </p>
                </div>
            </div>
        </div>
    );
};
export default SignInPage;
