// @ts-nocheck
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {AuthenicationActionTypes} from "../../../redux/authenication/authenication-enum";
import {verifyEmailApi} from "../../../redux/authenication/authenication-saga";
import Loader from "../../../components/loader";

const VerifyEmailPopUpPage = ({
                                  isVisible,
                                  closeAuthPopup,
                                  isVerifyEmailOtp,
                              }) => {
    useEffect(() => {
        if (isVisible) {
            var modal = document.getElementById("em-otp");
            modal.style.display = "block";
        }
    }, [isVisible]);

    const closeSignIn = () => {
        var modal = document.getElementById("em-otp");
        modal.style.display = "none";
        closeAuthPopup(false);
        dispatch({
            type: AuthenicationActionTypes.CLEARVALUES,
        });
    };

    const dispatch = useDispatch();
    const verifyEmailState = useSelector((state) => state.VerifyEmailOTPState);
    const [formData, setFormData] = useState({
        email: isVerifyEmailOtp,
        otp: "",
    });
    const [otpData, setOtpData] = useState({});
    const handleChange = (e) => {
        setOtpData({
            ...otpData,
            [e.target.name]: e.target.value,
        });
        const active = document.activeElement;
        if (active?.nextElementSibling) {
            active.nextElementSibling.focus();
        }
    };
    const onDelete = (e) => {
        // if (e.keyCode === 8) {
        //   const active = document.activeElement;
        //   if (active?.previousElementSibling) {
        //     active.previousElementSibling.focus();
        //   }
        // }
    };
    useEffect(() => {
        if (
            verifyEmailState?.isEmailVerified !== null &&
            verifyEmailState?.isEmailVerified
        ) {
            toast("Email verification successful");
            closeAuthPopup(false);
        } else if (
            verifyEmailState?.isEmailVerified !== null &&
            !verifyEmailState?.isEmailVerified
        ) {
            toast("Invalid verification code");
        }
    }, [verifyEmailState]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Object.keys(otpData).length > 0) {
            let otpValue;
            for (const key in otpData) {
                if (otpValue !== undefined) {
                    otpValue = otpValue + otpData[key];
                } else {
                    otpValue = otpData[key];
                }
            }
            if (otpValue !== undefined && otpValue.length < 6) {
                toast("Please enter 6 digits code");
            } else {
                const verifyBody = {
                    email: isVerifyEmailOtp,
                    otp: otpValue,
                };
                dispatch({
                    type: AuthenicationActionTypes.CLEARVALUES,
                });
                dispatch(verifyEmailApi(verifyBody));
            }
        } else {
            toast("Please enter 6 digits code");
        }

    };
    return (
        <div id="em-otp" class="modal popup-form forgetpass">
            {verifyEmailState.loading && (
                <Loader loading={verifyEmailState.loading}/>
            )}
            <div class="modal-content">
        <span class="close" onClick={() => closeSignIn()}>
          &times;
        </span>
                <h2>Email Verification </h2>
                <p>
                    Please Enter the OTP to Verify your Email. A OTP (One Time Password)
                    has been sent to your Email
                </p>
                <form onSubmit={handleSubmit}>
                    <div class="otp-field d-flex gap-2">
                        <input
                            type="text"
                            name="otp"
                            class="text-center pl-0"
                            placeholder="X"
                            maxlength="1"
                            onChange={handleChange}
                            onKeyDown={onDelete}
                        />
                        <input
                            type="text"
                            name="otp1"
                            class="text-center pl-0"
                            placeholder="X"
                            maxlength="1"
                            onChange={handleChange}
                            onKeyDown={onDelete}
                        />
                        <input
                            class="text-center pl-0"
                            type="text"
                            name="otp2"
                            placeholder="X"
                            maxlength="1"
                            onChange={handleChange}
                            onKeyDown={onDelete}
                        />
                        <input
                            type="text"
                            name="otp3"
                            class="text-center pl-0"
                            placeholder="X"
                            maxlength="1"
                            onChange={handleChange}
                            onKeyDown={onDelete}
                        />
                        <input
                            type="text"
                            name="otp4"
                            class="text-center pl-0"
                            placeholder="X"
                            maxlength="1"
                            onChange={handleChange}
                            onKeyDown={onDelete}
                        />
                        <input
                            type="text"
                            name="otp5"
                            class="text-center pl-0"
                            placeholder="X"
                            maxlength="1"
                            onChange={handleChange}
                            onKeyDown={onDelete}
                        />
                    </div>
                    <input class="bg-black otp-btn" type="submit" value="Validate OTP"/>
                </form>
            </div>
        </div>
    );
};
export default VerifyEmailPopUpPage;
