export const RoleType = {
    CANDIDATE: "candidate",
    EMPLOYER: "employer",
};

export const SetupAccountMenuList = [
    {
        id: 1,
        name: "Setup Account",
        title: true,
        isFilled: true,
        isShow:true
    },
    {
        id: 2,
        name: "Company Info",
        title: false,
        isFilled: false,
        isShow:true
    },
    {
        id: 3,
        name: "Prices",
        title: false,
        isFilled: false,
        isShow:true
    },
    {
        id: 4,
        name: "Job Details",
        title: true,
        isFilled: true,
        isShow:true
    },
    {
        id: 5,
        name: "Job Info",
        title: false,
        isFilled: false,
        isShow:true
    },
    {
        id: 6,
        name: "Job Description",
        title: false,
        isFilled: false,
        isShow:true
    },
    {
        id: 7,
        name: "Screening Questionnaire",
        title: false,
        isFilled: false,
        isShow:true
    },
    {
        id: 8,
        name: "Sponsoring",
        title: false,
        isFilled: false,
        isShow:true
    },
    {
        id: 9,
        name: "Preview and Publish",
        title: false,
        isFilled: false,
        isShow:true
    }
];

export const SetupAccountMenuRoutes = {
    CompanyInfo: '/company-info/',
    JobInfo: '/company-info/job-info',
    JobDescription: '/company-info/job-description',
    Prices: '/company-info/prices',
    Questionaire: '/company-info/questionnaire',
    Sponsoring: '/company-info/sponsoring',
    Preview: '/company-info/preview',
}

export const SessionStorage = {
    SESSION: "session",
    ROLETYPE: "role_type",
    ISPROFILECOMPLETED:"isProfileCompleted"
}
