import React, { useCallback, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MyProfitFilterMenu from "../../../components/myProfitFilterMenu";
import { debounce } from "../../../utils/config";
import { useDispatch } from "react-redux";
import {
  myProfileAppliedJobsApi,
  myProfileSavedJobsApi,
} from "../../../redux/candidates/my-profile-jobs/my-profile-saga";
import Footer from "../../../components/footer";

const ProfileAppliedSavedJobs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const onNavigationHandler = (routePath) => {
    navigate(routePath);
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const [jobSelected, setJobSelected] = useState("applied-jobs");

  const searchOnChangrHandler = (e) => {
    setSearchValue(e.target.value);
    jobSearch(e.target.value);
  };

  const jobSearch = useCallback(
    debounce((val) => {
      if (jobSelected === "saved-jobs") {
        dispatch(myProfileSavedJobsApi("?title=" + val));
      } else if (jobSelected === "applied-jobs") {
        dispatch(myProfileAppliedJobsApi("?title=" + val));
      }
    }, 1000),
    [jobSelected]
  );

  useEffect(() => {
    if (location.pathname.includes("/my-profile/applied-jobs")) {
      setJobSelected("applied-jobs");
    } else if (location.pathname.includes("/my-profile/saved-jobs")) {
      setJobSelected("saved-jobs");
    }
    dispatch(myProfileAppliedJobsApi(""));
    dispatch(myProfileSavedJobsApi(""));
  }, []);
  const sortByProfile = (sortValue) => {
    if (jobSelected === "saved-jobs") {
      if (searchParams.has("order")) {
        searchParams.delete("order");
      }
      searchParams.append("order", sortValue);
      setSearchParams(searchParams);
    } else if (jobSelected === "applied-jobs") {
      if (searchParams.has("order")) {
        searchParams.delete("order");
      }
      searchParams.append("order", sortValue);
      setSearchParams(searchParams);
    }
  };
  const statusJobs = (statusJobsList) => {
    if (jobSelected === "saved-jobs") {
      if (statusJobsList.includes("all")) {
        searchParams.delete("status");
        setSearchParams(searchParams);
        dispatch(myProfileAppliedJobsApi(""));
      } else {
        if (statusJobsList.length === 0) {
          searchParams.delete("status");
          setSearchParams(searchParams);
          dispatch(myProfileSavedJobsApi(""));
        } else {
          if (searchParams.has("status")) {
            searchParams.delete("status");
          }
          searchParams.append("status", statusJobsList);
          setSearchParams(searchParams);
        }
      }
    } else if (jobSelected === "applied-jobs") {
      if (statusJobsList.includes("all")) {
        searchParams.delete("status");
        setSearchParams(searchParams);
        dispatch(myProfileAppliedJobsApi(""));
      } else {
        if (statusJobsList.length === 0) {
          searchParams.delete("status");
          setSearchParams(searchParams);
          dispatch(myProfileAppliedJobsApi(""));
        } else {
          if (searchParams.has("status")) {
            searchParams.delete("status");
          }
          searchParams.append("status", statusJobsList);
          setSearchParams(searchParams);
        }
      }
    }
  };

  useEffect(() => {
    if (jobSelected === "saved-jobs") {
      dispatch(myProfileSavedJobsApi(location.search));
    } else if (jobSelected === "applied-jobs") {
      dispatch(myProfileAppliedJobsApi(location.search));
    }
  }, [searchParams]);

  return (
    <>
      <div class="candidate-outer grey-bg pt-64">
        <div class="container-cstm">
          <div class="job-outer appliedjob mt-0">
            <MyProfitFilterMenu
              jobSelected={jobSelected}
              sortBy={sortByProfile}
              statusJobs={statusJobs}
            />
            <div class="content-inner">
              <div class="profile-outer m-0 w-100">
                <div class="d-flex tab-white-form">
                  <div class="tab">
                    <p
                      class={`tablinks ${
                        jobSelected === "applied-jobs" && "active"
                      }`}
                      onClick={() => {
                        setSearchValue("");
                        setJobSelected("applied-jobs");
                        onNavigationHandler("/my-profile/applied-jobs");
                        dispatch(myProfileAppliedJobsApi(""));
                      }}
                    >
                      <span>Applied Jobs</span>
                    </p>
                    <p
                      class={`tablinks ${
                        jobSelected === "saved-jobs" && "active"
                      }`}
                      onClick={() => {
                        setSearchValue("");
                        setJobSelected("saved-jobs");
                        onNavigationHandler("/my-profile/saved-jobs");
                        dispatch(myProfileSavedJobsApi(""));
                      }}
                    >
                      <span>Saved Jobs</span>
                    </p>
                  </div>
                  <div class="white-form-search">
                    <form class="h-100">
                      <div class="frm-lft">
                        <div class="txt-otr">
                          <i class="fa fa-search" aria-hidden="true"></i>
                          <input
                            type="text"
                            value={searchValue}
                            placeholder="Job title, Company,…"
                            onChange={searchOnChangrHandler}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default ProfileAppliedSavedJobs;
