/* eslint-disable jsx-a11y/anchor-is-valid */

import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {useEffect} from "react";
import {SetupAccountMenuRoutes} from "../../../utils/enum";
import {CommonActionTypes} from "../../../redux/common/common-enum";
import {useDispatch, useSelector} from "react-redux";

const SponsoringPage = () => {
    const {state} = useLocation();
    const {id} = state;
    const dispatch = useDispatch();
    const setupAccountState = useSelector((state) => state.SetupAccountState);
    const navigate = useNavigate();
    const [count, pageName, setCount] = useOutletContext();

    useEffect(() => {
        if (count > 0 && pageName === SetupAccountMenuRoutes.Sponsoring) {
            setCount(0);
            dispatch({
                type: CommonActionTypes.SETUPACCOUNTMENU,
                setupMenuSelected: 9,
            });
            const menuIndex = setupAccountState.setupMenuData.findIndex(mI => mI.id === 8);
            let accountMenuList = [...setupAccountState.setupMenuData];
            accountMenuList[menuIndex].isFilled = true;
            dispatch({
                type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
                setupMenuData: accountMenuList,
            });
            navigate('/company-info/preview', {state: {id: id}});
        }
    }, [count]);


    return (
        <>
            <div className="inner-content-right">
                <h2>Sponsoring</h2>
                <p>
                    You can get sponsoring in order to get more views and more applies.
                </p>
                <form>
                    <div className="frmcontainer">
                        <select name="test" aria-invalid="false">
                            <option value="Tomato">1</option>
                            <option value="Banana">2</option>
                            <option value="Apple">3</option>
                        </select>{" "}
                    </div>

                    <p>Price: 30$</p>
                </form>
            </div>

        </>
    );
};
export default SponsoringPage;
