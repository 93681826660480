// @ts-nocheck
import axios from "axios";
import {baseUrl} from "./config";
import {SessionStorage} from "./enum";

axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(
    async (config) => {
        const session = JSON.parse(localStorage.getItem(SessionStorage.SESSION));
        if (session) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${session.access_token}`,
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const axiosPrivate = axios;
