import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {forgotPasswordApi} from "../../../redux/authenication/authenication-saga";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {isValidEmail} from "../../../utils/config";
import {AuthenicationActionTypes} from "../../../redux/authenication/authenication-enum";
import Loader from "../../../components/loader";
import {CommonActionTypes} from "../../../redux/common/common-enum";

const ForgotPasswordPage = ({isVisible, closeAuthPopup}) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (isVisible) {
            var modal = document.getElementById("forgetpass");
            modal.style.display = "flex";
        }
    }, [isVisible]);

    const closeSignIn = () => {
        var modal = document.getElementById("forgetpass");
        modal.style.display = "none";
        closeAuthPopup(false);
    };

    const dispatch = useDispatch();
    const forgotPasswordState = useSelector((state) => state.ForgotPasswordState);
    const [formData, setFormData] = useState({
        email: "",
    });
    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    useEffect(() => {
        if (
            forgotPasswordState.isForgotPasswordStatus !== null &&
            forgotPasswordState.isForgotPasswordStatus
        ) {
            dispatch({
                type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
                signInPopUp: false,
            });
            dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
            });
            closeSignIn();
            toast(
                "Please follow the link as sent to your registered email-id to reset your password"
            );
            // navigate("/resetpassword/" + formData.email);
        } else if (
            forgotPasswordState.isForgotPasswordStatus !== null &&
            !forgotPasswordState.isForgotPasswordStatus
        ) {
            toast(forgotPasswordState.forgotPasswordErrorMessage);
        }
    }, [forgotPasswordState]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.email === "") {
            toast("Enter the email address");
        } else if (!isValidEmail(formData.email)) {
            toast("Invalid Email");
        } else {
            dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
            });
            dispatch(forgotPasswordApi(formData));
        }
    };
    return (
        <div id="forgetpass" class="modal popup-form forgetpass">
            {forgotPasswordState.loading && (
                <Loader loading={forgotPasswordState.loading}/>
            )}
            <div class="modal-content">
        <span class="close" onClick={() => closeSignIn()}>
          &times;
        </span>
                <h2>Forgot password</h2>
                <p>
                    Enter the email address you used to create your account and we will
                    send you a link to reset your password.
                </p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="E-mail"
                        id=""
                        name="email"
                        onChange={handleChange}
                    />
                    <input type="submit" value="Forgot password"/>
                </form>
            </div>
        </div>
    );
};
export default ForgotPasswordPage;
