import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";
import Loader from "../../components/loader";
import Footer from "../../components/footer";
import React from "react";

const Support = () => {
  const [loading, setLoading] = useState(false);
  const [supportList, setSupportList] = useState([]);

  const SupportPage = () => {
    setLoading(true);
    GetApiCall(APIConstants.CMS_SUPPORT_URL).then((data) => {
      setLoading(false);
      setSupportList(data);
    });
  };

  useEffect(() => {
    SupportPage();
  }, []);

  const navigate = useNavigate();

  const CmsHandler = (cmsLink) => {
    navigate(cmsLink);
  };

  return (
    <div class="grey-bg p-cms">
      {loading && <Loader loading={loading} />}

      <div class="candidate-outer ">
        <div class="container-cstm">
          <div class="content-inner">
            <div class="cmp-desg-apply-section">
              <div class="job-desc">
                {supportList.data?.length > 0 &&
                  supportList.data?.map((supportData) => {
                    return (
                      <div class="about-us">
                        <h1>{supportData.title}</h1>
                        <div class="">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: supportData.body,
                            }}
                          ></p>
                        </div>

                        <br />

                        <h3>
                          <strong>Email: &nbsp;</strong>example@jobbas.com
                        </h3>
                        <h3>
                          <strong>Phone Number: &nbsp;</strong>+888,5556417
                        </h3>
                        <h3>
                          <strong>HQ Address: &nbsp;</strong>233 Spring Street,
                          East 10th Floor, NY-10013
                        </h3>

                        <br />
                        <br />

                        <p>
                          Praesent rutrum lobortis malesuada. Vestibulum
                          egestaa. Vestibulu.
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default Support;
