/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordApi } from "../../../redux/authenication/authenication-saga";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AuthenicationActionTypes } from "../../../redux/authenication/authenication-enum";
import { AuthContext } from "../../../contexts/auth-context";
import Loader from "../../../components/loader";

const ChangePasswordPage = ({ isVisible, closeAuthPopup }) => {
  const dispatch = useDispatch();
  const changePasswordState = useSelector((state) => state.ChangePasswordState);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [type, setType] = useState("password");
  const [currentType, setCurrentType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const currentToggle = () => {
    if (type === "password") {
      setCurrentType("text");
    } else {
      setCurrentType("password");
    }
  };
  const NewPasswordToggle = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
    } else {
      setNewPasswordType("password");
    }
  };

  const closeSignIn = () => {
    var modal = document.getElementById("changepassword");
    modal.style.display = "none";
    closeAuthPopup(false);
  };

  useEffect(() => {
    if (isVisible) {
      var modal = document.getElementById("changepassword");
      modal.style.display = "flex";
    }
  }, [isVisible]);

  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    changePasswordMethod();
  }, [changePasswordState]);

  const changePasswordMethod = async () => {
    if (
      changePasswordState.isChangePasswordStatus !== null &&
      changePasswordState.isChangePasswordStatus
    ) {
      closeSignIn();
      toast("Password has been changed successfully.");
      await auth.signOut();
      window.location = "/";
    } else if (
      changePasswordState.isChangePasswordStatus !== null &&
      !changePasswordState.isChangePasswordStatus
    ) {
      toast(changePasswordState.changePasswordErrorMessage);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.current_password === "") {
      toast("Enter the Current password");
    } else if (formData.current_password.length < 8) {
      toast("Password must be at least 8 chars long");
    } else if (formData.password === "") {
      toast("Enter the New password");
    } else if (formData.password_confirmation === "") {
      toast("Enter the Re-type new password");
    } else if (formData.password !== formData.password_confirmation) {
      toast("Password doesnt match");
    } else {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      dispatch(changePasswordApi(formData));
    }
  };

  return (
    <div id="changepassword" class="modal changepassword popup-form">
      {changePasswordState.loading && (
        <Loader loading={changePasswordState.loading} />
      )}
      <div class="modal-content">
        <span class="close" onClick={() => closeSignIn()}>
          &times;
        </span>
        <h2 className="mb-5">Change Password</h2>
        {/* <p>Enter your current password</p> */}
        <form onSubmit={handleSubmit}>
          <div class="pswrd">
            <input
              type={currentType}
              placeholder="Enter current password"
              name="current_password"
              id=""
              onChange={handleChange}
            />
            <i
              className={`fa ${
                currentType === "password" ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={currentToggle}
              aria-hidden="true"
            ></i>
          </div>
          <div class="pswrd">
            <input
              type={type}
              placeholder="New Password"
              name="password"
              id=""
              onChange={handleChange}
            />
            <i
              className={`fa ${
                type === "password" ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={handleToggle}
              aria-hidden="true"
            ></i>
          </div>
          <div class="pswrd">
            <input
              type={newPasswordType}
              placeholder="Re-type new password"
              name="password_confirmation"
              id=""
              onChange={handleChange}
            />
            <i
              className={`fa ${
                newPasswordType === "password" ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={NewPasswordToggle}
              aria-hidden="true"
            ></i>
          </div>
          <input type="submit" value="Submit" />
        </form>
      </div>
    </div>
  );
};
export default ChangePasswordPage;
