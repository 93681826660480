import "./App.css";
import "./assets/css/responsive.css";
import JobDescriptionPage from "./pages/candidates/Jobs/jobDescription";
import JobPage from "./pages/candidates/Jobs/jobs";
import CandidatesPage from "./pages/LandingPage/candidates";
import EmployersPage from "./pages/LandingPage/employers";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import MyDeskSavedJobsPage from "./pages/candidates/My-desk/my-desk-saved-jobs";
import SavedJobsPage from "./pages/candidates/My-desk/my-desk-saved-jobs";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./redux/store";
import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPasswordPage from "./pages/Authentications/Candidates/resetpassword";
import EmployerResetPasswordPage from "./pages/Authentications/Employers/employer-resetpassword";
import AccountSettingsPage from "./pages/candidates/profile-accountsettings/accountsettings";
import DashBoardPage from "./pages/Employer/dashBoard/dash-board";
import Header from "./components/header";
import SetUpAccount from "./pages/Employer/setupAccount";
import JobInfoPage from "./pages/Employer/setupAccount/job-info";
import CompanyInfoPage from "./pages/Employer/setupAccount/company-info";
import PreviewDetailsPage from "./pages/Employer/setupAccount/preview-details";
import JobDescriptionEmployerPage from "./pages/Employer/setupAccount/job-description";
import PricesPage from "./pages/Employer/setupAccount/prices";
import QuestionnairePage from "./pages/Employer/setupAccount/questionnaire";
import SponsoringPage from "./pages/Employer/setupAccount/sponsoring";
import PreviewPage from "./pages/Employer/setupAccount/preview";
import SetUpAccountSettings from "./pages/candidates/profile-accountsettings";
import ResumeSettingPage from "./pages/candidates/profile-accountsettings/resume-settings";
import SetUpCompanyProfile from "./pages/Employer/profileAccountSettings";
import CompanyProfileInfo from "./pages/Employer/profileAccountSettings/companyProfile-info";
import TransactionHistory from "./pages/Employer/profileAccountSettings/transaction-history";
import {
  AuthContext,
  RoleTypeContext,
  UserIdContext,
} from "./contexts/auth-context";
import { SessionStorage } from "./utils/enum";
import AppliedJobsPage from "./pages/candidates/My-desk/my-desk-applied-jobs";
import ProfileAppliedSavedJobs from "./pages/candidates/My-desk";
import MyJobPage from "./pages/Employer/dashBoard/my-jobs/my-job";
import MyJobApplicantsPage from "./pages/Employer/dashBoard/my-jobs/my-jobs-applicants";
import MyJobApplicantsDetailsPage from "./pages/Employer/dashBoard/my-jobs/my-jobs-applicants-details";
import AboutUs from "./pages/cms/about-us";
import PrivacyPolicy from "./pages/cms/privacy-policy";
import Terms from "./pages/cms/terms";
import Support from "./pages/cms/support";
import PaymentPage from "./pages/Employer/setupAccount/payment-page";
import Footer from "./components/footer";
import PricesSelectionPage from "./pages/Employer/dashBoard/price-selection";
import SignUpPage from "./pages/Authentications/Candidates/signup";
import SignInPage from "./pages/Authentications/Candidates/signIn";
import { CommonActionTypes } from "./redux/common/common-enum";
import ForgotPasswordPage from "./pages/Authentications/Candidates/forgotpassword";
import VerifyEmailPopUpPage from "./pages/Authentications/Candidates/verify-email-popup";

function PrivateCandidateRoute() {
  let auth = localStorage.getItem("session") !== null;
  let role_type = localStorage.getItem("role_type");
  if (auth && role_type !== "Candidate") {
    return <Navigate to="/dashboard" />;
  } else if (auth && role_type === "Candidate") {
    return <Outlet />;
  } else {
    return <Outlet />;
  }
}

function PrivateEmployerRoute() {
  let auth = localStorage.getItem("session") !== null;
  let role_type = localStorage.getItem("role_type");
  if (auth && role_type !== "Employer") {
    return <Navigate to="/" />;
  } else if (auth && role_type === "Employer") {
    return <Outlet />;
  } else {
    return <Outlet />;
  }
}

function PrivateCandidatesRoute() {
  let auth = localStorage.getItem("session") !== null;
  return auth ? <Outlet /> : <Navigate to="/" />;
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

function App() {
  const [savedUser, setSavedUser] = useState(null);
  const [savedRoleUser, setSavedRoleUser] = useState(null);
  const [isForgotVisible, setIsForgotVisible] = useState(false);
  const [isVerifyEmailVisible, setIsVerifyEmailVisible] = useState(false);
  const [isVerifyEmailOtp, setIsVerifyEmailOtp] = useState("");
  const dispatch = useDispatch();
  const authenticationPopUpState = useSelector(
    (state) => state.AuthenticationPopUpState
  );

  useEffect(() => {
    currentUser();
  }, []);

  const signIn = async (currentUser, roleType) => {
    if (currentUser != null) {
      localStorage.setItem(SessionStorage.SESSION, currentUser);
      localStorage.setItem(SessionStorage.ROLETYPE, roleType);
      setSavedUser(JSON.parse(currentUser));
      setSavedRoleUser(roleType);
    }
  };

  const signOut = async () => {
    localStorage.clear();
  };

  const profileUpdate = async (currentUser) => {
    try {
      localStorage.setItem(SessionStorage.SESSION, JSON.stringify(currentUser));
      const getCurrentUserRoleType = localStorage.getItem(
        SessionStorage.ROLETYPE
      );
      await signIn(JSON.stringify(currentUser), getCurrentUserRoleType);
    } catch (e) {}
  };

  const currentUser = async () => {
    try {
      const getCurrentUserValue = localStorage.getItem(SessionStorage.SESSION);
      const getCurrentUserRoleType = localStorage.getItem(
        SessionStorage.ROLETYPE
      );
      if (getCurrentUserValue != null) {
        await signIn(getCurrentUserValue, getCurrentUserRoleType);
        setSavedUser(JSON.parse(getCurrentUserValue));
        // @ts-ignore
        setSavedRoleUser(getCurrentUserRoleType);
      }
      return getCurrentUserValue != null
        ? JSON.parse(getCurrentUserValue)
        : null;
    } catch (e) {
      console.log(e);
    }
  };
  const currentUserRoleType = async () => {
    try {
      const getCurrentUserRoleValue = localStorage.getItem(
        SessionStorage.ROLETYPE
      );
      return getCurrentUserRoleValue != null ? getCurrentUserRoleValue : null;
    } catch (e) {
      console.log(e);
    }
  };

  const authContext = useMemo(
    () => ({
      signIn,
      signOut,
      currentUser,
      profileUpdate,
      currentUserRoleType,
    }),
    []
  );
  const forgotPasswordClick = (event) => {
    setIsForgotVisible(event);
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
      signInPopUp: false,
    });
  };

  const verifyEmailClick = (event) => {
    setIsVerifyEmailVisible(event);
  };

  const verifyEmailOTPClick = (event) => {
    setIsVerifyEmailOtp(event);
  };

  const closeAuthPopup = (event) => {
    setIsForgotVisible(event);
    setIsVerifyEmailVisible(event);
  };

  return (
    <AuthContext.Provider value={authContext}>
      <UserIdContext.Provider value={savedUser}>
        <RoleTypeContext.Provider value={savedRoleUser}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="h-100">
            <Header />
            <div className="candidate-outer ">
              {authenticationPopUpState.signUpPopUp && (
                <SignUpPage
                  isVisible={authenticationPopUpState.signUpPopUp}
                  verifyEmailClick={verifyEmailClick}
                  verifyEmailOTPClick={verifyEmailOTPClick}
                />
              )}
              {authenticationPopUpState.signInPopUp && (
                <SignInPage
                  isVisible={authenticationPopUpState.signInPopUp}
                  closeAuthPopup={closeAuthPopup}
                  forgotPasswordClick={forgotPasswordClick}
                  verifyEmailClick={verifyEmailClick}
                  verifyEmailOTPClick={verifyEmailOTPClick}
                />
              )}
              {isForgotVisible && (
                <ForgotPasswordPage
                  isVisible={isForgotVisible}
                  closeAuthPopup={closeAuthPopup}
                />
              )}
              {isVerifyEmailVisible && (
                <VerifyEmailPopUpPage
                  isVisible={isVerifyEmailVisible}
                  closeAuthPopup={closeAuthPopup}
                  isVerifyEmailOtp={isVerifyEmailOtp}
                />
              )}
            </div>
            <div className="p-all">
              <Routes>
                <Route path="/payment" element={<PaymentPage />} />
                <Route
                  path="/payment-selection"
                  element={<PricesSelectionPage />}
                />
                <Route element={<PrivateCandidateRoute />}>
                  <Route path="/" element={<CandidatesPage />} />
                  <Route path="/candidates" element={<CandidatesPage />} />
                  <Route
                    path="/password/reset"
                    element={<ResetPasswordPage />}
                  />
                  <Route path="/jobs" element={<JobPage />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/support" element={<Support />} />

                  <Route
                    path="/job-description"
                    element={<JobDescriptionPage />}
                  />

                  <Route element={<PrivateCandidatesRoute />}>
                    <Route
                      path="/my-profile"
                      element={<ProfileAppliedSavedJobs />}
                    >
                      <Route
                        path="/my-profile/applied-jobs"
                        element={<AppliedJobsPage />}
                      />
                      <Route
                        path="/my-profile/saved-jobs"
                        element={<SavedJobsPage />}
                      />
                    </Route>
                    <Route
                      path="/candidates-account"
                      element={<SetUpAccountSettings />}
                    >
                      <Route
                        path="/candidates-account/account"
                        element={<AccountSettingsPage />}
                      />
                      <Route
                        path="/candidates-account/resume"
                        element={<ResumeSettingPage />}
                      />
                    </Route>
                  </Route>

                  <Route path="/employers" element={<EmployersPage />} />
                </Route>
                <Route element={<PrivateEmployerRoute />}>
                  <Route
                    path="/employer-resetpassword/:emailId"
                    element={<EmployerResetPasswordPage />}
                  />
                  <Route path="/dashboard" element={<DashBoardPage />} />
                  <Route
                    path="/employer-profile"
                    element={<SetUpCompanyProfile />}
                  >
                    <Route
                      path="/employer-profile/company-info"
                      element={<CompanyProfileInfo />}
                    />
                    <Route
                      path="/employer-profile/transaction-history"
                      element={<TransactionHistory />}
                    />
                  </Route>
                  <Route path="/saved-jobs" element={<MyDeskSavedJobsPage />} />
                  <Route path="/applied-jobs" element={<AppliedJobsPage />} />
                  <Route path="/company-info" element={<SetUpAccount />}>
                    <Route
                      path="/company-info/"
                      element={<CompanyInfoPage />}
                    />
                    <Route
                      path="/company-info/job-info"
                      element={<JobInfoPage />}
                    />
                    <Route
                      path="/company-info/job-description"
                      element={<JobDescriptionEmployerPage />}
                    />
                    <Route
                      path="/company-info/prices"
                      element={<PricesPage />}
                    />
                    <Route
                      path="/company-info/questionnaire"
                      element={<QuestionnairePage />}
                    />
                    <Route
                      path="/company-info/sponsoring"
                      element={<SponsoringPage />}
                    />
                    <Route
                      path="/company-info/preview"
                      element={<PreviewPage />}
                    />
                    <Route
                      path="/company-info/preview-details"
                      element={<PreviewDetailsPage />}
                    />
                  </Route>
                  <Route path="/my-job" element={<MyJobPage />} />
                  <Route
                    path="/my-job-applicants"
                    element={<MyJobApplicantsPage />}
                  />
                  <Route
                    path="/my-job-applicants-details"
                    element={
                      <MyJobApplicantsDetailsPage applicant={undefined} />
                    }
                  />
                </Route>
              </Routes>
            </div>
          </div>
        </RoleTypeContext.Provider>
      </UserIdContext.Provider>
    </AuthContext.Provider>
  );
}

export default AppWrapper;
