import {JobsListActionTypes} from "./jobs-list-enum";

export const initialJobsListState = {
    status: false,
    loading: false,
    jobsListing: [],
};

export const JobsListReducer = (state = initialJobsListState, action) => {
    switch (action.type) {
        case JobsListActionTypes.JOBLISTREQUEST:
            return {...state, loading: action.loading};
        case JobsListActionTypes.JOBLISTSSUCCESS:
            return {
                ...state,
                status: action.status,
                jobsListing: action.jobsListing,
            };
        case JobsListActionTypes.JOBUPDATE:
            return {
                ...state,
                jobsListing: action.jobsListing,
            };
        case JobsListActionTypes.JOBLISTERROR:
            return {
                ...state,
                status: action.status,
                jobsListing: [],
            };
        default:
            return state;
    }
};
