import { FilterMenuActionTypes } from "./filter-menu-enum";

export const initialFilterMenuState = {
  status: false,
  loading: false,
  filterMenu: [],
};

export const FilterMenuReducer = (state = initialFilterMenuState, action) => {
  switch (action.type) {
    case FilterMenuActionTypes.FILTERMENUREQUEST:
      return { ...state, loading: action.loading };
    case FilterMenuActionTypes.FILTERMENUSUCCESS:
      return {
        ...state,
        status: action.status,
        filterMenu: action.filterMenu,
      };
    case FilterMenuActionTypes.FILTERMENUERROR:
      return {
        ...state,
        status: action.status,
        filterMenu: [],
      };
    default:
      return state;
  }
};
