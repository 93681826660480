import {call, put, takeLatest} from "redux-saga/effects";
import {GetApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import {JobsListActionTypes} from "./jobs-list-enum";

export function jobListApi(params) {
    return {
        type: JobsListActionTypes.JOBLISTREQUESTED,
        params: params,
    };
}

async function jobsListService(action) {
    return GetApiCall(APIConstants.JOBLIST_URL + action.params).then((data) => {
        return data;
    });
}

export function* doJobList(params) {
    yield put({type: JobsListActionTypes.JOBLISTREQUEST, loading: true});
    const jobsListResponse = yield call(jobsListService, params);
    if (jobsListResponse) {
        yield put({
            type: JobsListActionTypes.JOBLISTSSUCCESS,
            status: true,
            jobsListing: jobsListResponse,
        });
    } else {
        yield put({
            type: JobsListActionTypes.JOBLISTERROR,
            status: false,
        });
    }
    yield put({
        type: JobsListActionTypes.JOBLISTREQUEST,
        loading: false,
    });
}

export function* jobListSaga() {
    yield takeLatest(JobsListActionTypes.JOBLISTREQUESTED, doJobList);
}
