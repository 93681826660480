import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

const Pagination = ({perPage, total}) => {
    const [pages, setPages] = useState(0);
    const [pageSelected, setPageSelected] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setPages(Math.ceil(total / perPage));
    }, [perPage]);

    const pageHandler = (page) => {
        if (searchParams.has("page")) {
            searchParams.delete("page");
        }
        searchParams.append("page", page);
        setSearchParams(searchParams);
        setPageSelected(page);
    };

    const leftPaginationHandler = () => {
        if (pageSelected > 1) {
            const decreasePage = pageSelected - 1;
            setPageSelected(decreasePage);
            if (searchParams.has("page")) {
                searchParams.delete("page");
            }
            searchParams.append("page", decreasePage.toString());
            setSearchParams(searchParams);
        }
    };

    const rightPaginationHandler = () => {
        if (pageSelected < pages) {
            const increasePage = pageSelected + 1;
            setPageSelected(increasePage);
            if (searchParams.has("page")) {
                searchParams.delete("page");
            }
            searchParams.append("page", increasePage.toString());
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        if (searchParams.has("page")) {
            setPageSelected(searchParams.get("page"))
        }
    }, [searchParams && pages]);

    return (
        <div className="pagintn">
            <ul>
                {(pages !== null && pages > 1 && pageSelected !== 1) && (
                    <li className="arrlft" onClick={leftPaginationHandler}>
                        <a href="javascript:;">
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                        </a>
                    </li>
                )}
                {(pages > 0) &&
                    new Array(pages).fill().map((page, index) => {
                        return (
                            <li
                                key={index}
                                className={`${pageSelected === index + 1 && "active"}`}
                                onClick={() => pageHandler(index + 1)}
                            >
                                <a href="javascript:;">{index + 1}</a>
                            </li>
                        );
                    })}
                {(pages > 1 && pageSelected < pages) && (
                    <li className="arrrgt" onClick={rightPaginationHandler}>
                        <a href="javascript:;">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Pagination;
