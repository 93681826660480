import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SetupAccountMenu from "../../../components/setup-account-menu";
import {
  SetupAccountMenuList,
  SetupAccountMenuRoutes,
} from "../../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import { CommonActionTypes } from "../../../redux/common/common-enum";

const SetUpAccount = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [pageName, setPageName] = React.useState(0);
  const setupAccountState = useSelector((state) => state.SetupAccountState);

  useEffect(() => {
    dispatch({
      type: CommonActionTypes.SETUPACCOUNTMENULIST,
      setupMenuData: SetupAccountMenuList,
    });
  }, []);

  const nextHandler = (e) => {
    e.preventDefault();
    setCount((prevState) => prevState + 1);
    setPageName(location.pathname);
  };

  const backHandler = (e) => {
    e.preventDefault();
    if (setupAccountState.setupMenuSelected === 4) {
      navigate("/company-info/");
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className="outer-content">
        <div className="inner-content-left">
          <SetupAccountMenu
            menuSelected={setupAccountState.setupMenuSelected}
          />
        </div>
        <div className="inner-content-right">
          <Outlet context={[count, pageName, setCount]} />
        </div>
        <div className="outer-footer">
          <div className="container-cstm">
            <div className="footer-btn-section">
              {location.pathname !== SetupAccountMenuRoutes.CompanyInfo && (
                <button className="bck-btn" onClick={(e) => backHandler(e)}>
                  Back
                </button>
              )}
              <button className="nxt-btn" onClick={(e) => nextHandler(e)}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetUpAccount;
