import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";
import Loader from "../../components/loader";
import Footer from "../../components/footer";
import React from "react";

const Terms = () => {
  const [loading, setLoading] = useState(false);
  const [termsList, setTermsList] = useState([]);

  const TermsPage = () => {
    setLoading(true);
    GetApiCall(APIConstants.CMS_TERMS_URL).then((data) => {
      setLoading(false);
      setTermsList(data);
    });
  };

  useEffect(() => {
    TermsPage();
  }, []);

  const navigate = useNavigate();

  const CmsHandler = (cmsLink) => {
    navigate(cmsLink);
  };

  return (
    <div class="grey-bg p-cms">
      {loading && <Loader loading={loading} />}

      <div class="candidate-outer ">
        <div class="container-cstm">
          <div class="content-inner">
            <div class="cmp-desg-apply-section">
              <div class="job-desc">
                {termsList.data?.length > 0 &&
                  termsList.data?.map((termsData) => {
                    return (
                      <div class="about-us">
                        <h1>{termsData.title}</h1>
                        <div class="">
                          <ul>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: termsData.body,
                              }}
                            ></p>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default Terms;
