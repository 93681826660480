import {CompanySizesActionTypes} from "./companysizes-enum";


export const initialCompanysizesState = {
  status: false,
  loading: false,
  companysizes: [],
};

export const CompanySizesReducer = (state = initialCompanysizesState, action) => {
  switch (action.type) {
    case CompanySizesActionTypes.COMPANYSIZESREQUEST:
      return { ...state, loading: action.loading };
    case CompanySizesActionTypes.COMPANYSIZESSUCCESS:
      return {
        ...state,
        status: action.status,
        companysizes: action.companysizes,
      };
    case CompanySizesActionTypes.COMPANYSIZESERROR:
      return {
        ...state,
        status: action.status,
        companysizes: [],
      };
    default:
      return state;
  }
};
