import {AuthenicationActionTypes} from "./authenication-enum";

export const initialAuthenticationState = {
    loading: false,
    isLoggedIn: null,
    registerStatus: null,
    registerErrorMessage: "",
    loginStatus: null,
    loginErrorMessage: "",
    isVerified: null,
    isEmailVerified: null,
    verifiedEmailErrorMessage: "",
    isForgotPasswordStatus: null,
    forgotPasswordErrorMessage: "",
    isResetPasswordStatus: null,
    resetPasswordErrorMessage: "",
    isChangePasswordStatus: null,
    changePasswordErrorMessage: "",
};

export const AuthenticationReducer = (
    state = initialAuthenticationState,
    action
) => {
    switch (action.type) {
        case AuthenicationActionTypes.REGISTERREQUEST:
            return {...state, loading: action.loading};
        case AuthenicationActionTypes.REGISTERSUCCESS:
            return {
                ...state,
                registerStatus: action.registerStatus,
                registerErrorMessage: "",
            };
        case AuthenicationActionTypes.REGISTERERROR:
            return {
                ...state,
                registerStatus: false,
                registerErrorMessage: action.registerErrorMessage,
            };
        case AuthenicationActionTypes.LOGINREQUEST:
            return {...state, loading: action.loading};
        case AuthenicationActionTypes.LOGINSUCCESS:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                loginStatus: action.loginStatus,
                loginData: action.loginData,
                loginErrorMessage: "",
            };
        case AuthenicationActionTypes.LOGINERROR:
            return {
                ...state,
                isLoggedIn: false,
                loginStatus: false,
                loginData: null,
                isVerified: action.isVerified,
                loginErrorMessage: action.loginErrorMessage,
            };

        case AuthenicationActionTypes.VERIFYEMAILOTPSUCCESS:
            return {
                ...state,
                isEmailVerified: action.isEmailVerified,
                verifiedEmailErrorMessage: "",
            };
        case AuthenicationActionTypes.VERIFYEMAILOTPERROR:
            return {
                ...state,
                isEmailVerified: false,
                verifiedEmailErrorMessage: action.verifiedEmailErrorMessage,
            };
        case AuthenicationActionTypes.FORGOTPASSWORDSUCCESS:
            return {
                ...state,
                isForgotPasswordStatus: action.isForgotPasswordStatus,
            };
        case AuthenicationActionTypes.FORGOTPASSWORDERROR:
            return {
                ...state,
                isForgotPasswordStatus: action.isForgotPasswordStatus,
                forgotPasswordErrorMessage: action.forgotPasswordErrorMessage,
            };
        case AuthenicationActionTypes.RESETPASSWORDSUCCESS:
            return {
                ...state,
                isResetPasswordStatus: action.isResetPasswordStatus,
            };
        case AuthenicationActionTypes.RESETPASSWORDERROR:
            return {
                ...state,
                isResetPasswordStatus: false,
                resetPasswordErrorMessage: action.resetPasswordErrorMessage,
            };
        case AuthenicationActionTypes.CHANGEPASSWORDSUCCESS:
            return {
                ...state,
                isChangePasswordStatus: action.isChangePasswordStatus,
            };
        case AuthenicationActionTypes.CHANGEPASSWORDERROR:
            return {
                ...state,
                isChangePasswordStatus: false,
                changePasswordErrorMessage: action.changePasswordErrorMessage,
            };
        case AuthenicationActionTypes.CLEARVALUES:
            return initialAuthenticationState;
        default:
            return state;
    }
};
