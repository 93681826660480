import React from "react";

const NoResult = ({ title, subtitle }) => {
  return (
    <div className="jbs-no-rslt">
      <h6>{title}</h6>
      {subtitle !== "" && <p>{subtitle}</p>}
    </div>
  );
};

export default NoResult;
