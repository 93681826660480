import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";
import Loader from "../../components/loader";
import Footer from "../../components/footer";
import React from "react";

const AboutUs = () => {
  const [loading, setLoading] = useState(false);
  const [aboutList, setAboutList] = useState([]);

  const AboutUsPage = () => {
    setLoading(true);
    GetApiCall(APIConstants.CMS_ABOUT_URL).then((data) => {
      setLoading(false);
      setAboutList(data);
    });
  };

  useEffect(() => {
    AboutUsPage();
  }, []);

  const navigate = useNavigate();

  const CmsHandler = (cmsLink) => {
    navigate(cmsLink);
  };

  return (
    <div class="grey-bg p-cms">
      {loading && <Loader loading={loading} />}
      <div class="candidate-outer ">
        <div class="container-cstm">
          <div class="content-inner">
            <div class="cmp-desg-apply-section">
              <div class="job-desc">
                {aboutList.data?.length > 0 &&
                  aboutList.data?.map((about) => {
                    return (
                      <div class="about-us">
                        <h1>{about.title}</h1>
                        <div class="">
                          <p
                            dangerouslySetInnerHTML={{ __html: about.body }}
                          ></p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default AboutUs;
