import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";
import Loader from "../../components/loader";
import Footer from "../../components/footer";
import React from "react";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [privacyPolicyList, setPrivacyPolicyList] = useState([]);

  const PrivacyPolicyPage = () => {
    setLoading(true);
    GetApiCall(APIConstants.CMS_PRIVACY_POLICY_URL).then((data) => {
      setLoading(false);
      setPrivacyPolicyList(data);
    });
  };

  useEffect(() => {
    PrivacyPolicyPage();
  }, []);

  const navigate = useNavigate();

  const CmsHandler = (cmsLink) => {
    navigate(cmsLink);
  };

  return (
    <div class="grey-bg p-cms">
      {loading && <Loader loading={loading} />}

      <div class="candidate-outer ">
        <div class="container-cstm">
          <div class="content-inner">
            <div class="cmp-desg-apply-section">
              <div class="job-desc">
                {privacyPolicyList.data?.length > 0 &&
                  privacyPolicyList.data?.map((privacy) => {
                    return (
                      <div class="about-us">
                        <h1>{privacy.title}</h1>
                        <div class="">
                          <ol>
                            <p
                              dangerouslySetInnerHTML={{ __html: privacy.body }}
                            ></p>
                          </ol>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default PrivacyPolicy;
