import { call, put, takeLatest } from "redux-saga/effects";
import { GetApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import { FilterMenuActionTypes } from "./filter-menu-enum";

export function filterMenuApi() {
  return {
    type: FilterMenuActionTypes.FILTERMENUREQUESTED,
  };
}

async function filterMenuService() {
  return GetApiCall(APIConstants.FILTERSIDEMENU_URL).then((data) => {
    return data;
  });
}

export function* doFilterMenu() {
  yield put({ type: FilterMenuActionTypes.FILTERMENUREQUEST, loading: true });
  const filterMenuResponse = yield call(filterMenuService);
  if (filterMenuResponse) {
    yield put({
      type: FilterMenuActionTypes.FILTERMENUSUCCESS,
      status: true,
      filterMenu: filterMenuResponse.data,
    });
  } else {
    yield put({
      type: FilterMenuActionTypes.FILTERMENUERROR,
      status: false,
    });
  }
  yield put({
    type: FilterMenuActionTypes.FILTERMENUREQUEST,
    loading: false,
  });
}

export function* filterSideMenuSaga() {
  yield takeLatest(FilterMenuActionTypes.FILTERMENUREQUESTED, doFilterMenu);
}
