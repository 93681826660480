import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {industryApi} from "../../../redux/employer/industry/industry-saga";
import {companySizesApi} from "../../../redux/employer/company-sizes/companysizes-saga";
import {CommonActionTypes} from "../../../redux/common/common-enum";
import {useNavigate, useOutletContext} from "react-router-dom";
import {SessionStorage, SetupAccountMenuRoutes} from "../../../utils/enum";
import {toast} from "react-toastify";
import {companyCreateApi} from "../../../redux/employer/company-create/companycreate-saga";
import {CompanyCreateActionTypes} from "../../../redux/employer/company-create/companycreate-enum";
import {GetApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";

const CompanyInfoPage = () => {
    const dispatch = useDispatch();
    const industryState = useSelector((state) => state.IndustryState);
    const companySizesState = useSelector((state) => state.CompanySizesState);
    const companyCreationState = useSelector(
        (state) => state.CompanyCreationState
    );
    const setupAccountState = useSelector((state) => state.SetupAccountState);
    const navigate = useNavigate();
    const [count, pageName, setCount] = useOutletContext();
    const [selectedSize, setSelectedSize] = useState(0);
    const createNewCompanyState = useSelector(
        (state) => state.CreateNewCompanyState
    );

    const checkPaymentStatus = () => {
        GetApiCall(APIConstants.PAYMENTSTATUS).then((response) => {
            if (response.data.subscription_plan === null) {
                setCount(0);
                navigate("/company-info/prices");
                dispatch({
                    type: CompanyCreateActionTypes.COMPANYCREATIONCLEAR,
                });
            } else {
                dispatch({
                    type: CompanyCreateActionTypes.COMPANYCREATIONCLEAR,
                });
                dispatch({
                    type: CommonActionTypes.SETUPACCOUNTMENU,
                    setupMenuSelected: 4,
                });
                const menuIndex = setupAccountState.setupMenuData.findIndex(
                    (mI) => mI.id === 3
                );
                let accountMenuList = [...setupAccountState.setupMenuData];
                accountMenuList[menuIndex].isFilled = true;
                dispatch({
                    type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
                    setupMenuData: accountMenuList,
                });
                setCount(0);
                navigate("/company-info/job-info");
            }
        });
    }

    useEffect(() => {
        const isProfileCompleted = localStorage.getItem(SessionStorage.ISPROFILECOMPLETED);
        if (isProfileCompleted) {
            navigate("/dashboard");
        } else {
            dispatch(industryApi());
            dispatch(companySizesApi());
            if (companyCreationState.creationCompany?.company_size_id !== undefined) {
                setSelectedSize(companyCreationState.creationCompany.company_size_id);
            }
        }

    }, []);

    useEffect(() => {
        if (createNewCompanyState.status !== null && createNewCompanyState.status) {
            if (createNewCompanyState.message === "") {
                toast('Company Profile Created Successfully');
            } else {
                toast(createNewCompanyState.message);
            }
            checkPaymentStatus();
        } else if (
            createNewCompanyState.status !== null &&
            !createNewCompanyState.status
        ) {
            toast("The company name has already been taken.");
        }
    }, [createNewCompanyState]);

    useEffect(() => {
        if (count > 0 && pageName === SetupAccountMenuRoutes.CompanyInfo) {
            if (companyCreationState.creationCompany === null) {
                toast("Please enter company info");
            } else if (
                companyCreationState.creationCompany.company_logo === undefined
            ) {
                toast("Please enter company logo");
            } else if (
                companyCreationState.creationCompany.company_name === undefined ||
                companyCreationState.creationCompany.company_name === ""
            ) {
                toast("Please enter company name");
            } else if (
                companyCreationState.creationCompany.address === undefined ||
                companyCreationState.creationCompany.address === ""
            ) {
                toast("Please enter address");
            } else if (
                companyCreationState.creationCompany.industry_id === undefined
            ) {
                toast("Please enter industry");
            } else if (
                companyCreationState.creationCompany.website_address === undefined ||
                companyCreationState.creationCompany.website_address === ""
            ) {
                toast("Please enter website");
            } else if (selectedSize === 0) {
                toast("Please enter company size");
            } else {
                setCount(0);
                dispatch({
                    type: CommonActionTypes.SETUPACCOUNTMENU,
                    setupMenuSelected: 3,
                });
                const menuIndex = setupAccountState.setupMenuData.findIndex(
                    (mI) => mI.id === 2
                );
                let accountMenuList = [...setupAccountState.setupMenuData];
                accountMenuList[menuIndex].isFilled = true;
                dispatch({
                    type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
                    setupMenuData: accountMenuList,
                });
                dispatch(companyCreateApi(companyCreationState.creationCompany));
            }
        }
    }, [count]);

    const companySizeClick = (e, size) => {
        e.preventDefault();
        const companySize = {
            ...companyCreationState.creationCompany,
            company_size_id: size.id,
        };
        setSelectedSize(size.id);
        dispatch({
            type: CommonActionTypes.COMPANYCREATE,
            creationCompany: companySize,
        });
    };

    const handleChange = (e) => {
        const companyCreation = {
            ...companyCreationState.creationCompany,
            [e.target.name]: e.target.value,
        };
        dispatch({
            type: CommonActionTypes.COMPANYCREATE,
            creationCompany: companyCreation,
        });
    };

    const handleCompanyLogo = (e) => {
        const companyCreation = {
            ...companyCreationState.creationCompany,
            company_logo: e.target.files[0],
        };
        dispatch({
            type: CommonActionTypes.COMPANYCREATE,
            creationCompany: companyCreation,
        });
    };

    return (
        <>
            <div>
                <h2>Company Info</h2>
                <p>
                    Welcome to Jobbaus for employers. Start your journey to find the right
                    person for your position by adding information about your company.
                </p>
                <div className="upload-img-wrap">
                    <input type="file" accept="image/*" onChange={handleCompanyLogo}/>
                    {(companyCreationState.creationCompany === null ||
                        companyCreationState.creationCompany.company_logo ===
                        undefined) && (
                        <div className="upload-photo">
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            Upload your company logo
                        </div>
                    )}
                    {companyCreationState.creationCompany?.company_logo !== undefined && (
                        <div className="upload-photo">
                            <img
                                alt="not found"
                                width={"250px"}
                                height={"250px"}
                                src={URL.createObjectURL(
                                    companyCreationState.creationCompany.company_logo
                                )}
                            />
                        </div>
                    )}
                </div>
                <form>
                    <div className="frmcontainer-comp">
                        <input
                            type="text"
                            name="company_name"
                            onChange={handleChange}
                            placeholder="Company Name*"
                            value={companyCreationState.creationCompany?.company_name}
                        />{" "}
                        <input
                            type="text"
                            name="address"
                            onChange={handleChange}
                            placeholder="Location*"
                            value={companyCreationState.creationCompany?.address}
                        />{" "}
                    </div>
                    <div className="frmcontainer-comp">
                        <select
                            name="industry_id"
                            onChange={handleChange}
                            aria-invalid="false"
                            value={companyCreationState.creationCompany?.industry_id}
                        >
                            <option value="0">Select Industry</option>
                            {industryState.industries.map((industry, index) => {
                                return (
                                    <option key={index} value={industry.id}>
                                        {industry.name}
                                    </option>
                                );
                            })}
                        </select>{" "}
                        <input
                            type="text"
                            name="website_address"
                            onChange={handleChange}
                            value={companyCreationState.creationCompany?.website_address}
                            placeholder="Website URL"
                        />{" "}
                    </div>
                    <label>Company Size*</label>
                    <div className="comp-size">
                        {companySizesState.companysizes.map((sizes, index) => {
                            return (
                                <button
                                    key={index}
                                    class={`${selectedSize === sizes.id && "active"}`}
                                    onClick={(e) => companySizeClick(e, sizes)}
                                >
                                    {sizes.company_size}
                                </button>
                            );
                        })}
                    </div>
                </form>
            </div>
        </>
    );
};
export default CompanyInfoPage;
