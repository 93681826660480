import React from "react";
import {useSelector} from "react-redux";

const SetupAccountMenu = (menuSelected) => {
    const setupAccountState = useSelector((state) => state.SetupAccountState);

    return (
        <>
            {setupAccountState.setupMenuData.map((menu, index) => {
                let setupMenuAccountStyle = menuSelected.menuSelected === menu.id ? 'lbl-chkbx-actv' : menu.isFilled ? 'lbl-chkbx-nonactive' : 'lbl-chkbx-nonchecked';
                return (menu.isShow && (setupMenuAccountStyle !== undefined && menu.title ?
                    (<h3 key={index}>{menu.name}</h3>) :
                    (<label key={index}
                            className={`${setupMenuAccountStyle}`}>{menu.name}</label>)))
            })}
        </>
    )
}

export default SetupAccountMenu;
