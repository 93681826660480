/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import Loader from "../../../components/loader";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { SetupAccountMenuRoutes } from "../../../utils/enum";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CommonActionTypes } from "../../../redux/common/common-enum";
import { useDispatch, useSelector } from "react-redux";

const JobDescriptionEmployerPage = () => {
  const dispatch = useDispatch();
  const setupAccountState = useSelector((state) => state.SetupAccountState);
  const updateJobDetailsState = useSelector(
    (state) => state.CreateNewCompanyState.updateJobDetails
  );
  const [jobDescPayload, setJobDescPayload] = useState({
    description: updateJobDetailsState?.description,
    duties_and_responsibilty: updateJobDetailsState?.duties_and_responsibilty,
    requirements: updateJobDetailsState?.requirements,
    perks: updateJobDetailsState?.perks,
    company_footer_info: updateJobDetailsState?.company_footer_info,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [count, pageName, setCount] = useOutletContext();
  const { id } = state;

  const handleJobDescription = (e, keyValue) => {
    const jobDescrCreation = {
      ...jobDescPayload,
      [keyValue]: e,
    };
    setJobDescPayload(jobDescrCreation);
  };

  useEffect(() => {
    if (count > 0 && pageName === SetupAccountMenuRoutes.JobDescription) {
      createJobDescription();
    }
  }, [count]);

  const createJobDescription = () => {
    setLoading(true);
    PostApiCall(
      APIConstants.JOBCREATEDESCRIPTION_URL + id,
      jobDescPayload
    ).then((data) => {
      if (data.status) {
        setCount(0);
        toast("Job description created successfully");
        dispatch({
          type: CommonActionTypes.SETUPACCOUNTMENU,
          setupMenuSelected: 7,
        });
        const menuIndex = setupAccountState.setupMenuData.findIndex(
          (mI) => mI.id === 6
        );
        let accountMenuList = [...setupAccountState.setupMenuData];
        accountMenuList[menuIndex].isFilled = true;
        dispatch({
          type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
          setupMenuData: accountMenuList,
        });
        navigate("/company-info/questionnaire", { state: { id: id } });
      } else {
        toast("Please enter all details");
      }
      setLoading(false);
    });
  };

  return (
    <>
      {loading && <Loader loading={loading} />}

      <div className="inner-content-right">
        <h2>Job Description</h2>
        <p>Tell us about this position</p>
        <form action="">
          <div className="stp-jb-desc">
            <label>Company Info</label>
            {/* <textarea
              name="description"
              onChange={handleJobDescription}
              cols="30"
              rows="4"
            ></textarea> */}
            <ReactQuill
              style={{ height: 100 }}
              theme="snow"
              value={jobDescPayload?.description}
              onChange={(e) => handleJobDescription(e, "description")}
            />
          </div>
          <div className="stp-jb-desc">
            <label>Duties and Responsibilities</label>
            <ReactQuill
              style={{ height: 100 }}
              theme="snow"
              value={jobDescPayload?.duties_and_responsibilty}
              onChange={(e) =>
                handleJobDescription(e, "duties_and_responsibilty")
              }
            />
          </div>
          <div className="stp-jb-desc">
            <label>Requirements</label>
            <ReactQuill
              style={{ height: 100 }}
              value={jobDescPayload?.requirements}
              theme="snow"
              onChange={(e) => handleJobDescription(e, "requirements")}
            />
          </div>
          {/* <div className="stp-jb-desc">
                        <label>Compensation</label>
                        <ReactQuill
                            style={{height: 100}}
                            theme="snow"
                            onChange={(e) => handleJobDescription(e, "compensation")}
                        />
                    </div> */}
          <div className="stp-jb-desc">
            <label>Perks</label>
            <ReactQuill
              style={{ height: 100 }}
              theme="snow"
              value={jobDescPayload?.perks}
              onChange={(e) => handleJobDescription(e, "perks")}
            />
          </div>
          <div className="stp-jb-desc">
            <label>Company Footer Info</label>
            <ReactQuill
              style={{ height: 100 }}
              theme="snow"
              value={jobDescPayload?.company_footer_info}
              onChange={(e) => handleJobDescription(e, "company_footer_info")}
            />
          </div>
        </form>
      </div>
    </>
  );
};
export default JobDescriptionEmployerPage;
