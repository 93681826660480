import { APIConstants } from "../../../utils/config";
import { GetApiCall } from "../../../utils/axios";
import Loader from "../../../components/loader";
import { useEffect, useState } from "react";
import moment from "moment";

const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [transactionHistoryList, setTransactionHistoryList] = useState([]);

  const TransactionHistory = () => {
    setLoading(true);
    GetApiCall(APIConstants.TRANSACTION_HISTORY_URL).then((data) => {
      setLoading(false);
      setTransactionHistoryList(data);
    });
  };

  useEffect(() => {
    TransactionHistory();
  }, []);

  return (
    <div>
      {loading && <Loader loading={loading} />}
      <div id="account-tab2" class="tble-prt">
        <div className="table-responsive table-card">
          <table className="table table-hover table-centered align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th>Plan</th>
                <th>Start Date</th>
                <th>Expire Date</th>
                <th>Status</th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            {transactionHistoryList.data?.length > 0 &&
              transactionHistoryList.data?.map((TransactionData) => {
                return (
                  <tbody>
                    <tr>
                      <td> {TransactionData.membership_plan.package_name} </td>

                      <td>
                        {moment(TransactionData.start_date).format(
                          "MM-DD-YYYY"
                        )}
                      </td>
                      <td>
                        {" "}
                        {moment(TransactionData.end_date).format(
                          "MM-DD-YYYY"
                        )}{" "}
                      </td>
                      <td>
                        <span className="green">{TransactionData.status}</span>{" "}
                      </td>
                      <td> ... </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
};
export default TransactionHistory;
