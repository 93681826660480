import {axiosPrivate} from "./axios-instance";

export const PostApiCall = (url, body) => {
    return axiosPrivate
        .post(url, body)
        .then((response) => {
            return response.data;
        })
        .catch((reason) => {
            return reason?.response?.data;
        });
};

export const GetApiCall = (url) => {
    return axiosPrivate
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((reason) => {
            console.log(reason);
            return reason?.response?.data;
        });
};

export const UploadFormApiCall = (url, body) => {
        return axiosPrivate
            .post(url, body, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .catch((reason) => {
                return reason?.response?.data;
            });
    }
;


