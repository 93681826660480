import {applyMiddleware, compose} from 'redux';
import {legacy_createStore as createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-redux';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(rootSaga);

export default store;
