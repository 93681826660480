import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Footer from "../../../components/footer";

const SetUpAccountSettings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const onNavigationHandler = (routePath) => {
        navigate(routePath);
    };
    const [accountSelected, setAccountSelected] = useState("account");

    useEffect(() => {
        if (location.pathname.includes("/candidates-account/account")) {
            setAccountSelected('account');
        } else if (location.pathname.includes("/candidates-account/resume")) {
            setAccountSelected("resume");
        }
    }, []);

    return (
        <div className="grey-bg py-5">
            <div className="candidate-outer ">
                <div className="container-cstm">
                    <div className="profile-outer my-0">
                        <div className="tab">
                            <p
                                className={`tablinks ${
                                    accountSelected === "account" && "active"
                                }`}
                                onClick={() => {
                                    setAccountSelected("account");
                                    onNavigationHandler("/candidates-account/account");
                                }}
                            >
                                <span>Account Settings</span>
                            </p>
                            <p
                                className={`tablinks ${
                                    accountSelected === "resume" && "active"
                                }`}
                                onClick={() => {
                                    setAccountSelected("resume");
                                    onNavigationHandler("/candidates-account/resume");
                                }}
                            >
                                <span>Resume Settings</span>
                            </p>
                        </div>


                        <div>
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default SetUpAccountSettings;
