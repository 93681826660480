/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {jobListApi} from "../../../redux/candidates/jobs-listing/jobs-list-saga";
import FilterMenu from "../../../components/filterMenu";
import Loader from "../../../components/loader";
import {createSearchParams, useLocation, useNavigate, useSearchParams,} from "react-router-dom";
import {PostApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import {toast} from "react-toastify";
import Pagination from "../../../components/pagination";
import {RoleTypeContext, UserIdContext} from "../../../contexts/auth-context";
import NoResult from "../../../components/no-result";
import moment from "moment";
import {JobsListActionTypes} from "../../../redux/candidates/jobs-listing/jobs-list-enum";
import Footer from "../../../components/footer";

const JobPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const jobsListState = useSelector((state) => state.jobsListState);
    const userIdContext = useContext(UserIdContext);
    const roleTypeContext = useContext(RoleTypeContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [JobSearchParams, setJobSearchParams] = useState(null);

    useEffect(() => {
        dispatch(jobListApi(location.search));
    }, [searchParams]);

    useEffect(() => {
        if (location.search !== "") {
            dispatch(jobListApi(location.search));
        } else {
            dispatch(jobListApi(""));
        }
    }, []);

    const applyHandler = (e, job) => {
        e.preventDefault();
        navigate({
            pathname: "/job-description",
            search: createSearchParams({
                id: job.id,
            }).toString(),
        });
    };

    const ApplyJobs = (jobApplyId) => {
        PostApiCall(APIConstants.APPLYJOB_URL + jobApplyId).then((data) => {
            let updateJobListing = jobsListState.jobsListing;
            const singleJob = updateJobListing.data.findIndex((joblistingId) => joblistingId.id === jobApplyId);
            if (singleJob !== -1) {
                updateJobListing.data[singleJob].isApplied = updateJobListing.data[singleJob].isApplied === 1 ? 0 : 1;
                dispatch({
                    type: JobsListActionTypes.JOBUPDATE,
                    jobsListing: updateJobListing,
                });
            }
            toast(data.message);
        });
    };
    const applyJobshandle = (e, jobApply) => {
        e.preventDefault();
        ApplyJobs(jobApply.id);
    };

    const SavedJobs = (jobId) => {
        PostApiCall(APIConstants.SAVEJOB_URL + jobId).then((data) => {
            if (data.status) {
                let updateJobListing = jobsListState.jobsListing;
                const singleJob = updateJobListing.data.findIndex((joblistingId) => joblistingId.id === jobId);
                if (singleJob !== -1) {
                    updateJobListing.data[singleJob].isSaved = updateJobListing.data[singleJob].isSaved === 1 ? 0 : 1;
                    dispatch({
                        type: JobsListActionTypes.JOBUPDATE,
                        jobsListing: updateJobListing,
                    });
                }
                toast(data.message);
            } else {
                toast("You have already saved this job");
            }
        });
    };
    const savedJobshandle = (e, job) => {
        e.preventDefault();
        SavedJobs(job.id);
    };

    const handleChange = (e) => {
        const jobParams = {
            ...JobSearchParams,
            [e.target.name]: e.target.value,
        };
        setJobSearchParams(jobParams);
    };

    const nextHandler = (e) => {
        e.preventDefault();
        if (JobSearchParams !== null) {
            if (
                JobSearchParams["title"] !== undefined &&
                JobSearchParams["location"] !== undefined
            ) {
                searchParams.delete("title");
                searchParams.delete("location");
                if (JobSearchParams.title !== "") {
                    searchParams.append("title", JobSearchParams.title);
                }
                if (JobSearchParams.location !== "") {
                    searchParams.append("location", JobSearchParams.location);
                }
                setSearchParams(searchParams);
            } else if (JobSearchParams["location"] !== undefined) {
                if (searchParams.has("location")) {
                    searchParams.delete("location");
                }
                if (JobSearchParams.location !== "") {
                    searchParams.append("location", JobSearchParams.location);
                }
                setSearchParams(searchParams);
            } else if (JobSearchParams["title"] !== undefined) {
                if (searchParams.has("title")) {
                    searchParams.delete("title");
                }
                searchParams.append("title", JobSearchParams.title);
                setSearchParams(searchParams);
            }
        }
    };

    return (
        <>
            {jobsListState.loading && <Loader loading={jobsListState.loading}/>}
            <div className="candidate-outer grey-bg">
                <div className="container-cstm">
                    <div className="job-outer mt-0 pt-5">
                        <FilterMenu/>
                        <div className="content-inner">
                            <div className="cntnt-left">
                                <form>
                                    <div className="frm-lft">
                                        <div className="txt-otr">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <input
                                                type="text"
                                                name="title"
                                                onChange={handleChange}
                                                placeholder="Job title, Company,…"
                                            />
                                        </div>
                                    </div>
                                    <div className="frm-rgt">
                                        <div className="txt-otr">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <input
                                                type="text"
                                                name="location"
                                                onChange={handleChange}
                                                placeholder="City, State,…"
                                            />
                                        </div>
                                    </div>
                                    <input
                                        type="submit"
                                        className="btn fa-input"
                                        value="Search   &#xf061; "
                                        onClick={(e) => nextHandler(e)}
                                    />
                                </form>
                            </div>
                            {jobsListState.jobsListing.data?.length === 0 && (
                                <NoResult title={"No Jobs Available"} subtitle={""}/>
                            )}
                            {jobsListState.jobsListing.data?.length > 0 &&
                                jobsListState.jobsListing.data.map((listing, index) => {
                                    return (
                                        <>
                                            <div className="cmp-desg-apply-section" key={index}>
                                                <div
                                                    className="cmp-desg"
                                                    onClick={(e) => applyHandler(e, listing)}
                                                >
                                                    <img src={listing.logo} alt={listing.company_name}/>
                                                    <div className="desg-sec">
                                                        <p>{listing.title}</p>
                                                        <p>
                                                            {/* <span>
                                 Slack . Ottawa, Ontario, Canada . Part time
                              </span> */}
                                                            <span>
                                {listing.company_name}.{listing.city} , {listing.state},{" "}
                                                                {listing.country} .{" "}
                                                                {listing.job_type}
                              </span>
                                                        </p>
                                                        <p>
                                                            {moment(listing.created_at).format("MM-DD-YYYY")}
                                                        </p>
                                                        {listing.remote === "Yes" && <p>Remote</p>}
                                                    </div>
                                                </div>
                                                {userIdContext !== null &&
                                                    roleTypeContext !== "Employer" && (
                                                        <div className="apply-section">
                                                            <p
                                                                className="bookmark"
                                                                onClick={(e) => savedJobshandle(e, listing)}
                                                            >
                                                                <a
                                                                    href="#"
                                                                    className={`${
                                                                        listing.isSaved === 1 && "active"
                                                                    }`}
                                                                >
                                                                    <i
                                                                        class="fa fa-bookmark-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    <i
                                                                        class="fa fa-bookmark"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </a>
                                                            </p>
                                                            {listing.isApplied === 0 && (
                                                                <p
                                                                    className="apply-btn"
                                                                    onClick={(e) => applyJobshandle(e, listing)}
                                                                >
                                                                    <a href="">
                                                                        {" "}
                                                                        Apply Now
                                                                        <i
                                                                            className="fa fa-arrow-right"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </a>
                                                                </p>
                                                            )}
                                                            {listing.isApplied === 1 && (
                                                                <p className="apply-btn applied">
                                                                    <a
                                                                        href=""
                                                                        onClick={(e) => e.preventDefault()}
                                                                    >
                                                                        {" "}
                                                                        Applied
                                                                    </a>
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                        </>
                                    );
                                })}
                            {jobsListState.jobsListing.data?.length > 0 &&
                                jobsListState.jobsListing.total > 10 && (
                                    <Pagination
                                        perPage={jobsListState.jobsListing.per_page}
                                        total={jobsListState.jobsListing.total}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
};
export default JobPage;
