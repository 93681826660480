/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../components/footer";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const CandidatesPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState(null);

  const handleChange = (e) => {
    const searchParam = {
      ...searchParams,
      [e.target.name]: e.target.value,
    };
    setSearchParams(searchParam);
  };

  const nextHandler = (e) => {
    e.preventDefault();
    if (searchParams !== null) {
      if (
        searchParams["title"] !== undefined &&
        searchParams["location"] !== undefined
      ) {
        navigate({
          pathname: "/jobs",
          search: `?title=${searchParams.title}&location=${searchParams.location}`,
        });
      } else if (searchParams["location"] !== undefined) {
        navigate({
          pathname: "/jobs",
          search: "?location=" + searchParams.location,
        });
      } else if (searchParams["title"] !== undefined) {
        navigate({
          pathname: "/jobs",
          search: "?title=" + searchParams.title,
        });
      }
    } else {
      toast("Please enter your desired job/job location");
    }
  };

  return (
    <>
      <div className="candidate-outer">
        <div className="container-cstm">
          <div className="content-section">
            <div className="cntnt-left">
              <h2>Your Future Career Starts Here.</h2>
              <form>
                <div className="frm-lft">
                  <label>Job Title/Company</label>
                  <div className="txt-otr">
                    <i className="fa fa-search" aria-hidden="true"></i>
                    <input
                      type="text"
                      name="title"
                      placeholder="Ex. HR Manager or Volunteer"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="frm-rgt">
                  <label>Location</label>
                  <div className="txt-otr">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <input
                      type="text"
                      placeholder="Ex. Toronto or Ontario"
                      name="location"
                      onChange={handleChange}
                    />
                  </div>
                  {/* <GooglePlacesAutocomplete apiKey="AIzaSyBTqxUSWMpqy4-g_2-vkGkZpq7qh2RoLyA" /> */}
                </div>
                <div onClick={(e) => nextHandler(e)}>
                  <input
                    type="submit"
                    className="btn fa-input"
                    value="Search   &#xf061; "
                  />
                </div>
              </form>
              {/*<ul className="f-catg">*/}
              {/*  <li>*/}
              {/*    Following Categories: <a href="#">Urgent Roles</a>,{" "}*/}
              {/*    <a href="#">STEM roles</a>,{" "}*/}
              {/*    <a href="#">Volunteer positions</a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            <div className="cntnt-right">
              <img
                src={require("../../assets/images/blackcreek-corporate.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default CandidatesPage;
