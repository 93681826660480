import { useEffect, useState } from "react";
import { APIConstants } from "../../../../utils/config";
import { GetApiCall, PostApiCall } from "../../../../utils/axios";
import Loader from "../../../../components/loader";
import React from "react";

const MyJobApplicantsDetailsPage = ({ applicant }) => {
  const [loading, setLoading] = useState(false);
  const [jobsApplicantsDetails, setJobsApplicantsDetails] = useState();
  // const [jobsApplicantsStatus, setJobsApplicantsStatus] = useState();

  const myJobsApplicantsDetails = (job_id) => {
    setLoading(true);
    GetApiCall(
      APIConstants.EMPLOYER_MY_JOBS_APPLICANTS_DETAILS_URL + job_id
    ).then((data) => {
      setLoading(false);
      setJobsApplicantsDetails(data.data);
    });
  };

  const myJobsApplicantsStatus = (job, status) => {
    setLoading(true);
    const jobApplicantBody = {
      job_candidate_id: job.id,
      job_status: status,
    };
    PostApiCall(
      APIConstants.EMPLOYER_MY_JOBS_APPLICANTS_STATUS_URL,
      jobApplicantBody
    ).then(() => {
      setLoading(false);
      window.location.reload();
    });
  };

  useEffect(() => {
    myJobsApplicantsDetails(applicant.id);
  }, [applicant]);

  const rejectHandler = () => {
    myJobsApplicantsStatus(applicant, "Rejected");
  };

  const acceptHandler = () => {
    myJobsApplicantsStatus(applicant, "Accepted");
  };

  return (
    <div className="myjob-app-details">
      {loading && <Loader loading={loading} />}
      <div className="myjob-app-details-desc">
        <img
          src={jobsApplicantsDetails?.user.profile_image}
          alt={jobsApplicantsDetails?.user.profile_image}
        />

        <div className="desc">
          <h3>{jobsApplicantsDetails?.user.name}</h3>
          <p>{jobsApplicantsDetails?.user.phone_no}</p>
          <p>{jobsApplicantsDetails?.user.email}</p>
        </div>
      </div>

      <button>
        <a
          href={jobsApplicantsDetails?.user?.candidate_profile?.cv}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-eye" aria-hidden="true"></i>{" "}
        </a>
        <i className="fa fa-envelope" aria-hidden="true"></i>
        <i className="fa fa-share-alt" aria-hidden="true"></i>
      </button>
      <h4>Skills</h4>

      <p className="skill">
        {jobsApplicantsDetails?.user?.candidate_skill?.length > 0 &&
          jobsApplicantsDetails?.user?.candidate_skill?.map((skills) => {
            return <span value={skills.id}>{skills.skill.name}</span>;
          })}
      </p>
      <h4>Work History</h4>
      {jobsApplicantsDetails?.work_history.map((workHistory) => {
        return (
          <>
            <div className="skill-desc">
              <h6>{workHistory.designation}</h6>
              <p>{workHistory.company_name}</p>
              <p>
                {workHistory.joining_date} -{" "}
                {workHistory.end_date === null
                  ? "Present"
                  : workHistory.end_date}
              </p>
            </div>
          </>
        );
      })}

      <div className="myjob-app-details-bts">
        <button className="yellow-bg" onClick={() => rejectHandler()}>
          Reject
        </button>
        <button className="green-bg" onClick={() => acceptHandler()}>
          Accept
        </button>
      </div>

      {jobsApplicantsDetails?.questionarie_answer.length > 0 && (
        <h4>Answers</h4>
      )}
      {jobsApplicantsDetails?.questionarie_answer.length > 0 && (
        <div className="myjob-app-details-faq">
          <h6>
            What was you biggest challenge during a user interface design?
          </h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco{" "}
          </p>

          <h6>Have you ever been in a design team?</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna{" "}
          </p>
        </div>
      )}
    </div>
  );
};
export default MyJobApplicantsDetailsPage;
