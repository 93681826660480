/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerApi } from "../../../redux/authenication/authenication-saga";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../utils/config";
import { AuthenicationActionTypes } from "../../../redux/authenication/authenication-enum";
import Loader from "../../../components/loader";
import { CommonActionTypes } from "../../../redux/common/common-enum";

const EmployerSignUpPage = ({
  isVisible,
  closeAuthPopup,
  verifyEmailClick,
  verifyEmailOTPClick,
}) => {
  useEffect(() => {
    if (isVisible) {
      var modal = document.getElementById("employersignup");
      modal.style.display = "flex";
    }
  }, [isVisible]);

  const closeSignUp = () => {
    var modal = document.getElementById("employersignup");
    modal.style.display = "none";
    closeAuthPopup(false);
  };
  const dispatch = useDispatch();
  const registerState = useSelector((state) => state.RegisterState);
  const [type, setType] = useState("password");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    repeatpassword: "",
    role_type: "employer",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (registerState.registerStatus !== null && registerState.registerStatus) {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      toast("Account created successfully");
      closeAuthPopup(false);
      verifyEmailClick(true);
      verifyEmailOTPClick(formData.email);
    } else if (
      registerState.registerStatus !== null &&
      !registerState.registerStatus
    ) {
      toast(registerState.registerErrorMessage);
    }
  }, [registerState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.email === "") {
      toast("Enter the email address");
    } else if (!isValidEmail(formData.email)) {
      toast("Invalid Email");
    } else if (formData.password === "") {
      toast("Enter the password");
    } else if (formData.password.length < 8) {
      toast("Password must be at least 8 chars long");
    } else if (formData.repeatpassword === "") {
      toast("Enter the repeat password");
    } else if (formData.password !== formData.repeatpassword) {
      toast("Password doesnt match");
    } else {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      dispatch(registerApi(formData));
    }
  };

  return (
    <div id="employersignup" className="modal signin emp-signup popup-form">
      {registerState.loading && <Loader loading={registerState.loading} />}
      <div className="modal-content">
        <span className="close" onClick={() => closeSignUp()}>
          &times;
        </span>
        <h2>Sign up</h2>
        <div className="social-m">
          <div className="google">
            <i className="fa fa-google" aria-hidden="true"></i>{" "}
            <span>Sign up with Google</span>{" "}
          </div>
        </div>
        <p className="or-dvdr">
          <span>or</span>
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="E-mail"
            name="email"
            id="email"
            onChange={handleChange}
          />
          <div className="pswrd">
            <input
              type={type}
              placeholder="Password"
              name="password"
              id="password"
              onChange={handleChange}
            />
            <i
              className={`fa ${
                type === "password" ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={handleToggle}
              aria-hidden="true"
            ></i>
          </div>
          <input
            type="password"
            placeholder="Repeat Password"
            name="repeatpassword"
            id="repeatpassword"
            onChange={handleChange}
          />
          <input type="submit" value="Sign up" />
        </form>
        <p>
          Already have an account?{" "}
          <a
            href="#"
            onClick={() => {
              dispatch({
                type: AuthenicationActionTypes.CLEARVALUES,
              });
              dispatch({
                type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNIN,
                signInPopUpEmployer: true,
              });
              dispatch({
                type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNUP,
                signUpPopUpEmployer: false,
              });
            }}
          >
            Sign in
          </a>{" "}
        </p>
      </div>
    </div>
  );
};
export default EmployerSignUpPage;
