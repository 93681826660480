import {UploadFormApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import {call, put, takeLatest} from "redux-saga/effects";
import {CompanyCreateActionTypes} from "./companycreate-enum";

export function companyCreateApi(body) {
    return {
        type: CompanyCreateActionTypes.COMPANYCREATIONAPIREQUESTED,
        body: body,
    };
}

async function companyCreateService(action) {
    const sessionValue = await JSON.parse(localStorage.getItem("session"));
    return UploadFormApiCall(APIConstants.COMPANYCREATION_URL + sessionValue.id, action.body).then((data) => {
        return data;
    });
}

export function* doCompanyCreate(action) {
    yield put({type: CompanyCreateActionTypes.COMPANYCREATIONREQUEST, loading: true});
    const companySizesResponse = yield call(companyCreateService, action);
    if (companySizesResponse.status === undefined) {
        yield put({
            type: CompanyCreateActionTypes.COMPANYCREATIONSUCCESS,
            status: true,
        });
    } else {
        if (companySizesResponse.status) {
            yield put({
                type: CompanyCreateActionTypes.COMPANYCREATIONSUCCESS,
                status: true,
                message: companySizesResponse.message
            });
        } else {
            yield put({
                type: CompanyCreateActionTypes.COMPANYCREATIONERROR,
                status: false,
            });
        }
    }
    yield put({type: CompanyCreateActionTypes.COMPANYCREATIONREQUEST, loading: false});
}

export function* companyCreateSaga() {
    yield takeLatest(CompanyCreateActionTypes.COMPANYCREATIONAPIREQUESTED, doCompanyCreate);
}
