export const MyProfileActionTypes = {
  MYPROFILEJOBSREQUEST: "MYPROFILEJOBSREQUEST",
  MYPROFILEJOBSAPPILEDJOBS: "MYPROFILEJOBSAPPILEDJOBS",
  MYPROFILEJOBSSAVEDJOBS: "MYPROFILEJOBSSAVEDJOBS",
  MYPROFILEUPDATEJOBSAPPILEDJOBS: "MYPROFILEUPDATEJOBSAPPILEDJOBS",
  MYPROFILEUPDATEJOBSSAVEDJOBS: "MYPROFILEUPDATEJOBSSAVEDJOBS",
  MYPROFILEJOBSERROR: "MYPROFILEJOBSERROR",
  MYPROFILEAPPLIEDJOBSREQUESTED: "MYPROFILEAPPLIEDJOBSREQUESTED",
  MYPROFILESAVEDJOBSREQUESTED: "MYPROFILESAVEDJOBSREQUESTED",
};
