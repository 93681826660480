import { CompanyCreateActionTypes } from "./companycreate-enum";

export const initialCompanyCreationState = {
  status: null,
  loading: false,
  message: "",
  updateJobDetails: null,
};

export const CompanyCreationReducer = (
  state = initialCompanyCreationState,
  action
) => {
  switch (action.type) {
    case CompanyCreateActionTypes.COMPANYCREATIONREQUEST:
      return { ...state, loading: action.loading };
    case CompanyCreateActionTypes.COMPANYCREATIONSUCCESS:
      return {
        ...state,
        status: action.status,
        message: action.message,
      };
    case CompanyCreateActionTypes.COMPANYCREATIONCLEAR:
      return initialCompanyCreationState;
    case CompanyCreateActionTypes.COMPANYCREATIONERROR:
      return {
        ...state,
        status: false,
        message: "",
      };
    case CompanyCreateActionTypes.UPDATEJOBDETAILS:
      return {
        ...state,
        updateJobDetails: action.updateJobDetails,
      };
    default:
      return state;
  }
};
