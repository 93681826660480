import {all} from "redux-saga/effects";
import {authenticationSaga} from "./authenication/authenication-saga";
import {industrySaga} from "./employer/industry/industry-saga";
import {companySizesSaga} from "./employer/company-sizes/companysizes-saga";
import {membershipSaga} from "./employer/membership-plans/membership-saga";
import {jobListSaga} from "./candidates/jobs-listing/jobs-list-saga";
import {filterSideMenuSaga} from "./candidates/filter-menu/filter-menu-saga";
import {companyCreateSaga} from "./employer/company-create/companycreate-saga";
import {myProfileJobsSaga} from "./candidates/my-profile-jobs/my-profile-saga";

function* rootSaga() {
    yield all([
        authenticationSaga(),
        industrySaga(),
        companySizesSaga(),
        membershipSaga(),
        jobListSaga(),
        filterSideMenuSaga(),
        companyCreateSaga(),
        myProfileJobsSaga()
    ]);
}

export default rootSaga;
