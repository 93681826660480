import {MembershipPlansActionTypes} from "./membership-enum";


export const initialMembershipPlansState = {
  status: false,
  loading: false,
  membershipPlans: [],
};

export const MembershipPlansReducer = (state = initialMembershipPlansState, action) => {
  switch (action.type) {
    case MembershipPlansActionTypes.MEMBERSHIPPLANSREQUEST:
      return { ...state, loading: action.loading };
    case MembershipPlansActionTypes.MEMBERSHIPPLANSSUCCESS:
      return {
        ...state,
        status: action.status,
        membershipPlans: action.membershipPlans,
      };
    case MembershipPlansActionTypes.MEMBERSHIPPLANSERROR:
      return {
        ...state,
        status: action.status,
        membershipPlans: [],
      };
    default:
      return state;
  }
};
