import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CommonActionTypes } from "../redux/common/common-enum";
import {
  AuthContext,
  RoleTypeContext,
  UserIdContext,
} from "../contexts/auth-context";
import { AuthenicationActionTypes } from "../redux/authenication/authenication-enum";
import { APIConstants } from "../utils/config";
import { UploadFormApiCall } from "../utils/axios";
import { toast } from "react-toastify";

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [dropdown, setDropdown] = useState("none");
  const auth = useContext(AuthContext);
  const userIdContext = useContext(UserIdContext);
  const roleTypeContext = useContext(RoleTypeContext);
  const navigate = useNavigate();
  const [jobNavigation, setJobNavigation] = useState("/jobs");

  const signUpClick = () => {
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSSIGNUP,
      signUpPopUp: true,
    });
  };

  useEffect(() => {
    if (location.pathname.includes("/jobs")) {
      setJobNavigation("/jobs");
    } else if (location.pathname.includes("/my-profile/applied-jobs")) {
      setJobNavigation("/my-profile/applied-jobs");
    } else if (location.pathname.includes("/my-profile/saved-jobs")) {
      setJobNavigation("/my-profile/applied-jobs");
    } else if (location.pathname.includes("/dashboard")) {
      setJobNavigation("/dashboard");
    } else if (location.pathname.includes("/my-job")) {
      setJobNavigation("/my-job");
    }
  }, []);

  const signInClick = () => {
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
      signInPopUp: true,
    });
  };
  const signUpClickEmployer = () => {
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNUP,
      signUpPopUpEmployer: true,
    });
  };

  const signInClickEmployer = () => {
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNIN,
      signInPopUpEmployer: true,
    });
  };
  const myProfileHandler = (nav) => {
    setJobNavigation(nav);
    navigate(nav);
  };
  const candidateTabs = () => {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item" onClick={() => myProfileHandler("/jobs")}>
          <a
            className={`nav-link ${jobNavigation === "/jobs" && "active"}`}
            aria-current="page"
            href="javascript:;"
          >
            Jobs
          </a>
        </li>
        <li
          className="nav-item"
          onClick={() => myProfileHandler("/my-profile/applied-jobs")}
        >
          <a
            className={`nav-link ${
              jobNavigation === "/my-profile/applied-jobs" && "active"
            }`}
            aria-current="page"
            href="javascript:;"
          >
            My Jobs
          </a>
        </li>
      </ul>
    );
  };

  const employerTabs = () => {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item" onClick={() => myProfileHandler("/dashboard")}>
          <a
            className={`nav-link ${jobNavigation === "/dashboard" && "active"}`}
            aria-current="page"
            href="javascript:;"
          >
            Dashboard
          </a>
        </li>
        <li className="nav-item" onClick={() => myProfileHandler("/my-job")}>
          <a
            className={`nav-link ${jobNavigation === "/my-job" && "active"}`}
            aria-current="page"
            href="javascript:;"
          >
            My Jobs
          </a>
        </li>
      </ul>
    );
  };

  const headerNavigation = () => {
    if (dropdown === "none") {
      setDropdown("block");
    } else {
      setDropdown("none");
    }
  };

  const logout = () => {
    auth.signOut();
  };

  const candidateMenu = () => {
    return (
      <div className="after-login-outer">
        <ul className="after-login-inner">
          <li>
            <div className="pro-dropdown" onClick={headerNavigation}>
              <div id="profile-dd" className="pro-dropbtn">
                {userIdContext.email}
                <img id="img-dd" src={userIdContext.profile_image} alt="" />
                <i id="icon-dd" className="fa fa-caret-down"></i>
              </div>
              <ul
                className="pro-dropdown-content"
                style={{
                  display: `${dropdown}`,
                }}
                id="myDropdown"
              >
                {roleTypeContext === "Employer" ? (
                  <>
                    <li>
                      <a href="/employer-profile/company-info">Company info</a>
                    </li>
                    <li>
                      <a href="/employer-profile/transaction-history">
                        Transaction History
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a href="/candidates-account/account">Account Settings</a>
                    </li>
                    <li>
                      <a href="/candidates-account/resume">Resume Settings</a>
                    </li>
                  </>
                )}
                <li onClick={logout}>
                  <a href="/" className="red">
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const uploadYourResume = () => {
    if (userIdContext !== null) {
      handleUploadResume();
    } else {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      dispatch({
        type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
        signInPopUp: true,
      });
    }
  };

  const uploadTheSkillsAndResume = (resumeFile) => {
    const resumeBody = {
      resume: resumeFile,
    };
    UploadFormApiCall(APIConstants.CANDIDATERESUME_URL, resumeBody).then(
      (data) => {
        toast(data.message);
      }
    );
  };

  const handleUploadResume = (e) => {
    e.preventDefault();
    uploadTheSkillsAndResume(e.target.files[0]);
  };

  const unLoggedCandidateInMenu = () => {
    return (
      <>
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/">
              Home
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link " aria-current="page" href="#">
              Resources
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link " aria-current="page" href="#">
              Events
            </a>
          </li> */}
        </ul>
        <div className="after-login-outer">
          <div className="nav-right">
            <button className="me-2" onClick={uploadYourResume}>
              Upload Your Resume
            </button>
            <button onClick={() => signUpClick()}>Sign Up</button>{" "}
            <ul>
              <li>
                <a href="javascript:;" onClick={() => signInClick()}>
                  Sign In
                </a>{" "}
              </li>
              <li>
                <Link to={`/employers`}>
                  <a href="javascript:;">Employers</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  const unLoggedEmployerInMenu = () => {
    return (
      <div
        className={`${
          location.pathname === "/" || location.pathname === "/candidates"
            ? "white-bg"
            : "grey-bg"
        }`}
      >
        <div className="after-login-outer">
          <div className="nav-right">
            <button onClick={() => signUpClickEmployer()}>Sign Up</button>
            <ul>
              <li>
                <a href="javascript:;" onClick={() => signInClickEmployer()}>
                  Sign In
                </a>{" "}
              </li>
              <li>
                <Link to={`/candidates`}>
                  <a href="javascript:;">For Candidates</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    // className="grey-bg white-bg"
    <div
      className={`${
        location.pathname === "/" || location.pathname === "/candidates"
          ? "white-bg"
          : "grey-bg"
      }`}
    >
      <div className="candidate-outer ">
        <div className="container-cstm container-cstm-mobile">
          <header className="nav-section emp-landingpage desktop">
            <nav
              className="navbar navbar-expand-lg navbar-light "
              id="main_navbar"
            >
              <div className="logo">
                <a href="/">Hireboss</a>{" "}
              </div>
              {userIdContext !== null &&
                roleTypeContext === "Candidate" &&
                candidateTabs()}
              {userIdContext !== null &&
                roleTypeContext === "Employer" &&
                employerTabs()}
              {userIdContext !== null && candidateMenu()}
              {userIdContext === null &&
                location.pathname !== "/employers" &&
                unLoggedCandidateInMenu()}
              {userIdContext === null &&
                location.pathname === "/employers" &&
                unLoggedEmployerInMenu()}
            </nav>
          </header>
        </div>
      </div>
    </div>
  );
}

export default Header;
