/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import EmployerSignUpPage from "../Authentications/Employers/employersignup";
import EmployerSignInPage from "../Authentications/Employers/employersignin";
import EmployerForgotPasswordPage from "../Authentications/Employers/employerforgotpassword";
import VerifyEmailPopUpPage from "../Authentications/Candidates/verify-email-popup";
import { useDispatch, useSelector } from "react-redux";
import { CommonActionTypes } from "../../redux/common/common-enum";
import { UserIdContext } from "../../contexts/auth-context";
import Footer from "../../components/footer";

const EmployersPage = () => {
  const [isSignUpVisible, setIsSignUpVisible] = useState(false);

  const [isSignInVisible, setIsSignInVisible] = useState(false);
  const [isForgotVisible, setIsForgotVisible] = useState(false);
  const [isVerifyEmailVisible, setIsVerifyEmailVisible] = useState(false);
  const [isVerifyEmailOtp, setIsVerifyEmailOtp] = useState("");
  const authenticationEmployerPopUpState = useSelector(
    (state) => state.AuthenticationEmployerPopUpState
  );
  const dispatch = useDispatch();

  const forgotPasswordClick = (event) => {
    setIsSignInVisible(false);
    setIsForgotVisible(event);
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNIN,
      signInPopUpEmployer: false,
    });
  };
  const verifyEmailClick = (event) => {
    setIsSignInVisible(false);
    setIsVerifyEmailVisible(event);
  };
  const verifyEmailOTPClick = (event) => {
    setIsVerifyEmailOtp(event);
  };
  const closeAuthPopup = (event) => {
    setIsSignUpVisible(event);
    setIsSignInVisible(event);
    setIsForgotVisible(event);
    setIsVerifyEmailVisible(event);
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNUP,
      signUpPopUpEmployer: false,
    });
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNIN,
      signInPopUpEmployer: false,
    });
  };

  const postAJobForFree = () => {
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSEMPLOYERSIGNIN,
      signInPopUpEmployer: true,
    });
  };
  return (
    <>
      <div className="employee-outer">
        {authenticationEmployerPopUpState.signUpPopUpEmployer && (
          <EmployerSignUpPage
            isVisible={authenticationEmployerPopUpState.signUpPopUpEmployer}
            closeAuthPopup={closeAuthPopup}
            verifyEmailClick={verifyEmailClick}
            verifyEmailOTPClick={verifyEmailOTPClick}
          />
        )}
        {authenticationEmployerPopUpState.signInPopUpEmployer && (
          <EmployerSignInPage
            isVisible={authenticationEmployerPopUpState.signInPopUpEmployer}
            closeAuthPopup={closeAuthPopup}
            forgotPasswordClick={forgotPasswordClick}
            verifyEmailClick={verifyEmailClick}
            verifyEmailOTPClick={verifyEmailOTPClick}
          />
        )}
        {isForgotVisible && (
          <EmployerForgotPasswordPage
            isVisible={isForgotVisible}
            closeAuthPopup={closeAuthPopup}
          />
        )}
        {isVerifyEmailVisible && (
          <VerifyEmailPopUpPage
            isVisible={isVerifyEmailVisible}
            closeAuthPopup={closeAuthPopup}
            isVerifyEmailOtp={isVerifyEmailOtp}
          />
        )}
        <div className="container-empl desktop">
          <div className="content-section emplanding-content">
            <div className="cntnt-left">
              <h2>Ready to find your next big star?</h2>
              <p onClick={postAJobForFree}>
                <span>
                  <a href="#">Post a job for FREE!</a>
                </span>
              </p>
            </div>
            <div className="cntnt-right">
              <img
                src={require("../../assets/images/christin-hume-Hcfwew.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};
export default EmployersPage;
