// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { GetApiCall, PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import Loader from "../../../components/loader";
import { toast } from "react-toastify";
import {
  useNavigate,
  useOutletContext,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { SetupAccountMenuRoutes } from "../../../utils/enum";
import { CommonActionTypes } from "../../../redux/common/common-enum";
import { useDispatch, useSelector } from "react-redux";
import { CompanyCreateActionTypes } from "../../../redux/employer/company-create/companycreate-enum";

const JobInfoPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobRoles, setJobRoles] = useState([]);
  const [jobExperience, setJobExperience] = useState([]);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [count, pageName, setCount] = useOutletContext();
  const setupAccountState = useSelector((state) => state.SetupAccountState);
  const [remoteValue, setRemoteValue] = useState("");

  const [jobInfoPayload, setJobInfoPayload] = useState({
    google_location: "hyderbad",
    city: "hyderbad",
    zip: 500019,
    state: "telegana",
    latitude: "74125571",
    longitude: "75685561",
  });

  useEffect(() => {
    if (count > 0 && pageName === SetupAccountMenuRoutes.JobInfo) {
      createJobInfo();
    }
  }, [count]);

  const jobInfoApi = (id) => {
    setLoading(true);
    GetApiCall(APIConstants.JOBPREVIEW_URL + id).then((data) => {
      const jobCreation = {
        ...jobInfoPayload,
        title: data.data.title,
        experience_id: data.data.experience_id,
        address: data.data.address,
        city: data.data.address,
        job_type: data.data.job_type.id,
        min_salary: data.data.min_salary,
        max_salary: data.data.max_salary,
        remote: data.data.remote,
      };
      setRemoteValue(data.data.remote);
      setJobInfoPayload(jobCreation);
      dispatch({
        type: CompanyCreateActionTypes.UPDATEJOBDETAILS,
        updateJobDetails: data.data,
      });
      setLoading(false);
    });
  };

  const jobRolesApi = () => {
    setLoading(true);
    GetApiCall(APIConstants.JOBROLES_URL).then((jobRoles) => {
      setJobRoles(jobRoles.data);
      setLoading(false);
    });
  };

  const jobExperienceApi = () => {
    setLoading(true);
    GetApiCall(APIConstants.JOBEXPERIENCE_URL).then((jobExperience) => {
      setJobExperience(jobExperience.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    jobRolesApi();
    jobExperienceApi();
    if (searchParams !== null && searchParams.size > 0) {
      jobInfoApi(searchParams.get("id"));
    }
  }, []);

  const handleJobInfo = (e) => {
    if (e.target.name === "remote") {
      const jobCreation = {
        ...jobInfoPayload,
        remote: e.target.value,
      };
      setJobInfoPayload(jobCreation);
    } else {
      const jobCreation = {
        ...jobInfoPayload,
        [e.target.name]: e.target.value,
      };
      setJobInfoPayload(jobCreation);
    }
  };

  const createJobInfo = () => {
    setLoading(true);
    let jobUrl;
    if (searchParams !== null && searchParams.size > 0) {
      jobUrl = APIConstants.UPDATEJOB_URL + searchParams.get("id");
    } else {
      jobUrl = APIConstants.JOBCREATE_URL;
    }
    PostApiCall(jobUrl, jobInfoPayload).then((data) => {
      if (data.status) {
        setCount(0);
        if (searchParams !== null && searchParams.size > 0) {
          toast("Job info updated successfully");
        } else {
          toast("Job info created successfully");
        }

        dispatch({
          type: CommonActionTypes.SETUPACCOUNTMENU,
          setupMenuSelected: 6,
        });
        const menuIndex = setupAccountState.setupMenuData.findIndex(
          (mI) => mI.id === 5
        );
        let accountMenuList = [...setupAccountState.setupMenuData];
        accountMenuList[menuIndex].isFilled = true;
        dispatch({
          type: CommonActionTypes.SETUPACCOUNTMENUUPDATE,
          setupMenuData: accountMenuList,
        });
        navigate("/company-info/job-description", {
          state: { id: data.data.id },
        });
      } else {
        toast("Please enter all details");
      }
      setLoading(false);
    });
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <>
        <h2>Job Info</h2>
        <form>
          <div className="frmcontainer">
            <input
              type="text"
              name="title"
              onChange={handleJobInfo}
              placeholder="Position Title*"
              value={jobInfoPayload?.title}
            />
          </div>
          <div className="frmcontainer">
            <select
              name="experience_id"
              onChange={handleJobInfo}
              aria-invalid="false"
              value={jobInfoPayload?.experience_id}
            >
              <option value="0">Select Experience</option>
              {jobExperience.map((experience) => {
                return (
                  <option value={experience.id}>{experience.level}</option>
                );
              })}
            </select>
          </div>

          <div className="frmcontainer">
            <input
              type="text"
              name="address"
              value={jobInfoPayload?.address}
              onChange={handleJobInfo}
              placeholder="Enter the address here"
            />
          </div>
          <div className="frmcontainer">
            <select
              name="job_type"
              onChange={handleJobInfo}
              aria-invalid="false"
              value={jobInfoPayload?.job_type}
            >
              <option value="0">Select Job Type</option>
              {jobRoles.map((jobRole) => {
                return <option value={jobRole.id}>{jobRole.name}</option>;
              })}
            </select>
          </div>
          <label htmlFor="">Salary (Annualy)</label>
          <div className="frmcontainer1">
            {" "}
            <input
              type="text"
              name="min_salary"
              onChange={handleJobInfo}
              value={jobInfoPayload?.min_salary}
              placeholder="$$$"
            />
            <span>to</span>{" "}
            <input
              type="text"
              name="max_salary"
              onChange={handleJobInfo}
              value={jobInfoPayload?.max_salary}
              placeholder="$$$"
            />
          </div>
          <label htmlFor=""> Remote available?</label>
          <div className="frmcontainer1 radio-check">
            {remoteValue !== "" ? (
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="remote"
                  id="remoteyes"
                  value="yes"
                  onChange={handleJobInfo}
                  defaultChecked={remoteValue === "Yes"}
                  placeholder="Yes"
                />
                <label class="form-check-label" for="remoteyes">
                  Yes
                </label>
              </div>
            ) : (
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="remote"
                  id="remoteyes"
                  value="yes"
                  onChange={handleJobInfo}
                  defaultChecked={remoteValue === "Yes"}
                  placeholder="Yes"
                />
                <label class="form-check-label" for="remoteyes">
                  Yes
                </label>
              </div>
            )}
            {remoteValue !== "" ? (
              <div className="form-check">
                <input
                  type="radio"
                  name="remote"
                  className="form-check-input"
                  id="remoteno"
                  value="no"
                  defaultChecked={remoteValue === "No"}
                  onChange={handleJobInfo}
                  placeholder="No"
                />
                <label class="form-check-label" for="remoteno">
                  No
                </label>
              </div>
            ) : (
              <div className="form-check">
                <input
                  type="radio"
                  name="remote"
                  className="form-check-input"
                  id="remoteno"
                  value="no"
                  defaultChecked={remoteValue === "No"}
                  onChange={handleJobInfo}
                  placeholder="No"
                />
                <label class="form-check-label" for="remoteno">
                  No
                </label>
              </div>
            )}
          </div>
        </form>
      </>
    </>
  );
};
export default JobInfoPage;
