import NoResult from "../../../components/no-result";
import Pagination from "../../../components/pagination";
import {useDispatch, useSelector} from "react-redux";
import {createSearchParams, useNavigate} from "react-router-dom";
import {APIConstants} from "../../../utils/config";
import {toast} from "react-toastify";
import {PostApiCall} from "../../../utils/axios";
import {MyProfileActionTypes} from "../../../redux/candidates/my-profile-jobs/my-profile-enum";
import moment from "moment";
import React from "react";

const SavedJobsPage = () => {
    const MyProfileSavedState = useSelector((state) => state.MyProfileSavedState);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateToJobDetails = (e, job) => {
        e.preventDefault();
        navigate({
            pathname: "/job-description",
            search: createSearchParams({
                id: job.id,
            }).toString(),
        });
    };

    const SavedJobs = (e, job) => {
        e.preventDefault();
        PostApiCall(APIConstants.SAVEJOB_URL + job.id).then((data) => {
            if (data.status) {
                let updateJobListing = MyProfileSavedState.savedJobs;
                const singleJob = updateJobListing.data.data.findIndex((joblistingId) => joblistingId.job_id === job.job_id);
                if (singleJob !== -1) {
                    updateJobListing.data.data.splice(singleJob, 1);
                    dispatch({
                        type: MyProfileActionTypes.MYPROFILEJOBSSAVEDJOBS,
                        savedJobs: updateJobListing,
                    });
                }
                toast(data.message);
            } else {
                toast("You have already saved this job");
            }
        });
    };

    const ApplyJobs = (e, job) => {
        e.preventDefault();
        PostApiCall(APIConstants.APPLYJOB_URL + job.id).then((data) => {
            toast(data.message);
            let updateJobListing = MyProfileSavedState.savedJobs;
            const singleJob = updateJobListing.data.data.findIndex((joblistingId) => joblistingId.job_id === job.job_id);
            if (singleJob !== -1) {
                updateJobListing.data.data[singleJob].isApplied = updateJobListing.data.data[singleJob].isApplied === 1 ? 0 : 1;
                dispatch({
                    type: MyProfileActionTypes.MYPROFILEJOBSSAVEDJOBS,
                    savedJobs: updateJobListing,
                });
            }
        });
    };

    return (
        <>
            {MyProfileSavedState.savedJobs?.data?.data.length > 0 &&
                MyProfileSavedState.savedJobs?.data?.data?.map((job) => {
                    return (
                        <div id="save-jobs" key={job.id}>
                            <div className="cmp-desg-apply-section">
                                <div
                                    className="cmp-desg"
                                    onClick={(e) => navigateToJobDetails(e, job)}
                                >
                                    <img src={job.logo} alt=""/>
                                    <div className="desg-sec">
                                        <p>{job.title}</p>
                                        <p>
                                            {/*<span>*/}
                                            {/*  {job.address} .{" "}*/}
                                            {/*    {job.job_type}*/}
                                            {/*</span>*/}
                                            <span>
                          {job?.company_name}.{job?.city} , {job?.state},{" "}
                                                {job?.country}.{" "}
                                                {job?.job_type}
                      </span>
                                        </p>
                                        <p>{moment(job.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                <div className="apply-section">
                                    <p className="bookmark" onClick={(e) => SavedJobs(e, job)}>
                                        <a href="#" className="active">
                                            <i class="fa fa-bookmark-o" aria-hidden="true"></i>
                                            <i class="fa fa-bookmark" aria-hidden="true"></i>
                                        </a>
                                    </p>
                                    {(job.isApplied === 0 && job.status !== "Closed") && (
                                        <p
                                            className="apply-btn"
                                            onClick={(e) => ApplyJobs(e, job)}
                                        >
                                            <a href="">
                                                {" "}
                                                Apply Now
                                                <i
                                                    className="fa fa-arrow-right"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        </p>
                                    )}
                                    {job.isApplied === 1 && (
                                        <p className="apply-btn applied">
                                            <a
                                                href=""
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                {" "}
                                                Applied
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            {MyProfileSavedState.savedJobs?.data?.data?.length === 0 && (
                <NoResult title={"No Saved Jobs"}/>
            )}
            <Pagination/>
        </>
    );
};
export default SavedJobsPage;
