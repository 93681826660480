import React, {useEffect, useState} from "react";
import {membershipApi} from "../../../redux/employer/membership-plans/membership-saga";
import {useDispatch, useSelector} from "react-redux";
import {createSearchParams, useNavigate,} from "react-router-dom";
import Loader from "../../../components/loader";

const PricesSelectionPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const membershipState = useSelector((state) => state.MembershipState);
    const [selectedPrice, setSelectedPrice] = useState(0);

    useEffect(() => {
        dispatch(membershipApi());
    }, []);

    const priceHandler = (membership) => {
        setSelectedPrice(membership.id);
    };

    const paynow = () => {
        navigate({
            pathname: "/payment",
            search: createSearchParams({
                id: selectedPrice.toString(),
                returnurl: '/dashboard',
            }).toString(),
        });
    }

    return (
        <>
            {membershipState.loading && (
                <Loader loading={membershipState.loading}/>
            )}

            <div className="inner-content-right container-cstm container-cstm-mobile price-selection">
                <h2>Prices</h2>
                <p>Choose the plan that suits you </p>
                <div className="emp-step-price-outer">
                    {membershipState.membershipPlans.map((membership, index) => {
                        return (
                            <div className="emp-step-price price-block" key={index}>
                                <h3>{membership.package_name}</h3>
                                <p>
                                    <span>{membership.price} $</span>
                                </p>
                                <p> {membership.description}</p>

                                    
                                    <button
                                    className={`${
                                        selectedPrice === membership.id ? "active" : ""
                                    }` }
                                    onClick={() => priceHandler(membership)}
                                >
                                    {selectedPrice === membership.id ? "Selected" : "Select"}
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div className="price-paynow-btn">
                <button
                    className='selectedPrice active' onClick={paynow}
                >
                    Pay Now
                </button>
                </div>
                
            </div>
        </>
    );
};
export default PricesSelectionPage;
