import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import {PostApiCall} from "../../../utils/axios";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {APIConstants} from "../../../utils/config";
import Loader from "../../../components/loader";

export default function CheckoutForm({clientSecret, membershipId, returnurl}) {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);
        stripe
            .confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${returnurl === undefined || returnurl === null ? `${window.location.origin}/company-info/job-info` : `${window.location.origin}${returnurl}`}`,
                },
                redirect: "if_required",
            })
            .then((data) => {
                if (data.paymentIntent?.status === "succeeded") {
                    paySubscribeMembership(data.paymentIntent?.payment_method, data.paymentIntent?.id);
                } else {
                    console.log(data.error);
                }
            })
            .catch((error) => {
                if (error.type === "card_error" || error.type === "validation_error") {
                    setMessage(error.message?.toString);
                } else {
                    setMessage("An unexpected error occured.");
                }
            });

        setIsProcessing(false);
    };

    const paySubscribeMembership = (payment_method, payment_id) => {
        const membershipBody = {
            membership_plan_id: membershipId,
            client_secret: clientSecret,
            payment_id: payment_id,
            payment_method: payment_method,
        };
        PostApiCall(APIConstants.PAYSUBSCRIBEMEMBERSHIP, membershipBody).then(
            (data) => {
                if (data.status) {
                    setLoading(false);
                    toast("Payment has been done successfully.");
                    if (returnurl === null) {
                        navigate("/company-info/job-info");
                    } else {
                        navigate(returnurl);
                    }
                }
            }
        );
    };

    return (
        <>
            {loading && (
                <Loader loading={loading}/>
            )}
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element"/>
                <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
        </>
    );
}
