import Pagination from "../../../components/pagination";
import NoResult from "../../../components/no-result";
import {useSelector} from "react-redux";
import {createSearchParams, useNavigate} from "react-router-dom";
import React from "react";
import moment from "moment/moment";

const AppliedJobsPage = () => {
    const MyProfileAppliedState = useSelector(
        (state) => state.MyProfileAppliedState
    );
    const navigate = useNavigate();

    const navigateToJobDetails = (e, job) => {
        e.preventDefault();
        navigate({
            pathname: "/job-description",
            search: createSearchParams({
                id: job.job_id,
            }).toString(),
        });
    };

    return (
        <>
            {MyProfileAppliedState.appliedJobs?.data?.list?.length > 0 &&
                MyProfileAppliedState.appliedJobs?.data?.list?.map((job) => {
                    return (
                        <div
                            id="app-jobs"
                            key={job.id}
                            className="tabcontent"
                            style={{display: "block"}}
                        >
                            <div className="cmp-desg-apply-section">
                                <div
                                    className="cmp-desg"
                                    onClick={(e) => navigateToJobDetails(e, job)}
                                >
                                    <img src={job.job.employer_profile.logo} alt=""/>
                                    <div className="desg-sec">
                                        <p>{job.job.title}</p>
                                        <p>
                      <span>
                          {job.job?.employer_profile?.company_name}.{job.job?.city} , {job.job?.state},{" "}
                          {job.job?.country}.{" "}
                          {job.job?.job_type?.name}
                      </span>
                                        </p>
                                        <p>{moment(job.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                <div className="apply-section">
                                    <p className="bookmark">
                                        <a href="#"></a>
                                    </p>
                                    <p className="apply-btn pending-review">
                                        {job.job_status === "Not Reviewed" && <a href="#"> Pending review</a>}
                                        {job.job_status === "In Progress" && <a href="#"> In Progress</a>}
                                        {job.job_status === "Accepted" && <a href="#"> Accepted</a>}
                                        {job.job_status === "Rejected" && <a href="#"> Rejected</a>}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            {MyProfileAppliedState.appliedJobs?.data?.list?.length === 0 && (
                <NoResult title={"No Applied Jobs"} subtitle={""}/>
            )}
            <Pagination perPage={undefined} total={undefined}/>
        </>
    );
};
export default AppliedJobsPage;
