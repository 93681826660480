import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const CmsHandler = (cmsLink) => {
    navigate(cmsLink);
  };
  return (
    <footer className="footer-section">
      <div className="container-cstm f-outer">
        <div className="footer-logo">
          <a href="/">Hireboss</a>{" "}
        </div>
        <ul>
          <li>
            <a href="" onClick={() => CmsHandler("/about-us")}>
              About
            </a>
          </li>
          <li>
            <a href="" onClick={() => CmsHandler("/privacy-policy")}>
              Privacy
            </a>
          </li>
          <li>
            <a href="" onClick={() => CmsHandler("/terms")}>
              Terms
            </a>
          </li>
          <li>
            <a href="" onClick={() => CmsHandler("/support")}>
              Support
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
