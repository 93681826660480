import {CompanySizesActionTypes} from "./companysizes-enum";
import {GetApiCall} from "../../../utils/axios";
import {APIConstants} from "../../../utils/config";
import { call, put, takeLatest } from "redux-saga/effects";

export function companySizesApi() {
  return {
    type: CompanySizesActionTypes.COMPANYSIZESAPIREQUESTED,
  };
}

async function companySizesService() {
  return GetApiCall(APIConstants.COMPANYSIZES_URL).then((data) => {
    return data;
  });
}

export function* doCompanySizes() {
  yield put({ type: CompanySizesActionTypes.COMPANYSIZESREQUEST, loading: true });
  const companySizesResponse = yield call(companySizesService);
  if (companySizesResponse) {
    yield put({
      type: CompanySizesActionTypes.COMPANYSIZESSUCCESS,
      status: true,
      companysizes: companySizesResponse,
    });
  } else {
    yield put({
      type: CompanySizesActionTypes.COMPANYSIZESERROR,
      status: false,
    });
  }
  yield put({ type: CompanySizesActionTypes.COMPANYSIZESREQUEST, loading: false });
}

export function* companySizesSaga() {
  yield takeLatest(CompanySizesActionTypes.COMPANYSIZESAPIREQUESTED, doCompanySizes);
}
