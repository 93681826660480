import React from "react";

export const AuthContext = React.createContext({
  signIn: () => {},
  signOut: () => {},
  currentUser: () => {},
  profileUpdate: (currentUser) => {},
  roleType: () => {},
});

export const UserIdContext = React.createContext(null);
export const RoleTypeContext = React.createContext(null);
