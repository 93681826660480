import { IndustryActionTypes } from "./industry-enum";

export const initialIndustryState = {
  status: false,
  loading: false,
  industries: [],
};

export const IndustryReducer = (state = initialIndustryState, action) => {
  switch (action.type) {
    case IndustryActionTypes.INDUSTRYREQUEST:
      return { ...state, loading: action.loading };
    case IndustryActionTypes.INDUSTRYSUCCESS:
      return {
        ...state,
        status: action.status,
        industries: action.industries,
      };
    case IndustryActionTypes.INDUSTRYERROR:
      return {
        ...state,
        status: action.status,
        industries: [],
      };
    default:
      return state;
  }
};
