export const AuthenicationActionTypes = {
  LOGINREQUEST: "LOGINREQUEST",
  LOGINSUCCESS: "LOGINSUCCESS",
  LOGINERROR: "LOGINERROR",
  LOGINAPIREQUESTED: "LOGINAPIREQUESTED",
  REGISTERREQUEST: "REGISTERREQUEST",
  REGISTERSUCCESS: "REGISTERSUCCESS",
  REGISTERERROR: "REGISTERERROR",
  REGISTERAPIREQUESTED: "REGISTERAPIREQUESTED",
  VERIFYEMAILOTPSUCCESS: "VERIFYEMAILOTPSUCCESS",
  VERIFYEMAILOTPREQUESTED: "VERIFYEMAILOTPREQUESTED",
  VERIFYEMAILOTPERROR: "VERIFYEMAILOTPERROR",
  FORGOTPASSWORDSUCCESS: "FORGOTPASSWORDSUCCESS",
  FORGOTPASSWORDERROR: "FORGOTPASSWORDERROR",
  FORGOTPASSWORDREQUESTED: "FORGOTPASSWORDREQUESTED",
  RESETPASSWORDSUCCESS: "RESETPASSWORDSUCCESS",
  RESETPASSWORDREQUESTED: "RESETPASSWORDREQUESTED",
  RESETPASSWORDERROR: "RESETPASSWORDERROR",
  CHANGEPASSWORDSUCCESS: "CHANGEPASSWORDSUCCESS",
  CHANGEPASSWORDREQUESTED: "CHANGEPASSWORDREQUESTED",
  CHANGEPASSWORDERROR: "CHANGEPASSWORDERROR",
  CLEARVALUES: "CLEARVALUES",
};
