import { combineReducers } from "redux";
import { AuthenticationReducer } from "./authenication/authenication-redux";
import { IndustryReducer } from "./employer/industry/industry-redux";
import { CompanySizesReducer } from "./employer/company-sizes/companysizes-redux";
import { MembershipPlansReducer } from "./employer/membership-plans/membership-redux";
import {
  AuthenticationPopUpEmployerReducer,
  AuthenticationPopUpReducer,
  CreateCompanyReducer,
  SetupAccountReducer,
} from "./common/common-redux";
import { JobsListReducer } from "./candidates/jobs-listing/jobs-list-redux";
import { FilterMenuReducer } from "./candidates/filter-menu/filter-menu-redux";
import { CompanyCreationReducer } from "./employer/company-create/companycreate-redux";
import { MyProfileMenuReducer } from "./candidates/my-profile-jobs/my-profile-redux";

const rootReducer = combineReducers({
  RegisterState: AuthenticationReducer,
  LoginState: AuthenticationReducer,
  VerifyEmailOTPState: AuthenticationReducer,
  ForgotPasswordState: AuthenticationReducer,
  ResetPasswordState: AuthenticationReducer,
  ChangePasswordState: AuthenticationReducer,
  IndustryState: IndustryReducer,
  CompanySizesState: CompanySizesReducer,
  MembershipState: MembershipPlansReducer,
  CompanyCreationState: CreateCompanyReducer,
  AuthenticationPopUpState: AuthenticationPopUpReducer,
  jobsListState: JobsListReducer,
  filterSideMenuState: FilterMenuReducer,
  AuthenticationEmployerPopUpState: AuthenticationPopUpEmployerReducer,
  CreateNewCompanyState: CompanyCreationReducer,
  SetupAccountState: SetupAccountReducer,
  MyProfileAppliedState: MyProfileMenuReducer,
  MyProfileSavedState: MyProfileMenuReducer,
});

export default rootReducer;
