/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from "react";
import {
  GetApiCall,
  PostApiCall,
  UploadFormApiCall,
} from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import { UserIdContext } from "../../../contexts/auth-context";
import Loader from "../../../components/loader";
import { toast } from "react-toastify";
import React from "react";

const ResumeSettingPage = () => {
  const [skillsList, setSkillsList] = useState([]);
  const userIdContext = useContext(UserIdContext);
  const [skillName, setSkillName] = useState("");
  const [choosedSkillList, setChoosedSkillList] = useState([]);
  const [uploadResumePdf, setUploadResumePdf] = useState(null);
  const [createWorkHistory, setCreatWorkHistory] = useState(null);
  const [resumeSkillHistory, setResumeSkillHistory] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [isCurrentCompany, setIsCurrentCompany] = useState("0");
  const [viewTheResume, setViewTheResume] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateWorkHistoryStatus, setUpdateWorkHistoryStatus] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetApiCall(APIConstants.SKILLS_URL).then((data) => {
      setSkillsList(data.data);
    });
  }, []);

  const resumeSkillHistoryFunction = () => {
    GetApiCall(APIConstants.CANDIDATEGETRESUMESKILLHISTORY_URL).then((data) => {
      setResumeSkillHistory(data.data);
      setChoosedSkillList(data.data.skill);
      setViewTheResume(data.data?.resume?.resume);
      setLoading(false);
    });
  };

  useEffect(() => {
    resumeSkillHistoryFunction();
  }, []);

  const handleUploadResume = (e) => {
    e.preventDefault();
    setUploadResumePdf(e.target.files[0]);
  };

  const uploadTheSkillsAndResume = () => {
    setLoading(true);
    // @ts-ignore
    const skillsId = choosedSkillList.map((skId) => skId.id);
    const resumeBody = {
      resume: uploadResumePdf,
      skills: skillsId,
    };
    UploadFormApiCall(APIConstants.CANDIDATERESUME_URL, resumeBody).then(
      (data) => {
        setViewTheResume(data.data?.cv);
        setLoading(false);
        toast(data.message);
      }
    );
  };

  const updateTheSkills = (e) => {
    e.preventDefault();
    // @ts-ignore
    uploadTheSkillsAndResume(uploadResumePdf);
  };

  const removeSkill = (skil) => {
    const newPeople = choosedSkillList.filter((sk) => sk !== skil);
    // @ts-ignore
    setChoosedSkillList(newPeople);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const historyCreationBody = {
      // @ts-ignore
      ...createWorkHistory,
      // @ts-ignore
      user_id: userIdContext.id,
      is_current_company: isCurrentCompany,
      [e.target.name]: e.target.value,
    };
    setCreatWorkHistory(historyCreationBody);
  };

  const addNewWorkHistory = () => {
    if (createWorkHistory === null) {
      toast("Please fill the all details");
    } else if (
      createWorkHistory.designation === undefined ||
      createWorkHistory.designation === ""
    ) {
      toast("Please enter job title");
    } else if (
      createWorkHistory.company_name === undefined ||
      createWorkHistory.company_name === ""
    ) {
      toast("Please enter company name");
    } else if (
      createWorkHistory.city === undefined ||
      createWorkHistory.city === ""
    ) {
      toast("Please enter city");
    } else if (
      createWorkHistory.joining_date === undefined ||
      createWorkHistory.joining_date === ""
    ) {
      toast("Please select joining date");
    } else {
      if (isCurrentCompany === "0") {
        if (
          createWorkHistory.end_date === undefined ||
          createWorkHistory.end_date === ""
        ) {
          toast("Please select end date");
        } else {
          setLoading(true);
          PostApiCall(
            APIConstants.CANDIDATEWORKHISTORY_URL,
            createWorkHistory
          ).then((data) => {
            if (data.status) {
              setLoading(false);
              // setCreatWorkHistory(null);
              toast("Added the work history");
              setResumeSkillHistory([]);
              resumeSkillHistoryFunction();
              window.location.reload();
            }
          });
        }
      } else {
        setLoading(true);
        PostApiCall(
          APIConstants.CANDIDATEWORKHISTORY_URL,
          createWorkHistory
        ).then((data) => {
          if (data.status) {
            setLoading(false);
            // setCreatWorkHistory(null);
            toast("Added the work history");
            setResumeSkillHistory([]);
            resumeSkillHistoryFunction();
            window.location.reload();
          }
        });
      }
    }
  };

  const updateWorkHistory = () => {
    setLoading(true);
    PostApiCall(
      // @ts-ignore
      APIConstants.CANDIDATEUPDATEWORKHISTORY_URL + createWorkHistory.id,
      createWorkHistory
    ).then((data) => {
      if (data.status) {
        setUpdateWorkHistoryStatus(false);
        setLoading(false);
        toast("Updated the work history");
        setResumeSkillHistory([]);
        resumeSkillHistoryFunction();
        window.location.reload();
      }
    });
  };
  const CreateWorkHistory = () => {
    if (updateWorkHistoryStatus) {
      updateWorkHistory();
    } else {
      addNewWorkHistory();
    }
  };
  const deletWorkHistory = (history) => {
    setLoading(true);
    GetApiCall(APIConstants.CANDIDATEWORKHISTORYDELETE_URL + history.id).then(
      (data) => {
        if (data.status) {
          setLoading(false);
          toast("Work history deleted successfully");
          resumeSkillHistoryFunction();
        }
      }
    );
  };
  const editWorkHistory = (updateHistory) => {
    window.scrollTo(0, 0);
    setUpdateWorkHistoryStatus(true);
    setCreatWorkHistory(updateHistory);
  };
  const workHistoryhandle = (e) => {
    e.preventDefault();
    CreateWorkHistory();
  };

  const skillNameChange = (value) => {
    setSkillName(value);
    setFilterSkills([]);
    const filtered = skillsList.filter((skill) =>
      // @ts-ignore
      skill.name.toLowerCase().includes(value)
    );
    // @ts-ignore
    setFilterSkills(filtered);
  };

  const isCurrentCompanyChange = (e) => {
    if (e.target.checked) {
      setIsCurrentCompany("1");
    } else {
      setIsCurrentCompany("0");
    }
  };

  return (
    <div id="rsm-stns">
      {loading && <Loader loading={loading} />}
      <div className="rsm-stns-outer">
        <div className="rsm-stns-left yellow-btn-wrap">
          <form>
            <div
              className="file-upload-wrapper"
              data-text={
                uploadResumePdf !== null
                  ? // @ts-ignore
                    uploadResumePdf.name
                  : "Upload Your Resume (PDF)"
              }
            >
              <input
                name="file-upload-field"
                type="file"
                accept="application/pdf"
                className="file-upload-field"
                onChange={handleUploadResume}
              />
            </div>
            <div
              // @ts-ignore
              class="view-resume"
            >
              <a href={viewTheResume} target="_blank" rel="noopener noreferrer">
                View Resume
              </a>
            </div>
            <div className="rsm-skill">
              <h2>Skill</h2>
              <div className="d-flex add-btn-skill">
                <div className="skill-dropdown">
                  <input
                    type="text"
                    value={skillName}
                    placeholder="Add more skills"
                    onChange={(e) => skillNameChange(e.target.value)}
                  />
                  <ul
                    // @ts-ignore
                    class={`skill-list ${skillName.length > 0 && "show"}`}
                  >
                    {filterSkills.length > 0 &&
                      skillName.length > 0 &&
                      filterSkills.map((skill) => {
                        return (
                          <li
                            onClick={(e) => {
                              e.preventDefault();
                              setChoosedSkillList([...choosedSkillList, skill]);
                              setSkillName("");
                            }}
                          >
                            {
                              // @ts-ignore
                              skill.name
                            }
                          </li>
                        );
                      })}
                  </ul>
                </div>{" "}
              </div>
              <p>
                {choosedSkillList.map((skill) => (
                  <span>
                    {
                      // @ts-ignore
                      skill.name
                    }{" "}
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                      onClick={() => removeSkill(skill)}
                    ></i>
                  </span>
                ))}
              </p>
            </div>

            <input type="submit" onClick={(e) => updateTheSkills(e)} />
          </form>
        </div>
        <div className="rsm-stns-right">
          <h2>Work History</h2>

          {/*<p className="add-work-btn desktop">*/}
          {/*    <a href="#">Add new work history</a>*/}
          {/*</p>*/}
          <div className="add-new-work">
            <form>
              <input
                type="text"
                name="designation"
                onChange={handleChange}
                placeholder="Job Title"
                // @ts-ignore
                value={createWorkHistory?.designation}
              />
              <input
                type="text"
                name="company_name"
                placeholder="Company Name"
                onChange={handleChange}
                // @ts-ignore
                value={createWorkHistory?.company_name}
              />
              <input
                type="text"
                name="city"
                onChange={handleChange}
                placeholder="City"
                // @ts-ignore
                value={createWorkHistory?.city}
              />

              <div className="job-period">
                <label>Time Period </label>
                <p>
                  <input
                    type="date"
                    name="joining_date"
                    onChange={handleChange}
                    placeholder="YYYY / MM"
                    // @ts-ignore
                    value={createWorkHistory?.joining_date}
                  />
                  {isCurrentCompany === "0" && `To`}
                  {isCurrentCompany === "0" && (
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleChange}
                      placeholder="YYYY / MM"
                      // @ts-ignore
                      value={createWorkHistory?.end_date}
                    />
                  )}
                  {/* To <span>Present</span> */}
                </p>
              </div>
              <label className="lbl-chkbx">
                I currently work here
                <input
                  type="checkbox"
                  name="is_current_company"
                  onChange={(e) => isCurrentCompanyChange(e)}
                />
                <span className="checkmark"></span>
              </label>

              <input type="submit" onClick={(e) => workHistoryhandle(e)} />
            </form>
          </div>
          {
            // @ts-ignore
            resumeSkillHistory.workHistory?.length > 0 &&
              // @ts-ignore
              resumeSkillHistory.workHistory?.map((listing, index) => {
                return (
                  <div className="work-details-outer">
                    <span>
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </span>

                    <div className="work-details" key={index}>
                      <p>
                        <strong>{listing.designation}</strong>
                      </p>
                      <p>{listing.company_name}</p>
                      <p>
                        {listing.joining_date}{" "}
                        {listing.end_date !== null && "/"} {listing.end_date}
                      </p>
                      <p>{listing.city}</p>
                    </div>

                    <span>
                      <i
                        className="fa fa-trash-o"
                        aria-hidden="true"
                        onClick={() => deletWorkHistory(listing)}
                      ></i>
                    </span>
                    <span>
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => editWorkHistory(listing)}
                      ></i>
                    </span>
                  </div>
                );
              })
          }
        </div>
      </div>
    </div>
  );
};
export default ResumeSettingPage;
