import {MyProfileActionTypes} from "./my-profile-enum";

export const initialMyProfileMenuState = {
    status: false,
    loading: false,
    appliedJobs: null,
    savedJobs: null,
};

export const MyProfileMenuReducer = (state = initialMyProfileMenuState, action) => {
    switch (action.type) {
        case MyProfileActionTypes.MYPROFILEJOBSREQUEST:
            return {...state, loading: action.loading};
        case MyProfileActionTypes.MYPROFILEJOBSAPPILEDJOBS:
            return {
                ...state,
                status: action.status,
                appliedJobs: action.appliedJobs,
            };
        case MyProfileActionTypes.MYPROFILEJOBSSAVEDJOBS:
            return {
                ...state,
                status: action.status,
                savedJobs: action.savedJobs,
            };
        case MyProfileActionTypes.MYPROFILEUPDATEJOBSSAVEDJOBS:
            return {
                ...state,
                savedJobs: action.savedJobs,
            };
        case MyProfileActionTypes.MYPROFILEUPDATEJOBSAPPILEDJOBS:
            return {
                ...state,
                appliedJobs: action.appliedJobs,
            };
        default:
            return state;
    }
};
