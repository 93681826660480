/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordApi } from "../../../redux/authenication/authenication-saga";
import Loader from "../../../components/loader";
import { AuthenicationActionTypes } from "../../../redux/authenication/authenication-enum";

const EmployerResetPasswordPage = () => {
  const dispatch = useDispatch();
  const resetPasswordState = useSelector((state) => state.ResetPasswordState);
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState({
    email: params.emailId,
    password: "",
    repeatpassword: "",
  });
  const [type, setType] = useState("password");
  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (
      resetPasswordState.isResetPasswordStatus !== null &&
      resetPasswordState.isResetPasswordStatus
    ) {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      toast(
        "Password has been changed successfully, please try to login with the new password."
      );
      navigate("/employers");
    }
  }, [resetPasswordState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password === "") {
      toast("Enter the password");
    } else if (formData.password.length < 8) {
      toast("Password must be at least 8 chars long");
    } else if (formData.repeatpassword === "") {
      toast("Enter the repeat password");
    } else if (formData.password !== formData.repeatpassword) {
      toast("Password doesnt match");
    } else {
      dispatch({
        type: AuthenicationActionTypes.CLEARVALUES,
      });
      dispatch(resetPasswordApi(formData));
    }
  };
  return (
    <div class="grey-bg">
      {resetPasswordState.loading && (
        <Loader loading={resetPasswordState.loading} />
      )}
      <div class="candidate-outer ">
        <div class="resetpassword">
          <h2>Reset password</h2>
          <p>Enter your new password below.</p>
          <form onSubmit={handleSubmit}>
            <div class="pswrd">
              <input
                type={type}
                placeholder="Password"
                name="password"
                id=""
                onChange={handleChange}
              />
              <i
                className={`fa ${
                  type === "password" ? "fa-eye-slash" : "fa-eye"
                }`}
                onClick={handleToggle}
                aria-hidden="true"
              ></i>
            </div>
            <input
              type="password"
              placeholder="Repeat Password"
              name="repeatpassword"
              id=""
              onChange={handleChange}
            />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};
export default EmployerResetPasswordPage;
