export const CommonActionTypes = {
  COMPANYCREATE: "COMPANYCREATE",
  COMPANYCLEAR: "COMPANYCLEAR",
  AUTHENTICATIONSCLEAR: "AUTHENTICATIONSCLEAR",
  AUTHENTICATIONSSIGNIN: "AUTHENTICATIONSSIGNIN",
  AUTHENTICATIONSSIGNUP: "AUTHENTICATIONSSIGNUP",
  AUTHENTICATIONSEMPLOYERCLEAR: "AUTHENTICATIONSEMPLOYERCLEAR",
  AUTHENTICATIONSEMPLOYERSIGNIN: "AUTHENTICATIONSEMPLOYERSIGNIN",
  SETUPACCOUNTMENULIST: "SETUPACCOUNTMENULIST",
  SETUPACCOUNTMENU: "SETUPACCOUNTMENU",
  SETUPACCOUNTMENUCLEAR: "SETUPACCOUNTMENUCLEAR",
  SETUPACCOUNTMENUUPDATE: "SETUPACCOUNTMENUUPDATE",
  AUTHENTICATIONSEMPLOYERSIGNUP: "AUTHENTICATIONSEMPLOYERSIGNUP",
  LOADER: "LOADER",
};
