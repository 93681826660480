import {PostApiCall} from "../../utils/axios";
import {APIConstants} from "../../utils/config";
import {AuthenicationActionTypes} from "./authenication-enum";
import {call, put, takeLatest} from "redux-saga/effects";

export function registerApi(body) {
    return {
        type: AuthenicationActionTypes.REGISTERAPIREQUESTED,
        body: body,
    };
}

export function loginApi(body) {
    return {
        type: AuthenicationActionTypes.LOGINAPIREQUESTED,
        body: body,
    };
}

export function verifyEmailApi(body) {
    return {
        type: AuthenicationActionTypes.VERIFYEMAILOTPREQUESTED,
        body: body,
    };
}

export function forgotPasswordApi(body) {
    return {
        type: AuthenicationActionTypes.FORGOTPASSWORDREQUESTED,
        body: body,
    };
}

export function resetPasswordApi(body) {
    return {
        type: AuthenicationActionTypes.RESETPASSWORDREQUESTED,
        body: body,
    };
}

export function changePasswordApi(body) {
    return {
        type: AuthenicationActionTypes.CHANGEPASSWORDREQUESTED,
        body: body,
    };
}

async function RegisterService(action) {
    return PostApiCall(APIConstants.REG_URL, action.body).then((data) => {
        return data;
    });
}

async function loginService(action) {
    return PostApiCall(APIConstants.LOGIN_URL, action.body).then((data) => {
        return data;
    });
}

async function verifyEmailService(action) {
    return PostApiCall(APIConstants.VERIFYEMAIL_URL, action.body).then((data) => {
        return data;
    });
}

async function forgotPasswordService(action) {
    return PostApiCall(APIConstants.FORGOTPASSWORD_URL, action.body).then(
        (data) => {
            return data;
        }
    );
}

async function resetPasswordService(action) {
    return PostApiCall(APIConstants.RESETPASSWORD_URL, action.body).then(
        (data) => {
            return data;
        }
    );
}

async function changePasswordService(action) {
    return PostApiCall(APIConstants.CHANGEPASSWORD_URL, action.body).then(
        (data) => {
            return data;
        }
    );
}

export function* doRegister(action) {
    yield put({type: AuthenicationActionTypes.REGISTERREQUEST, loading: true});
    const registerResponse = yield call(RegisterService, action);
    if (registerResponse.status) {
        yield put({
            type: AuthenicationActionTypes.REGISTERSUCCESS,
            registerStatus: true,
        });
    } else {
        yield put({
            type: AuthenicationActionTypes.REGISTERERROR,
            registerErrorMessage: registerResponse.message.email[0],
        });
    }
    yield put({type: AuthenicationActionTypes.REGISTERREQUEST, loading: false});
}

export function* doLogin(action) {
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: true});
    const loginResponse = yield call(loginService, action);
    if (loginResponse.status) {
        yield put({
            type: AuthenicationActionTypes.LOGINSUCCESS,
            isLoggedIn: true,
            loginStatus: true,
            loginData: loginResponse,
        });
    } else {
        yield put({
            type: AuthenicationActionTypes.LOGINERROR,
            loginErrorMessage: loginResponse.message,
            isVerified: loginResponse.isVerfied,
        });
    }
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: false});
}

export function* doVerifyEmail(action) {
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: true});
    const verifyResponse = yield call(verifyEmailService, action);
    if (verifyResponse.status) {
        yield put({
            type: AuthenicationActionTypes.VERIFYEMAILOTPSUCCESS,
            isEmailVerified: true,
        });
    } else {
        yield put({
            type: AuthenicationActionTypes.VERIFYEMAILOTPERROR,
            isEmailVerified: false,
        });
    }
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: false});
}

export function* doForgotPassword(action) {
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: true});
    const forgotResponse = yield call(forgotPasswordService, action);
    if (forgotResponse.status) {
        yield put({
            type: AuthenicationActionTypes.FORGOTPASSWORDSUCCESS,
            isForgotPasswordStatus: true,
        });
    } else {
        yield put({
            type: AuthenicationActionTypes.FORGOTPASSWORDERROR,
            isForgotPasswordStatus: false,
            forgotPasswordErrorMessage:forgotResponse.message
        });
    }
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: false});
}

export function* doResetPassword(action) {
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: true});
    const resetResponse = yield call(resetPasswordService, action);
    if (resetResponse.status) {
        yield put({
            type: AuthenicationActionTypes.RESETPASSWORDSUCCESS,
            isResetPasswordStatus: true,
        });
    } else {
        yield put({
            type: AuthenicationActionTypes.RESETPASSWORDSUCCESS,
            isResetPasswordStatus: false,
        });
    }
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: false});
}

export function* doChangePassword(action) {
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: true});
    const changeResponse = yield call(changePasswordService, action);
    if (changeResponse.status) {
        yield put({
            type: AuthenicationActionTypes.CHANGEPASSWORDSUCCESS,
            isChangePasswordStatus: true,
        });
    } else {
        yield put({
            type: AuthenicationActionTypes.CHANGEPASSWORDERROR,
            isChangePasswordStatus: false,
            changePasswordErrorMessage: changeResponse.message
        });
    }
    yield put({type: AuthenicationActionTypes.LOGINREQUEST, loading: false});
}

export function* authenticationSaga() {
    yield takeLatest(AuthenicationActionTypes.REGISTERAPIREQUESTED, doRegister);
    yield takeLatest(AuthenicationActionTypes.LOGINAPIREQUESTED, doLogin);
    yield takeLatest(
        AuthenicationActionTypes.VERIFYEMAILOTPREQUESTED,
        doVerifyEmail
    );
    yield takeLatest(
        AuthenicationActionTypes.FORGOTPASSWORDREQUESTED,
        doForgotPassword
    );
    yield takeLatest(
        AuthenicationActionTypes.RESETPASSWORDREQUESTED,
        doResetPassword
    );
    yield takeLatest(
        AuthenicationActionTypes.CHANGEPASSWORDREQUESTED,
        doChangePassword
    );
}
