import { call, put, takeLatest } from "redux-saga/effects";
import { APIConstants } from "../../../utils/config";
import { IndustryActionTypes } from "./industry-enum";
import { GetApiCall } from "../../../utils/axios";

export function industryApi() {
  return {
    type: IndustryActionTypes.INDUSTRYAPIREQUESTED,
  };
}

async function industryService() {
  return GetApiCall(APIConstants.INDUSTRIES_URL).then((data) => {
    return data;
  });
}

export function* doIndustry() {
  yield put({ type: IndustryActionTypes.INDUSTRYREQUEST, loading: true });
  const industryResponse = yield call(industryService);
  if (industryResponse.status) {
    yield put({
      type: IndustryActionTypes.INDUSTRYSUCCESS,
      status: true,
      industries: industryResponse.data.industries,
    });
  } else {
    yield put({
      type: IndustryActionTypes.INDUSTRYERROR,
      status: false,
    });
  }
  yield put({ type: IndustryActionTypes.INDUSTRYREQUEST, loading: false });
}

export function* industrySaga() {
  yield takeLatest(IndustryActionTypes.INDUSTRYAPIREQUESTED, doIndustry);
}
